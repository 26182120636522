import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import { RuleDropdowns } from '../models/Core';

const DropdownService = {
    NAME: "DROPDOWN SERVICE",
    getResources: async function () {
        try {
        }
        catch (e) {
            console.error(e);
        }
    },
    getRequestDropdowns: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Dropdown/RequestDropdowns`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    }
    ,
    getConflictDropdowns: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Dropdown/conflictdropdowns`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getOrgConflictDropdowns: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Dropdown/orgconflictdropdowns`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },

    getCOIAssessmentResult: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Dropdown/COIAssessmentResult`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getRuleDropdowns: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Dropdown/ruleDropDowns`);
            return response.data as RuleDropdowns;
        } catch (e) {
            console.log(this.NAME, e);
            return {} as RuleDropdowns
        }
    }
};

export default DropdownService;

import axios from './Api';
import ApiConfig from '../config/ApiConfig';

const BaseService = {
    base: 'en',
    NAME: 'BASE SERVICE',
    setCurrentLanguage: async function (lc: string) {
        return new Promise((res, rej) => {
            try {
                res(localStorage.setItem("_geh_lc", lc))
            } catch (error) {
                console.log("An error occured setting language", error);
                rej(error);
            }
        })
    },
    getCurrentLanguage: async function () {
        return new Promise<string>((res, rej) => {
            try {
                const lang = localStorage.getItem("_geh_lc");
                if (lang) {
                    res(lang)
                }
                res(this.base)
            } catch (error) {
                console.log("An error occured getting language", error);
                res(this.base);
            }
        })
    },
    getLanguages: async function () {
        try {
            let lang = await this.getCurrentLanguage();


            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/Languages`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getHubNavResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();

            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubNav`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getHubAdminResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();
            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubAdmin`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },

    getDashboardResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();
            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubDashboard`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },

    getActionResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();
            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubActions`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getHubMeta: async function () {
        try {

            const response = await axios.get(`${ApiConfig.baseURL}/api/Config/GetHubMeta`, );
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },

    getCoiDetailsResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();

            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubCoiDetails`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getOrgCoiDetailsResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();
            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubOrgCoiDetails`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getGiftDetailResources: async function () {
        try {
            let lang = await this.getCurrentLanguage();
            const response = await axios.get(`${ApiConfig.baseURL}/api/dropdown/HubGiftDetails`, {
                headers: {
                    "Accept-Language": lang
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },




}

export default BaseService;
import axios from 'axios'

import ApiConfig from '../config/ApiConfig'
import { GetToken } from '../config/AdalConfig';


const instance = axios.create(ApiConfig)


instance.interceptors.request.use(async function (config) {
    const token = await GetToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default instance
import React, { Component } from 'react'
import { Tree, Spin, Row, Col, Button, Modal, Form, Input, AutoComplete, Tag, message } from 'antd';
import OrganizationService from '../services/OrganizationService';
import EmployeeService from '../services/EmployeeService';
import _, { isNull } from 'lodash';
import { Employee, GenericDTO, Message, TreeDTO } from '../models/Core';

interface IEthicOfficersProps {
    searchType: null | number
    removeOfficer(OrgID: number, EmployeeID: number): Promise<Message | null>
    addOfficer(OrgID: number, EmployeeID: number): Promise<Message | null>
    translate(text: string): string,
    getOrg(id: number | null): Promise<TreeDTO[]>
    addText: string,
    removeText: string,
    searchText: string
}

interface IEthiicOfficersState {
    loading: boolean,
    organizations: TreeDTO[],
    employees: Employee[],
    employeeSearchQuery: string,
    EmployeeID: null | number,
    Employee: null | Employee,
    OrganizationID: null | number,
    showAddModal: boolean,
    searchType: number
}



export default class EthicsOfficers extends Component<IEthicOfficersProps, IEthiicOfficersState> {
    constructor(props: IEthicOfficersProps) {
        super(props)
        this.state = {
            loading: false,
            organizations: [],
            employees: [],
            employeeSearchQuery: '',
            EmployeeID: null,
            Employee: null,
            OrganizationID: null,
            showAddModal: false,
            searchType: this.props.searchType ?? 0
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true }, async () => {
            const organizations = await this.props.getOrg(null);
            if (!isNull(organizations)) {
                this.setState({ organizations })
            }
            this.setState({ loading: false })
        })
    }

    onSelect = (node: TreeDTO) => {
        if (node.isLeaf) {
            this.setState({ EmployeeID: node.id, OrganizationID: node.orgId });
        }
        else {
            this.setState({ OrganizationID: node.id, EmployeeID: null });
        }
    }

    removeOfficer = () => {
        this.setState({ loading: true }, async () => {
            if (isNull(this.state.EmployeeID) || isNull(this.state.OrganizationID)) {
                message.error(this.translate("Employee or Organization is not selected"), 10)

                return
            }

            const data = await this.props.removeOfficer(this.state.OrganizationID, this.state.EmployeeID);
            if (isNull(data)) {
                message.error(this.translate("Error Occured"), 10)
                return
            }
            if (data.IsError) {
                message.error(this.translate(data.Message), 10)
            } else {
                message.success(`${this.translate(data.Message)}: ${data.Data.FullName}`)
            }
            this.getData();
        })
    }

    addOfficer = () => {
        this.setState({ loading: true, showAddModal: false }, async () => {

            if (isNull(this.state.EmployeeID) || isNull(this.state.OrganizationID)) {
                message.error(this.translate("Employee or Organization is not selected"), 10)
                return
            }
            const data = await this.props.addOfficer(this.state.OrganizationID, this.state.EmployeeID);
            if (isNull(data)) {
                message.error(this.translate("Error Occured"), 10)
                return
            }
            if (data.IsError) {
                message.error(this.translate(data.Message), 10)
            } else {
                message.success(`${this.translate(data.Message)}: ${data.Data.FullName}`)
            }
            this.getData();
        })
    }

    searchEmployees = async () => {
        const foundEmployees = await EmployeeService.search(this.state.employeeSearchQuery, this.state.searchType);
        const employees = _.map(foundEmployees, (e) => ({ ...e, value: e.FullName }))
        this.setState({ employees })
    }

    selectEmployee = async (FullName: string) => {
        const Employee = _.find(this.state.employees, { FullName });
        if (Employee)
            this.setState({ EmployeeID: Employee.ID });
    }
    translate = (text: string) => {
        return this.props.translate(text)
    }

    render() {
        const { loading, organizations, EmployeeID, OrganizationID, Employee, showAddModal, employees } = this.state;

        return (
            <div>
                <Row>
                    <Col>
                        <Button className="btn-success" style={{ marginRight: 10 }} disabled={OrganizationID && !EmployeeID ? false : true} onClick={() => { this.setState({ showAddModal: true }) }}>
                            {this.translate(this.props.addText)}
                        </Button>
                        <Button className="btn-danger" disabled={EmployeeID ? false : true} onClick={this.removeOfficer}>
                            {this.translate(this.props.removeText)}
                        </Button>
                    </Col>
                </Row>
                <br />
                {
                    loading ?
                        <Spin />
                        :
                        <div style={{ padding: 20, backgroundColor: '#ffffff', height: '100%', width: '100%' }}>
                            <Row>
                                <Col>
                                    <Tree treeData={organizations}
                                        // loadData={this.getEthicsOfficersForOrg}
                                        onSelect={(key, e) => { this.onSelect(e.node as TreeDTO) }} />
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                        </div>
                }
                <Modal title={this.translate(this.props.addText)} visible={showAddModal} onOk={this.addOfficer}
                    okText={this.translate("Add")}
                    cancelText={this.translate("Cancel")}
                    onCancel={() => this.setState({ showAddModal: false })}>

                    <Form>
                        {this.translate(this.props.searchText)}
                        <br /><br />
                        <AutoComplete
                            onSelect={this.selectEmployee}
                            dropdownClassName="certain-category-search-dropdown"
                            dropdownMatchSelectWidth={500}
                            style={{ width: 250 }}
                            options={employees.map(x => { return { label: x.FullName, value: x.FullName } })}>
                            <Input.Search size="large"
                                placeholder={this.translate("Enter the name / email address of the employee")}
                                onChange={(e) => this.setState({ employeeSearchQuery: e.target.value })}
                                onSearch={this.searchEmployees} />
                        </AutoComplete>
                    </Form>
                    <br />
                    {
                        this.state.Employee ?
                            <Tag color="blue">
                                {this.state.Employee.FullName} ({this.state.Employee.EmailAddress})
                            </Tag>
                            :
                            null
                    }
                </Modal>
            </div>
        )
    }
}

import React, { Component } from 'react'
import _, { indexOf } from 'lodash'
import GiftService from '../services/GiftService';
import PieChart from './charts/PieChart';
import { Row, Col, Spin } from 'antd';
import CombinationChart from './charts/CombinationChart';
import currencyFormatter from 'currency-formatter';
import BaseService from '../services/BaseService';
import { Gift, IChartType } from "../models/Core";


interface IDashBoardProps {
    languageCode: string
}

interface IDashboardState {
    query: string,
    statusPie: IChartType[],
    namePie: IChartType[],
    priorityPie: IChartType[],
    businessBar: IChartType[],
    businessNames: string[],
    sentCount: number,
    receivedCount: number,
    loading: boolean,
    languageCode: string,
    langData: any,
}



export default class Dashboard extends Component<IDashBoardProps, IDashboardState> {
    constructor(props: IDashBoardProps) {
        super(props)
        this.state = {
            query: '',
            statusPie: [],
            namePie: [],
            priorityPie: [],
            businessBar: [],
            businessNames: [],
            sentCount: 0,
            receivedCount: 0,
            loading: false,
            languageCode: props.languageCode,
            langData: {},
        }
    }

    componentDidMount() {
        this.getLanguageData();

    }
    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label]
        }
        return label;
    }

    getData = () => {
        this.setState({ loading: true }, async () => {
            const gifts: Gift[] = await GiftService.getGifts();
            const statusPie = _.uniqBy(_.map(gifts, (gift) => {
                return {
                    name: this.translate(gift.StatusName),
                    y: _.round((_.countBy(gifts, { StatusName: gift.StatusName })["true"] / gifts.length) * 100, 2),
                    count: _.countBy(gifts, { StatusName: gift.StatusName })["true"]
                }
            }), 'name');

            const giftTypes = _.flatMap(gifts, (gift) => gift.GiftTypes);
            // const giftTypePie = _.uniqBy(_.map(giftTypes, (gift) => {
            //     return {
            //         name: this.translate(gift.Name),
            //         y: _.round((_.countBy(giftTypes, { Name: gift.Name })[true] / giftTypes.length) * 100, 2),
            //         count: _.countBy(giftTypes, { Name: gift.Name })[true]
            //     }
            // }), 'name');

            const namePie = _.uniqBy(_.compact(_.map(gifts, (gift) => {
                if (gift.Name) {
                    return {
                        name: this.translate(gift.Name),
                        y: _.round((_.countBy(gifts, { Name: gift.Name })["true"] / gifts.length) * 100, 2),
                        count: _.countBy(gifts, { Name: gift.Name })["true"]
                    }
                }
            })), 'name');


            const priorities = _.flatMap(gifts, (gift) => gift.Priorities);
            const priorityPie = _.uniqBy(_.map(priorities, (priority) => {
                return {
                    name: this.translate(priority.Name),
                    y: _.round((_.countBy(priorities, { Name: priority.Name })["true"] / priorities.length) * 100, 2),
                    count: _.countBy(priorities, { Name: priority.Name })["true"]
                }
            }), 'name');


            let businessBar: IChartType[] = [];

            gifts.forEach(x => {
                const amount = x.ConvertedValue;
                let Contacts: string[] = [...x.Contacts.map(c => c.Business?.Name)];
                if (!!x.BusinessName) {
                    Contacts.push(x.BusinessName)
                }
                Contacts = [...new Set(Contacts)]
                Contacts.forEach(y => {
                    const index = businessBar.findIndex(z => z.name === y);
                    if (index >= 0) {
                        businessBar[index].y += amount;
                    } else {
                        businessBar.push({
                            name: y, y: amount
                        })
                    }
                })
            })


            const businessNames = _.map(businessBar, 'name');

            const sentGifts = gifts.filter(x => x.IsGiven === true && x.Status !== 4)
            const receivedGifts = gifts.filter(x => x.IsGiven === false && x.Status !== 4)

            const sentCount = _.sumBy(sentGifts, 'ConvertedValue');

            const receivedCount = _.sumBy(receivedGifts, 'ConvertedValue');

            this.setState({ statusPie, namePie, priorityPie, sentCount, receivedCount, businessBar, businessNames, loading: false })
        })
    }
    moneyFormat = (Currency: string, Amount: number) => {
        return `${Currency} - ${currencyFormatter.format(Amount, { code: Currency })}`
    }

    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getDashboardResources();
            this.setState({ langData });
            this.getData();

        })

    }



    render() {
        const { statusPie, namePie, priorityPie, sentCount, receivedCount, businessBar, businessNames, loading } = this.state;
        return (
            <div>
                {
                    loading ?
                        <Spin />
                        :
                        <div>
                            <Row>
                                <Col span={13}>
                                    <h2>{this.translate("Dashboard")} </h2>
                                </Col>

                                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <h3 style={{ borderBottom: 'solid 3px' }}> {this.translate("Given")}<pre>{this.moneyFormat("CAD", sentCount)}</pre></h3>
                                </Col>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <h3 style={{ borderBottom: 'solid 3px' }}> {this.translate("Received")}<pre>{this.moneyFormat("CAD", receivedCount)}</pre></h3>
                                </Col>
                            </Row>
                            <div style={{ backgroundColor: '#ffffff' }}>
                                <Row style={{ backgroundColor: '#ffffff' }}>
                                    <Col span={12}>
                                        <h3 style={{ textAlign: 'center', marginBottom: 20 }}>{this.translate("GCR by Status")}</h3>
                                        <PieChart series={[{ data: statusPie }]} />
                                    </Col>
                                    <Col span={12}>
                                        <h3 style={{ textAlign: 'center', marginBottom: 20 }}>{this.translate("GCR by Types")}</h3>
                                        <PieChart series={[{ data: namePie }]} />
                                    </Col>
                                </Row>
                                <hr style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#f2f2f2' }} />
                                <Row>
                                    <Col span={12}>
                                        <h3 style={{ textAlign: 'center', marginBottom: 20 }}>{this.translate("GCR by Priorities")}</h3>
                                        <PieChart series={[{ data: priorityPie }]} />
                                    </Col>
                                    <Col span={12}>
                                        <h3 style={{ textAlign: 'center', marginBottom: 20 }}>{this.translate("GCR by Business")}</h3>
                                        <CombinationChart series={[{ type: 'column', data: businessBar }]} categories={businessNames} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import { Message, TreeDTO } from '../models/Core';

const OrganizationService = {
    NAME: 'Organizations',
    getOrganizationTree: async function (id: number | null) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/tree/${id ? id : ''}`);
            return response.data as TreeDTO[];
        } catch (e) {
            console.log(this.NAME, e);
            return [];
        }
    },
    getOrganizations: async function () {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getOrganizationsTreeLegal: async function (id: number | null) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/treeLegal/${id ? id : ''}`);
            return response.data as TreeDTO[];
        } catch (e) {
            console.log(this.NAME, e);
            return [];
        }
    },
    getOrganizationsTreeStates: async function (id: number | null) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/StateTree/${id ? id : ''}`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getOrganizationsTreeRegion: async function (id: number | null) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/RegionTree/${id ? id : ''}`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getEthicsOfficer: async function (OrganizationID: number) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/getethicsofficer/${OrganizationID}`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    addEthicsOfficer: async function (OrganizationID: number, EmployeeID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/addethicsofficer`, qs.stringify({ OrganizationID, EmployeeID }));
            return response.data as Message;
        } catch (e) {
            console.log(this.NAME, e);
            return null;
        }
    },
    removeEthicsOfficer: async function (OrganizationID: number, EmployeeID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/removeethicsofficer`, qs.stringify({ OrganizationID, EmployeeID }));
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    addLegalOfficer: async function (OrganizationID: number, EmployeeID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/addlegalofficer`, qs.stringify({ OrganizationID, EmployeeID }));

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    removeLegalOfficer: async function (OrganizationID: number, EmployeeID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/removelegalofficer`, qs.stringify({ OrganizationID, EmployeeID }));
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    addState: async function (Name: string, OrganizationID: string) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/AddStateProvince`,
                qs.stringify({ OrganizationID, Name }));

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    changeStateStatus: async function (stateID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/ChangeStateProvinceStatus`,
                qs.stringify({ stateID }));

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    addRegion: async function (Name: string) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/AddRegion`,
                qs.stringify({ Name }));

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    changeRegionStatus: async function (regionID: number) {
        try {
            const response = await axios.post(`${ApiConfig.baseURL}/api/organizations/ChangeRegionStatus`,
                qs.stringify({ regionID }));

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getRules: async function () {

        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/organizations/RuleTree`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    }
};

export default OrganizationService;
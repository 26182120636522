import React, { Component } from 'react'
import ConflictOfInterestService from '../services/ConflictOfInterestService';
import {
    Layout, Spin, Descriptions, PageHeader, Button, Row, Col,
    Tag, Typography, List, Modal, Select, Form, Input, Alert, Tooltip, Tabs, Badge, Upload, Checkbox, message,
    Timeline, Table
} from 'antd'
import { EditTwoTone, CheckCircleTwoTone, ExclamationCircleTwoTone, InboxOutlined } from '@ant-design/icons';
import OrganizationService from '../services/OrganizationService';
import moment from 'moment';
import CommentService from '../services/CommentService';
import BaseService from '../services/BaseService';
import Comments from './Comments';
import TextArea from 'antd/lib/input/TextArea';
import { AuditDTO, CommentsBase, ConflictOfInterestFileDTO, EmptyConflictOfInterest, GenericDTO, IConflictOfInterest, IOrgConflictOfInterst, EmployeeWithPermissions } from '../models/Core';
import { RcFile } from 'antd/lib/upload';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';




const { Header, Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;



type IConflictOfInterestDetailParams = {
    id: string; // parameters will always be a string (even if they are numerical)
};


interface IConflictOfInterestDetailProps extends RouteComponentProps<IConflictOfInterestDetailParams> {
    languageCode: string,
    history: History
    user: EmployeeWithPermissions,

}

interface IConflictOfInterestDetailState {
    loading: boolean,
    coi: IConflictOfInterest,
    organizations: GenericDTO[],
    currencies: [],
    OrganizationName: string,
    ApprovedValue: null | number,
    ReviewComments: string,
    showEditModal: boolean,
    showStatusModal: boolean,
    statusModalTitle: string,
    editModalConfirmLoading: boolean,
    statusModalConfirmLoading: boolean,
    comments: CommentsBase[],
    languageCode: string,
    langData: any,
    showFileModal: boolean,
    ShareWithUser: boolean,
    EmailUser: boolean,
    EmailUserComment: string,
    fileList: RcFile[],
    coiFiles: ConflictOfInterestFileDTO[],
    auditHistory: AuditDTO[],
    CanDelete: boolean,
    OrganizationID: number
}

export default class ConflictOfInterestDetail extends Component<IConflictOfInterestDetailProps, IConflictOfInterestDetailState> {
    constructor(props: IConflictOfInterestDetailProps) {
        super(props);
        this.state = {
            loading: false,
            coi: EmptyConflictOfInterest,
            organizations: [],
            currencies: [],
            OrganizationName: "",
            ApprovedValue: null,
            ReviewComments: '',
            showEditModal: false,
            showStatusModal: false,
            statusModalTitle: '',
            editModalConfirmLoading: false,
            statusModalConfirmLoading: false,
            comments: [],
            languageCode: props.languageCode,
            langData: {},
            showFileModal: false,
            ShareWithUser: false,
            EmailUser: false,
            EmailUserComment: '',
            fileList: [],
            coiFiles: [],
            auditHistory: [],
            CanDelete: false,
            OrganizationID: 60

        }
    }

    componentDidMount() {
        this.getLanguageData();
    }

    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label]
        }
        return label;
    }
    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getCoiDetailsResources();
            this.setState({ langData });
            this.getData();

        })

    }

    getData = () => {
        const { id } = this.props.match.params;

        if (id) {
            this.setState({ loading: true }, async () => {
                const coi: IConflictOfInterest = await ConflictOfInterestService.get(id);
                const organizations = await OrganizationService.getOrganizations();
                this.setState({
                    loading: false, coi, organizations,
                    OrganizationName: coi.Organization != null ? coi.Organization.Name : '',
                    ReviewComments: coi.ReviewComments ? coi.ReviewComments : '',
                    comments: coi.Comments ? coi.Comments : [],
                    coiFiles: coi.Files ? coi.Files : [],
                    auditHistory: coi.AuditHistory ? coi.AuditHistory : [],
                    CanDelete: coi.CanDelete,
                    OrganizationID: coi.OrganizationID

                })
            })
        }
        else {
            this.props.history.push('/actions');
        }
    }

    postComment = async (commentText: string) => {
        var res = await CommentService.AddCOIComment(this.state.coi.ID, commentText);

        if (res) {
            const comments = [res, ...this.state.comments];
            this.setState({ comments });
        }

    }

    changeGiftStatus = () => {
        const { statusModalTitle, ReviewComments, coi } = this.state;

        this.setState({ loading: true, showStatusModal: false }, async () => {
            switch (statusModalTitle) {
                case 'Approve':
                    await ConflictOfInterestService.approve(coi.ID, ReviewComments);
                    this.getData();
                    break;


                case 'UnderReview':
                    await ConflictOfInterestService.markUnderReview(coi.ID);
                    this.getData();
                    break;
                case 'Reopen':
                    await ConflictOfInterestService.reopen(coi.ID);
                    this.getData();
                    break;

                case 'InReview':
                    await ConflictOfInterestService.markInReview(coi.ID, ReviewComments);
                    this.getData();
                    break;

                case 'Delete':
                    const data = await ConflictOfInterestService.delete(coi.ID);
                    if (data && data.status === 200) {
                        this.props.history.push(`/actions`)
                    } else {
                        this.getData();
                    }
                    break;

                default:
                    await ConflictOfInterestService.reject(coi.ID, ReviewComments);
                    this.getData();
                    break;
            }

        })
    }

    // markInReview = async () => {
    //     await ConflictOfInterestService.markInReview(this.state.coi.ID);
    //     this.getData();
    // }

    // markReject = async () => {
    //     await ConflictOfInterestService.reject(this.state.coi.ID);
    //     this.getData();
    // }

    // markApprove = async () => {
    //     await ConflictOfInterestService.approve(this.state.coi.ID);
    //     this.getData();
    // }

    getStatus = () => {
        const text = this.state.coi.StatusName;
        switch (text) {
            case 'Submitted':
                return <Tag color="blue">{this.translate(text)}</Tag>;
            case 'Approved':
                return <Tag color="green">{this.translate(text)}</Tag>;
            case 'Rejected':
                return <Tag color="red">{this.translate(text)}</Tag>;
            case 'InReview':
                return <Tag color="orange">{this.translate(text)}</Tag>;
            case 'UnderReview':
                return <Tag color="#5260ff">{this.translate(text)}</Tag>;
            default:
                return <Tag>{this.translate(text)}</Tag>;
        }
    }

    getCompliesWithPolicy = () => {
        const complies = this.state.coi.CompliesWithPolicy;
        switch (complies) {
            case true:
                return <Tooltip title={this.translate("Complies")}><CheckCircleTwoTone twoToneColor='#52c41a'></CheckCircleTwoTone></Tooltip>;
            case false:
                return <Tooltip title={this.translate("DoesNotComply")}><ExclamationCircleTwoTone twoToneColor='#eb2f96'></ExclamationCircleTwoTone></Tooltip>;
            default:
                return null;
        }
    }

    getSubTitles = () => {
        return <div>{this.getStatus()} {this.getCompliesWithPolicy()}</div>
    }

    getButtons = () => {
        let btns = [<Button key="2" className="btn-success" onClick={() => (
            this.setState({ statusModalTitle: 'Approve', showStatusModal: true })
        )}>{this.translate("Approve")}</Button>,
        <Button key="3" className="btn-danger" onClick={() => (
            this.setState({ statusModalTitle: 'Reject', showStatusModal: true })
        )}>{this.translate("Reject")}</Button>];

        if (this.state.coi.StatusName === 'Submitted' || this.state.coi.StatusName === 'InReview' || this.state.coi.StatusName === 'UnderReview') {

            btns.push(
                <Button key="1" onClick={() => (this.setState({ statusModalTitle: 'InReview', showStatusModal: true }))}>{this.translate("MarkasInReview")}</Button>,
            )

            btns.push(
                <Button key="5" className="btn-review" onClick={() => (
                    this.setState({ statusModalTitle: 'UnderReview', showStatusModal: true })
                )}>{this.translate("UnderReview")}</Button>
            )



        }

        else {
            btns = [];
            if (this.props.user.IsAdmin) {
                btns.push(
                    <Button key="10" onClick={() => (this.setState({ statusModalTitle: 'Reopen', showStatusModal: true }))}>{this.translate("Reopen")}</Button>,
                )
            }
        }


        if (this.state.coi.CanDelete) {
            btns.push(
                <Button key="8" className="btn-delete" onClick={() => (
                    this.setState({ statusModalTitle: 'Delete', showStatusModal: true })
                )}>{this.translate("Delete")}</Button>
            )
        }

        return btns;
    }

    downloadFile = async (fileID: number) => {
        await ConflictOfInterestService.downloadFile(fileID);
    }

    updateOrganization = () => {
        this.setState({ loading: true, showEditModal: false }, async () => {
            const { coi, OrganizationID } = this.state;
            await ConflictOfInterestService.updateOrganization(coi.ID, OrganizationID);
            this.getData();
        })
    }
    uploadFiles = async () => {
        const { EmailUserComment, EmailUser, ShareWithUser, fileList, coi } = this.state;

        const emailComment = EmailUser ? EmailUserComment : ""
        const result = await ConflictOfInterestService.uploadFiles(fileList, coi.ID.toString(), String(ShareWithUser), String(EmailUser), emailComment);

        if (result) {
            this.setState(state => ({
                coiFiles: [...state.coiFiles, ...result],
            }));
            message.success(this.translate("FileUploaded"))
        } else {
            message.error(this.translate("fileUploadError"), 10)
        }
        this.setState({ showFileModal: false, EmailUserComment: '', EmailUser: false, ShareWithUser: false, fileList: [] })

    }


    auditColumn = () => {
        return [
            {
                title: this.translate("ChangeType"),
                dataIndex: 'ChangeTypeDisplay',

            },
            {
                title: this.translate("ColumnChanged"),
                dataIndex: 'ColumnChanged',
                render: (ColumnChanged: string) => {


                    return <span>{this.translate(ColumnChanged)}</span>
                }

            },
            {
                title: this.translate("OriginalValue"),
                dataIndex: 'OriginalValueDisplay',
                render: (data: string) => {


                    return <span>{this.translate(data)}</span>
                }

            },
            {
                title: this.translate("NewValue"),
                dataIndex: 'NewValueDisplay',
                render: (data: string) => {


                    return <span>{this.translate(data)}</span>
                }

            },

        ]
    }

    render() {
        const { loading, coi, showEditModal, showStatusModal, statusModalTitle, ApprovedValue, ReviewComments,
            editModalConfirmLoading, statusModalConfirmLoading,
            auditHistory, OrganizationName, comments, ShareWithUser, coiFiles, showFileModal,
            EmailUserComment, EmailUser, organizations, OrganizationID } = this.state;

        return (
            <div>
                {
                    loading ?
                        <Spin />
                        :
                        <div>
                            <Content>
                                <PageHeader
                                    ghost={false}
                                    onBack={() => window.history.length > 1 ? window.history.back() : this.props.history.push(`/actions`)}
                                    title={`COI ID - ${coi.ID}`}
                                    subTitle={this.getSubTitles()}
                                    extra={this.getButtons()}
                                >


                                    <div>
                                        {
                                            coi.AdditionalComments ?

                                                <Alert message={this.translate("AdditionalComments")} style={{ marginBottom: 20 }}
                                                    description={coi.AdditionalComments} type="info" closable={false} />

                                                :

                                                null
                                        }
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab={this.translate("Details")} key="1">
                                                <Row>
                                                    <Col>
                                                        {
                                                            coi.CreatedBy ?
                                                                <h3><span className="txt-label">{this.translate("CreatedBy")}</span> {coi.CreatedBy.FullName} - {moment(coi.CreatedOn).local().format("MMM Do, YYYY")} </h3>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>
                                                {coi.UpdatedBy ? <Row>
                                                    <Col>
                                                        <h3><span className="txt-label">{this.translate("UpdatedBy")}</span> {coi.UpdatedBy.FullName} - {moment(coi.UpdatedOn).local().format("MMM Do, YYYY")} </h3>

                                                    </Col>
                                                </Row> : null}

                                                <Row>
                                                    <Col>
                                                        {
                                                            coi.Organization ?
                                                                <h3><span className="txt-label">{this.translate("Region")}</span> {coi.Organization.Name}
                                                                    <EditTwoTone style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.setState({ showEditModal: true })} /> </h3>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Descriptions title={this.translate("Description")}>
                                                            <Descriptions.Item>{coi.Description}</Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={24}>
                                                        <Descriptions title={this.translate("MitigationDescription")}>
                                                            <Descriptions.Item>{coi.MitigationDescription}</Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Descriptions
                                                    title={this.translate("Details")}
                                                    bordered
                                                >

                                                    {coi.ConflictStatusName ? <Descriptions.Item span={24} label={this.translate("ConflictStatusName")}>{this.translate(coi.ConflictStatusName)}</Descriptions.Item> : null}
                                                    {coi.PersonalObjectOfConflictName ? <Descriptions.Item span={24} label={this.translate("PersonalObjectOfConflictName")}>{this.translate(coi.PersonalObjectOfConflictName)}</Descriptions.Item> : null}
                                                    {coi.OrganizationalObjectOfConflictName ? <Descriptions.Item span={24} label={this.translate("OrganizationalObjectOfConflictName")}>{this.translate(coi.OrganizationalObjectOfConflictName)}</Descriptions.Item> : null}
                                                    {coi.ConflictTypeName ? <Descriptions.Item span={24} label={this.translate("ConflictTypeName")}>{this.translate(coi.ConflictTypeName)}</Descriptions.Item> : null}
                                                    {coi.PublicOfficialName ? <Descriptions.Item span={24} label={this.translate("PublicOfficialName")}>{coi.PublicOfficialName}</Descriptions.Item> : null}
                                                    {coi.PublicOfficialBusinessName ? <Descriptions.Item span={24} label={this.translate("PublicOfficialBusinessName")}>{coi.PublicOfficialBusinessName}</Descriptions.Item> : null}
                                                    {coi.PublicOfficialPosition ? <Descriptions.Item span={24} label={this.translate("PublicOfficialPosition")}>{coi.PublicOfficialPosition}</Descriptions.Item> : null}
                                                    {coi.PublicOfficialRelationshipTypeID ? <Descriptions.Item span={24} label={this.translate("PublicOfficialRelationshipTypeName")}>{this.translate(coi.PublicOfficialRelationshipTypeName)}</Descriptions.Item> : null}
                                                    {coi.RelationshipDetailWithPublicOfficial ? <Descriptions.Item span={24} label={this.translate("RelationshipDetailWithPublicOfficial")}>{coi.RelationshipDetailWithPublicOfficial}</Descriptions.Item> : null}
                                                    {coi.PublicOfficialLeftOfficeDate ? <Descriptions.Item span={24} label={this.translate("PublicOfficialLeftOfficeDate")}>{moment(coi.PublicOfficialLeftOfficeDate).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                    {coi.IsTruthful != null ? <Descriptions.Item span={24} label={this.translate("IsTruthful")}>{coi.IsTruthful ? this.translate("Yes") : this.translate("No")}</Descriptions.Item> : null}
                                                    {coi.NotifiedManager != null ? <Descriptions.Item span={24} label={this.translate("NotifiedManager")}>{coi.NotifiedManager ? this.translate("Yes") : this.translate("No")}</Descriptions.Item> : null}
                                                    {coi.ImplicatedBuisness != null ? <Descriptions.Item span={24} label={this.translate("ImplicatedBuisness")}>{`${coi.ImplicatedBuisness.Name}   ${coi.ImplicatedBuisness.Country ? ` - ${coi.ImplicatedBuisness.Country}` : ""}`}</Descriptions.Item> : null}



                                                    {coi.CreatedOn ? <Descriptions.Item span={24} label={this.translate("CreatedOn")}>{moment(coi.CreatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                    {coi.UpdatedOn ? <Descriptions.Item span={24} label={this.translate("UpdatedOn")}>{moment(coi.UpdatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                    {coi.OtherConflictType ? <Descriptions.Item span={24} label={this.translate("OtherConflictType")}>{coi.OtherConflictType}</Descriptions.Item> : null}
                                                    {coi.OtherPersonalObjectOfConflict ? <Descriptions.Item span={24} label={this.translate("OtherPersonalObjectOfConflict")}>{coi.OtherPersonalObjectOfConflict}</Descriptions.Item> : null}
                                                    {coi.OtherOrganizationalObjectOfConflict ? <Descriptions.Item span={24} label={this.translate("OtherOrganizationalObjectOfConflict")}>{coi.OtherOrganizationalObjectOfConflict}</Descriptions.Item> : null}




                                                </Descriptions>


                                                <br />
                                                {
                                                    coi.Employee ?
                                                        <Descriptions
                                                            title={this.translate("WSPContact")}
                                                            bordered
                                                        >
                                                            <Descriptions.Item span={24} label={this.translate("Name")}>{coi.Employee.FullName}</Descriptions.Item>
                                                            <Descriptions.Item span={24} label={this.translate("EmailAddress")}>{coi.Employee.EmailAddress}</Descriptions.Item>
                                                            <Descriptions.Item span={24} label={this.translate("PhoneNumber")}>{coi.Employee.PhoneNumber}</Descriptions.Item>
                                                        </Descriptions>
                                                        :
                                                        null
                                                }


                                            </TabPane>

                                            <TabPane tab={<span>{this.translate("Comments")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={comments ? comments.length : 0} size="small" showZero></Badge></span>} key="2">


                                                <Comments comments={comments} submitFunction={this.postComment} translate={this.translate} />

                                            </TabPane>

                                            <TabPane tab={<span>{this.translate("Files")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={coi.Files ? coi.Files.length : 0} size="small" showZero></Badge></span>} key="3">
                                                <div>
                                                    <Modal title={this.translate('addNewFile')} visible={showFileModal} onOk={this.uploadFiles}
                                                        confirmLoading={statusModalConfirmLoading}
                                                        okText={this.translate("Upload")}
                                                        okButtonProps={this.state.fileList.length > 0 ? { disabled: false } : { disabled: true }}
                                                        onCancel={() => this.setState({ showFileModal: false })}>
                                                        <Dragger
                                                            name={'files'}
                                                            multiple={true}
                                                            beforeUpload={file => {
                                                                this.setState(state => ({
                                                                    fileList: [...state.fileList, file],
                                                                }));
                                                                return false;
                                                            }}
                                                            fileList={this.state.fileList}
                                                            onRemove={file => {
                                                                this.setState(state => {
                                                                    const index = state.fileList.findIndex(x => x.uid === file.uid);
                                                                    const newFileList = state.fileList.slice();
                                                                    newFileList.splice(index, 1);
                                                                    return {
                                                                        fileList: newFileList,
                                                                    };
                                                                });
                                                            }}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{this.translate("uploadText")}</p>
                                                            <p className="ant-upload-hint">
                                                                {this.translate("uploadHint")}
                                                            </p>
                                                        </Dragger>
                                                        <br />

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                                            <Checkbox value={ShareWithUser} onChange={(e) => this.setState({ ShareWithUser: e.target.checked })}>{this.translate("ShareWithUser")}</Checkbox>
                                                            <Checkbox value={EmailUser} onChange={(e) => this.setState({ EmailUser: e.target.checked })}>{this.translate("EmailUser")}</Checkbox>

                                                        </div>
                                                        <br />

                                                        {EmailUser && <Form.Item label={this.translate("EmailFileComment")}>
                                                            <TextArea value={EmailUserComment} rows={4} onChange={(e) => { this.setState({ EmailUserComment: e.target.value }) }}></TextArea>
                                                        </Form.Item>}
                                                    </Modal>


                                                    <List header={<h3><span className="txt-label">{this.translate("Files")}</span><Button size={"small"} type={'primary'} onClick={() => this.setState({ showFileModal: true })}>{this.translate("addNewFile")}</Button></h3>}
                                                        bordered
                                                        dataSource={coiFiles}
                                                        locale={
                                                            { emptyText: this.translate("NoFiles") }
                                                        }
                                                        renderItem={item => (
                                                            <List.Item>
                                                                <Typography.Link onClick={() => this.downloadFile(item.ID)}
                                                                >{item.Name}
                                                                </Typography.Link>
                                                                {item.Private && <Tag color="red">{this.translate("Private")}</Tag>}
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<span>{this.translate("AuditHistory")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={auditHistory ? auditHistory.length : 0} size="small" showZero></Badge></span>} key="4">
                                                <Row>
                                                    <Timeline style={{ width: '100%' }}>
                                                        {auditHistory.map(audit => (
                                                            <Timeline.Item>
                                                                <Table
                                                                    tableLayout="fixed"
                                                                    title={() => <Row><Col ><h4>{`${audit.User.FullName} ${audit.User.Roles && audit.User.Roles.length > 0 && ` - ${audit.User.Roles.map(y => this.translate(y)).join(", ")}`} - ${moment(audit.CreatedData).local().format("MMM Do, YYYY h:mm:ss a")}`}</h4></Col> </Row>}
                                                                    dataSource={audit.AuditDetails} columns={this.auditColumn()} style={{ width: '100%' }}
                                                                    size="small" pagination={false}
                                                                />


                                                            </Timeline.Item>
                                                        ))}

                                                    </Timeline>

                                                </Row>

                                            </TabPane>

                                        </Tabs>
                                    </div>

                                </PageHeader>

                            </Content>
                        </div>
                }
                <Modal title={this.translate("UpdateRegion")} visible={showEditModal} onOk={this.updateOrganization}
                    confirmLoading={editModalConfirmLoading}
                    okText={this.translate("Update")}
                    onCancel={() => this.setState({ showEditModal: false })}>
                    <Form>
                        <Form.Item label={this.translate("Region")}>
                            <Select placeholder={this.translate("PleaseSelectTheRegion")}
                                defaultValue={OrganizationID}
                                onChange={(OrganizationID: number) => this.setState({ OrganizationID })}>
                                {
                                    organizations.map(org => <Option key={`org${org.ID}`} value={org.ID}>{org.Name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={this.translate(statusModalTitle)} visible={showStatusModal} onOk={this.changeGiftStatus}
                    confirmLoading={statusModalConfirmLoading}
                    okText={this.translate("Update")}
                    onCancel={() => this.setState({ showStatusModal: false })}>
                    <Form>

                        {statusModalTitle != "UnderReview" && statusModalTitle != "Delete" && statusModalTitle != "Reopen" ? <Form.Item label={this.translate("ReviewComments")}>
                            <Input.TextArea showCount maxLength={1000} rows={4} value={ReviewComments} onChange={(e) => { this.setState({ ReviewComments: e.target.value }) }} />
                        </Form.Item> :

                            statusModalTitle == "Delete" ?
                                <span>{this.translate("DeleteEntry")}</span> :
                                statusModalTitle == "Reopen" ? <span>{this.translate("SetReviewStateReopen")} </span> : <span>{this.translate("SetReviewState")} </span>


                        }


                    </Form>
                </Modal>
            </div >
        )
    }
}

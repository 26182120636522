
import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import api from './Api';
import { pagination, sorter } from '../models/Core';

const EmployeeService = {
    NAME: "EMPLOYEE SERVICE",
    search: async function (query: string, searchType: number) {
        try {
            const response = await axios.get(`${ApiConfig.baseURL}/api/Employees/search?query=${query}&searchType=${searchType}`);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    bulkAddUser: async function (file: Blob) {
        try {
            const formData = new FormData();
            formData.append("bulkUpload", file);

            console.log("FormData", formData)


            const response = await axios.post(`${ApiConfig.baseURL}/api/Employees/bulkAdd`, formData);
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    }
    ,
    addUser: async function (FullName: string, EmailAddress: string, RoleID: number) {
        try {


            const response = await api.post(`${ApiConfig.baseURL}/api/Employees/add`, JSON.stringify({
                FullName, EmailAddress, RoleID
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    getOfficers: async function (pagination: pagination, sorter: sorter, query: string) {
        try {
            const response = await api.get(`${ApiConfig.baseURL}/api/Employees/GetOfficers`, {
                params: {
                    page: pagination.current, limit: pagination.pageSize,
                    desc: sorter.order, query
                },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            });
            return response.data;
        } catch (e) {
            console.log(e);
            return [];
        }
    },
    updatePreferredLanguage: async function (language: string) {
        try {

            const response = await api.post(`${ApiConfig.baseURL}/api/Employees/setLanguage`, JSON.stringify({
                language
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.status === 200;
        } catch (e) {
            console.log(this.NAME, e);
            return false;
        }
    }
};

export default EmployeeService;
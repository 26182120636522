
import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import api from './Api';
import { Rule, RuleDropdowns } from '../models/Core';

const RuleService = {
    NAME: 'RULE SERVICE',
    GetRule: async function (id: number) {
        try {
            const response = await api.get(`${ApiConfig.baseURL}/api/Rules/get/${id}`
            );
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    GetAllRule: async function () {
        try {
            const response = await api.get(`${ApiConfig.baseURL}/api/Rules/All`
            );
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    AddNewRule: async function (rule: Rule) {
        try {
            const response = await api.post(`${ApiConfig.baseURL}/api/Rules/NewRule`,
                JSON.stringify(rule), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },

    ReRunRule: async function (giftID: number) {
        try {
            const response = await api.get(`${ApiConfig.baseURL}/api/Rules/Run/${giftID}`
            );
            if (response.status === 200) {
                return true
            }
            return false;
        } catch (e) {
            console.log(this.NAME, e);
            return false;
        }
    },
    UpdateRule: async function (rule: Rule) {
        try {
            const response = await api.post(`${ApiConfig.baseURL}/api/Rules/UpdateRule`,
                JSON.stringify(rule), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    }

};

export default RuleService;
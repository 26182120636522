import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { IChartWrapper } from '../../models/Core';

interface ICombinationChartProps {
    series: IChartWrapper[],
    categories: string[]
}

const CombinationChart = ({ series, categories }: ICombinationChartProps) => {
    const mapOptions = {
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: categories ? categories : []
        },
        tooltip: {
            pointFormat: '<b>$ {point.y}</b>'
        },
        labels: {
            items: [{
                style: {
                    left: '50px',
                    top: '18px',
                    color: ( // theme
                        Highcharts.defaultOptions.title &&
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'black'
                }
            }]
        },
        series: series ? series : []
    }


    return (
        <HighchartsReact
            // containerProps={{ style: { gridRow: "1 / 2", gridColumn: "3/5" } }}
            highcharts={Highcharts}
            options={mapOptions}
        // callback={chart => setTimeout(() => chart.reflow(), 10)}
        />
    )
}

export default CombinationChart;
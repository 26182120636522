// import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import { runWithAdal } from 'react-adal';
import { AuthContext } from './config/AdalConfig';

import './custom.css'
import { message } from 'antd';

const DO_NOT_LOGIN = false;

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const newVersionLoading = () => {
  message.info("New version of app loading. Please Wait...")
}

const newVersionLoaded = () => {
  message.success("New version of app loaded.")
}

const swConfig = {
  onSuccess: newVersionLoaded,
  onUpdate: newVersionLoading
};


runWithAdal(AuthContext, () => {
  ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>,
    rootElement);
}, DO_NOT_LOGIN)


// temporarily disabling until a better fix or full deployment
// registerServiceWorker();
serviceWorkerRegistration.register(swConfig);

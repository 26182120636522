import React, { useEffect, useState } from "react";


import {
    Card,
    Descriptions
} from 'antd';
import { ProjectCardType } from "../models/Core";




const ProjectCard = ({ Project, translate }: ProjectCardType) => {

    const [project, setProject] = useState(Project);


    useEffect(() => {
        if (project !== Project) {
            setProject(Project);
        }
    }, [Project]);

    return (
        <Card title={`${project.ProjectName} - ${project.ProjectNumber}`} className="mb-3">
            <Descriptions
                bordered
            >
                <Descriptions.Item
                    label={translate("ProjectScope")}
                    span={24}
                >
                    {project.ProjectScope}
                </Descriptions.Item>

                <Descriptions.Item
                    label={translate("Location")}
                    span={24}
                >
                    {project.Location}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("UltimateClient")}
                    span={24}
                >
                    {project.UltimateClient?.Name}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("WSPClient")}
                    span={24}
                >
                    {project.WSPClient?.Name}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("KeyAccountManager")}
                    span={24}
                >
                    {project.KeyAccountManager?.FullName}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("ProjectManager")}
                    span={24}
                >
                    {project.ProjectManager?.FullName}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("BuisnessGroup")}
                    span={24}
                >
                    {translate(project.BuisnessGroup)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={translate("ProjectStage")}
                    span={24}
                >
                    {translate(project.ProjectStage)}
                </Descriptions.Item>
            </Descriptions>

        </Card>
    )
}

export default ProjectCard;
import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import mime from 'mime-types';
import api from './Api';
import { GiftFilterDTO, giftFilterFormType, pagination, sorter } from '../models/Core';
import { RcFile } from 'antd/lib/upload';


const GiftService = {
  NAME: "GIFT SERVIVCE",
  getGifts: async function () {
    try {
      const response = await axios.get(`${ApiConfig.baseURL}/api/gifts`);
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  getActions: async function (filter: GiftFilterDTO, pagination: pagination, sorter: sorter, query: string, filterRequestReview: boolean) {
    try {

      const types = filter.Type ? filter.Type : [];
      const OfferedOn = filter.OfferedOn ? filter.OfferedOn : [];
      const CreatedOn = filter.CreatedOn ? filter.CreatedOn : [];
      let Status = filter.Status ? filter.Status : [];
      if (filterRequestReview) {
        Status = [...Status, 1]
      }
      const response = await api.get(`${ApiConfig.baseURL}/api/gifts/GetActions`, {
        params: {
          BuisnessName: filter.BuisnessName, CriticalPeriod: filter.CriticalPeriod, Currencies: filter.Currencies,
          OfferedOn: OfferedOn.map(x => x.toDate()),
          CreatedOn: CreatedOn.map(x => x.toDate()),
          AcceptanceStatus: filter.AcceptanceStatus, GiftCategoryItems: filter.GiftCategoryItems,
          PreapprovalStatus: filter.PreapprovalStatus, Priorities: filter.Priorities, RelationshipTypes: filter.RelationshipTypes,
          Employee: filter.Employee, ExpenseReportNumber: filter.ExpenseReportNumber, Type: filter.Type,
          Status, GivenRecieved: filter.GivenRecieved,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          sortDir: sorter.order, searchQuery: query, Region: filter.Region,
          BuisnessCountry: filter.BuisnessCountry, PublicOfficialName: filter.PublicOfficialName, PublicOfficial: filter.PublicOfficial,
          Id: filter.Id ?? 0
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  getGift: async function (id: string) {
    try {
      const response = await api.get(`${ApiConfig.baseURL}/api/gifts/hub/${id}`);
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  markInReview: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/inreview`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  markUnderReview: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/underreview`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  reopen: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/Reopen`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
    moveItem: async function (ID: number) {
        try {
            const response = await api.put(`${ApiConfig.baseURL}/api/gifts/MoveItem`, qs.stringify({
                ID
            }));
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
  approveGift: async function (ID: number, ApprovedValue: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/approve`, qs.stringify({
        ID, ReviewComments, ApprovedValue
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  deleteGift: async function (ID: number) {
    try {
      const response = await api.post(`${ApiConfig.baseURL}/api/gifts/DeleteGift/${ID}`);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (e) {
      console.log(this.NAME, e);
      return false
    }
  },

  reqReviewGift: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/requestReview`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  rejectGift: async function (ID: number, ApprovedValue: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/reject`, qs.stringify({
        ID, ReviewComments, ApprovedValue
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  updateOrganization: async function (ID: number, OrganizationID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/gifts/updateorganization`, qs.stringify({
        ID, OrganizationID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  uploadFiles: async function (fileList: RcFile[], ID: string, shareWithUser: string, EmailUser: string, EmailComment: string) {
    try {
      const formData = new FormData();
      formData.append("GiftID", ID);
      formData.append("ShareWithUser", shareWithUser);
      formData.append("EmailUser", EmailUser);
      formData.append("EmailComment", EmailComment);
      fileList.forEach(file => {
        formData.append("files", file)

      });

      const response = await api.post(`${ApiConfig.baseURL}/api/gifts/hubUpload`, formData);


      if (response && response.status === 200) {
        return response.data;
      } else {
        return null
      }

    } catch (e) {
      console.log(this.NAME, e);
      return null;
    }
  },

  downloadFile: async function (ID: number) {
    try {
      axios({
        url: `${ApiConfig.baseURL}/api/gifts/DownloadFile/${ID}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '');
            link.setAttribute('download', filename);

          }
        }
        document.body.appendChild(link);
        link.click();
      });
    } catch (e) {
      console.log(this.NAME, e);
    }
  }
  ,
  downloadActionsGift: async function (filter: GiftFilterDTO, pagination: pagination, sorter: sorter, query: string, filterRequestReview: boolean, selectedRowKeys: number[], selectedColumns: string[]) {
    try {

      const OfferedOn = filter.OfferedOn ? filter.OfferedOn : [];
      const CreatedOn = filter.CreatedOn ? filter.CreatedOn : [];

      let Status = filter.Status ? filter.Status : [];
      if (filterRequestReview) {
        Status = [...Status, 1]
      }

      const res = await axios({
        url: `${ApiConfig.baseURL}/api/gifts/GetActionsExcel/`,

        params: {
          BuisnessName: filter.BuisnessName,
          OfferedOn: OfferedOn.map(x => x.toDate()),
          CreatedOn: CreatedOn.map(x => x.toDate()),
          Employee: filter.Employee, Type: filter.Type, Status,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          AcceptanceStatus: filter.AcceptanceStatus, GiftCategoryItems: filter.GiftCategoryItems,
          PreapprovalStatus: filter.PreapprovalStatus, Priorities: filter.Priorities, RelationshipTypes: filter.RelationshipTypes,
          ExpenseReportNumber: filter.ExpenseReportNumber,
          GivenRecieved: filter.GivenRecieved,
          Currencies: filter.Currencies,
          BuisnessCountry: filter.BuisnessCountry, PublicOfficialName: filter.PublicOfficialName, SelectedIDs: selectedRowKeys,
          SelectedColumns: selectedColumns,
          searchQuery: query, Region: filter.Region,
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        },

        method: 'GET',
        responseType: 'blob', // important
      })
      if (res) {

        const contentType = res.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
        return true;

      }
    } catch (e) {
      console.log(this.NAME, e);
      return false;
    }
    return false;
  },
};

export default GiftService;
import React, { Component } from 'react'
import { Layout, Menu, Avatar, Spin, notification, Button } from 'antd';
import Gifts from '../components/Gifts';
import Dashboard from '../components/Dashboard';
import Admin from '../components/Admin';
import _ from 'lodash';
import { Route, Link, NavLink } from 'react-router-dom';
import { WithAdalLoginApi, AuthContext, adalConfig } from '../config/AdalConfig';
import { UserOutlined } from '@ant-design/icons';
import GiftDetail from '../components/GiftDetail';
import COIDetail from '../components/COIDetail';
import OrgCOIDetail from '../components/OrgCOIDetail';
import AuthService from '../services/AuthService';
import BaseService from '../services/BaseService';
import { ArgsProps } from 'antd/lib/message';
import { EmployeeWithPermissions, emptyEmployee, EmptyFilter, FilterDTO, pagination, sorter } from '../models/Core';
import { History } from 'history';
import EmployeeService from '../services/EmployeeService';



const { Header, Content, Footer } = Layout;

interface IRoutes {
    key: number,
    menu: string | null,
    route: string,
    menuRoute: string,
    exact: boolean,
    component: any
}


let routes: IRoutes[] = [
    { key: 1, menu: 'Dashboard', menuRoute: '/Dashboard', route: '/Dashboard', component: Dashboard, exact: true },

    { key: 2, menu: 'Actions', menuRoute: '/', route: '/', component: Gifts, exact: false },

    { key: 4, menu: null, menuRoute: '/actions/:id', route: '/actions/:id', component: GiftDetail, exact: true },
    { key: 5, menu: null, menuRoute: '/coiActions/:id', route: '/coiActions/:id', component: COIDetail, exact: true },
    { key: 6, menu: null, menuRoute: '/Organisational/:id', route: '/Organisational/:id', component: OrgCOIDetail, exact: true },

];

interface IHomeProps {
    history: History,

}

interface IHomeState {
    path: string,
    selectedPathIndex: string,
    user: EmployeeWithPermissions,
    loading: boolean,
    supportedLanguages: string[],
    resources: any,
    languageCode: string,
    canUse: boolean,
    CanViewOrgCOI: boolean,
    userFilter: FilterDTO,
    pagination: pagination,
    sorter: sorter,
    currentTab: string,
    textSearchQuery: string,
    userPhoto: string
}


export default class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props)
        this.state = {
            path: '',
            selectedPathIndex: "2",
            user: {
                ...emptyEmployee,
                IsAdmin: false,
                IsLegalOfficer: false,
                IsEthicOfficer: false,
                CanViewOrgCOI: false,
                RoleID: 0
            },
            loading: false,
            supportedLanguages: [],
            resources: {},
            languageCode: '',
            canUse: false,
            CanViewOrgCOI: false,
            userFilter: EmptyFilter,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            sorter: {
                field: "",
                order: ""
            },
            currentTab: "2",
            textSearchQuery: "",
            userPhoto: ""

        }
    }
    componentWillMount() {
        // if (!AuthContext.getCachedToken(AdalConfig.clientId) || !AuthContext.getCachedUser()) {
        //     this.props.history.push({ pathname: '/auth' })
        // }

        // const selectedPathIndex = _.find(routes, { route: window.location.pathname })['key']
        // this.setState({ selectedPathIndex: selectedPathIndex.toString() })
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            this.getData();
        })
    }

    getData = async () => {
        AuthService.getLoggedInUser().then(async (user: EmployeeWithPermissions) => {
            const canUse = user.RoleID > 1;
            if (user.IsAdmin || user.IsEthicOfficer) {

                routes.push({ key: 7, menu: "Admin", menuRoute: '/admin/', route: '/admin/:id?', component: Admin, exact: true })
            }
            await BaseService.setCurrentLanguage('en');

            this.setState({
                user,
                canUse,
                CanViewOrgCOI: user.CanViewOrgCOI,
                languageCode: 'en'
            })


            //If user is just legal officer skip dashboard
            if (user.IsLegalOfficer && (user.IsEthicOfficer === false && user.IsAdmin === false)) {
                routes = routes.filter(x => x.key === 2 || x.key === 6);
                console.log(routes)
                this.setState({ currentTab: "4" });
                if (this.props.history.location.pathname === "/") {
                    this.props.history.push(`/actions`)
                }
            }
            await this.getLanguageData();

            this.setState({ loading: false });
            let photo = await AuthService.userPhotoByID(user.UsersAzureID);
            if (photo) {
                this.setState({ userPhoto: photo });
            }
        }).catch(() => {
            this.setState({ canUse: false, loading: false })
        });





    }

    updateSearchFilter = async (userFilter: FilterDTO) => {
        this.setState({ userFilter });
    }

    updatePagination = async (pagination: pagination) => {
        this.setState({ pagination });
    }

    updateSorter = async (sorter: sorter) => {

        this.setState({ sorter });
    }

    setTextSearchQuery = (query: string) => {
        this.setState({ textSearchQuery: query })
    }



    getLanguageData = async () => {

        return new Promise(async (res, _) => {
            let currentLanguage = await BaseService.getCurrentLanguage();
            if (currentLanguage === null) {
                currentLanguage = 'en';
                BaseService.setCurrentLanguage(currentLanguage);
            }

            const supportedLanguages = await BaseService.getLanguages();
            const resources = await BaseService.getHubNavResources();


            res(this.setState({ supportedLanguages, resources, languageCode: currentLanguage }));
        })



    }


    translate = (label: string) => {
        if (this.state.resources[label]) {
            return this.state.resources[label]
        }
        return label;
    }

    setLanguage = async (lang: string) => {
        this.setState({ loading: true }, async () => {
            await BaseService.setCurrentLanguage('en');
            await this.getLanguageData();
            await EmployeeService.updatePreferredLanguage(lang);
        })
    }

    updateCurrentTab = (currentTab: string) => {
        this.setState({ currentTab });
        if (this.state.textSearchQuery.length > 0) {
            this.setState({ textSearchQuery: '' })
        }
    }

    isDEV = () => {
        const location = window.location.hostname;
        return (location.includes("localhost")
            || location === "127.0.0.1" ||
            location.includes("gehhub")
        );

    }

    render() {
        const { languageCode, canUse, loading, userFilter, pagination, sorter, currentTab, CanViewOrgCOI, user, textSearchQuery, userPhoto } = this.state;
        return (
            <Layout>
                <Header className={this.isDEV() ? "wsp-dev-bg" : "wsp-dark-bg"} style={{ position: 'fixed', zIndex: 1, width: '100%', }}>
                    <img src="/img/wsp_logo.png" className="app-logo" />
                    {this.isDEV() ? <span className="app-menu  txt-white" style={{ fontSize: "1.3em", lineHeight: "1em" }}>DEV</span> : null}
                    {canUse && <Menu disabledOverflow={true} className={this.isDEV() ? "app-menu wsp-dev-bg" : "app-menu wsp-dark-bg"} theme="dark" mode="horizontal" defaultSelectedKeys={[this.state.selectedPathIndex]}>
                        {
                            routes.map(r => (
                                r.menu &&
                                <Menu.Item key={r.key}>
                                    <NavLink to={r.menuRoute}>{this.translate(r.menu)}</NavLink>
                                </Menu.Item>

                            ))
                        }
                    </Menu>}
                    <span className="userName">{this.state.user.FullName}</span>
                    {
                        userPhoto && userPhoto.length > 0 ?
                            <Avatar style={{ position: 'absolute', top: '15px', right: '15px' }} shape="square" size="default" src={userPhoto} />
                            :
                            <Avatar style={{ position: 'absolute', top: '15px', right: '15px' }} shape="square" size="default" icon={<UserOutlined />} />
                    }
                </Header>
                {canUse ? <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64, minHeight: '80vh' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
                        {
                            routes.map(r => (
                                <Route key={r.key} exact path={r.route} render={props => (
                                    <r.component {...props} {...{
                                        languageCode: languageCode, setLanguage: this.setLanguage,
                                        updateSearchFilter: this.updateSearchFilter, userFilter, pagination,
                                        updatePagination: this.updatePagination, sorter, updateSorter: this.updateSorter,
                                        currentTab, updateCurrentTab: this.updateCurrentTab, CanViewOrgCOI,
                                        user, textSearchQuery, setTextSearchQuery: this.setTextSearchQuery
                                    }} />
                                )} />
                            ))
                        }
                    </div>
                </Content> : loading ? <Spin /> : <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64, minHeight: '80vh' }}><span>Please contact an admin for access</span></Content>}
                <Footer style={{ textAlign: 'center' }}>&copy; WSP Global - Build: {window.appVersion}</Footer>
            </Layout>
        )
    }
}
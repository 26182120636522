import React, { useState, useEffect } from 'react';

import {
    Table, Input, Button, Row, Col, Tag, Dropdown, Menu, Checkbox, Spin,
} from 'antd';

import { DownOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import Filters from './Filters';
import { pagination, sorter, FilterDTO, ActionReturnType, EmptyFilter, GenericDTO, ActionsDTO } from '../models/Core';
import { App } from '../models/Enums';
import { TableRowSelection } from 'antd/lib/table/interface';
import { SearchProps } from 'antd/lib/input';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
const { Search } = Input;


interface ISearchTableProps {
    columns: any,
    translate(text: string): string,
    pagination: pagination,
    userFilter: FilterDTO,
    dropDowns: any,
    updateSearchFilter(filter: FilterDTO): void,

    sorter: sorter,
    getActions(filter: FilterDTO, pagination: pagination, sorter: sorter, query: string): Promise<ActionReturnType>,
    updatePagination(pag: pagination): void,
    updateSorter(sorter: sorter): void,
    showStatus: boolean,
    coi: boolean,
    excelFileService(filter: FilterDTO, pagination: pagination, sorter: sorter, query: string, selectedRowKeys: number[]): Promise<boolean>,

    tableColumns: string[],
    visibleColumns: string[],
    setNewVisibleColumns(columns: string[]): void,
    filterApp: App
}

const SearchTable = ({ columns, translate, pagination, userFilter, dropDowns, updateSearchFilter,
    sorter, getActions, updatePagination, updateSorter, showStatus, coi, excelFileService,
    tableColumns, visibleColumns, setNewVisibleColumns,
    filterApp }: ISearchTableProps) => {

    const [query, setQuery] = useState("");
    const [gifts, setGifts] = useState<ActionsDTO[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [total, setTotal] = useState(0);
    const [localPag, setLocalPag] = useState(pagination);

    const [downloadingDoc, setDownloadingDoc] = useState(false);


    const [loading, setLoading] = useState(false);
    const [columnVisible, setColumnVisible] = useState(false);
    const [downLoadVisible, setDownloadVisible] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);



    useEffect(() => {
        getData(userFilter, pagination, sorter, query);
    }, []);

    useEffect(() => {
        if (localPag !== pagination) {

            setLocalPag(pagination);
        }


    }, [pagination])

    const getData = async (localFilter: FilterDTO, localPagination: pagination, localSorter: sorter, localQuery: string) => {


        setLoading(true);
        const res = await getActions(localFilter, localPagination, localSorter, localQuery)
        setGifts(res.Data);
        setTotal(res.Count);
        setLocalPag({ ...localPagination, total: res.Count })
        updatePagination({ ...localPagination, total: res.Count })
        setLoading(false);

    }

    const search = () => {
        getData(userFilter, pagination, sorter, query);
    }


    const getExcelFile = async (localFilter: FilterDTO, localPagination: pagination, localSorter: sorter, localQuery: string, localRowSelected: number[]) => {
        if (excelFileService) {
            setLoading(true);
            const res = await excelFileService(localFilter, localPagination, localSorter, localQuery, localRowSelected);
            setLoading(false);
        }
    }

    const downloadFile = () => {
        getExcelFile(userFilter, pagination, sorter, query, selectedRowKeys);

    }


    const downloadAllFile = () => {
        setDownloadingDoc(true);

    }

    const awaitFileDownload = async (userFilter: FilterDTO, pagination: pagination, sorter: sorter, query: string, selectedRowKeys: number[]) => {
        let test = await getExcelFile(userFilter, pagination, sorter, query, selectedRowKeys);
        console.log(test);
        setDownloadingDoc(false);
    }

    useEffect(() => {
        if (downloadingDoc) {
            awaitFileDownload(userFilter, { ...pagination, current: 1, pageSize: pagination.total }, sorter, query, selectedRowKeys);
        }
    }, [downloadingDoc])

    const setFilter = (data: FilterDTO) => {
        updateSearchFilter(data);
        setShowFilterModal(false);
        getData(data, pagination, sorter, query);

    };

    const clear = () => {
        updateSearchFilter(EmptyFilter);
        setQuery('')
        getData(EmptyFilter, pagination, sorter, query);

    };

    const handleTableChange = async (pagination: pagination, sorter: sorter) => {
        setLocalPag(pagination);
        await updatePagination(pagination);
        await updateSorter(sorter);
        getData(userFilter, pagination, sorter, query);

    };


    const removeFilter = (key: keyof FilterDTO, index: number) => {
        let filterType = userFilter[key];

        if (filterType && key !== "OfferedOn" && key !== "CreatedOn" && Array.isArray(filterType) && filterType.length >= index) {

            filterType.splice(index, 1)
            //@ts-ignore
            userFilter[key] = filterType;
            setFilter(userFilter);
        }
        if (filterType && key === "OfferedOn" || key === "CreatedOn" || !Array.isArray(filterType)) {
            //@ts-ignore
            userFilter[key] = null;
            setFilter(userFilter);

        }


    }

    const findNameInDropDowns = (dropDowns: GenericDTO[], ID: number) => {
        if (!dropDowns) {
            return ID
        }
        const value = dropDowns.find(x => x.ID === ID);
        if (value) {
            return value.Name
        }
        return ID
    }

    const handleOnClick = (e: CheckboxChangeEvent, column: string) => {
        const checked = e.target.checked;
        let newColumns = visibleColumns;
        if (checked) {
            newColumns = [...newColumns, column]
        } else {
            newColumns = newColumns.filter(x => x !== column)
        }
        setNewVisibleColumns(newColumns);
    }

    const handleVisibleChange = (flag: boolean) => {
        setColumnVisible(flag);
    }
    const menu = (
        <Menu >
            {tableColumns.map(column =>
                <Menu.Item key={column}>
                    <Checkbox checked={visibleColumns.includes(column)} onChange={(e) => handleOnClick(e, column)}>{translate(column)}</Checkbox>
                </Menu.Item>
            )}

        </Menu>
    );
    const handleDownloadVisibleChange = (flag: boolean) => {
        setDownloadVisible(flag);
    }
    const menuDownloads = (
        <Menu >
            <Menu.Item>
                <Button style={{ display: 'inline-block', marginLeft: 10 }} onClick={() => downloadFile()}>{translate("DownloadExcel")}</Button>
            </Menu.Item>

            <Menu.Item>
                <Button style={{ display: 'inline-block', marginLeft: 10 }} disabled={downloadingDoc} onClick={() => downloadAllFile()}>{downloadingDoc && <Spin style={{ marginRight: 10 }} />} {translate("DownloadAllExcel")}</Button>

            </Menu.Item>

        </Menu>
    );

    const convertTags = (key: string, value: any) => {
        const conflict = coi ? coi : false;
        let dateArray: Moment[];

        switch (key) {
            case "Type":
                value = conflict ? findNameInDropDowns(dropDowns.conflictTypes, value as number)
                    : findNameInDropDowns(dropDowns.giftCategories, value as number)
                break;
            case "Region":
                value = findNameInDropDowns(dropDowns.organizations, value as number);

                break;
            case "Status":
                value = findNameInDropDowns(dropDowns.status, value as number);

                break;
            case "GivenRecieved":
                value = value === 1 ? "Given" : "Recieved"

                break;
            case "OfferedOn":
                dateArray = value as Moment[];
                value = dateArray.map(x => moment(x.toDate()).local().format("MMM Do, YYYY")).join(translate(" through "))

                break;
            case "CreatedOn":
                dateArray = value as Moment[];

                value = dateArray.map(x => moment(x.toDate()).local().format("MMM Do, YYYY")).join(translate(" through "))

                break;
            case "CriticalPeriod":
                value = findNameInDropDowns(dropDowns.criticalPeriods, value as number);

                break;
            case "acceptanceStatus":
                value = findNameInDropDowns(dropDowns.acceptanceStatus, value as number);

                break;

            case "giftCategoryItems":
                value = findNameInDropDowns(dropDowns.giftCategoryItems, value as number);

                break;

            case "preapprovalStatus":
                value = findNameInDropDowns(dropDowns.preapprovalStatus, value as number)

                break;

            case "relationshipTypes":
                value = findNameInDropDowns(dropDowns.relationshipTypes, value as number);

                break;

            case "PersonalObjectOfConflicts":
                value = findNameInDropDowns(dropDowns.personalObjectOfConflicts, value as number);

                break;
            case "OrganizationalObjectOfConflicts":
                value = findNameInDropDowns(dropDowns.organizationalObjectOfConflicts, value as number);

                break;
            case "PublicOfficialRelationshipTypes":
                value = findNameInDropDowns(dropDowns.publicOfficialRelationshipTypes, value as number);

                break;
            case "ConflictStatus":
                value = findNameInDropDowns(dropDowns.conflictStatus, value as number);

                break;

            case "ConflictingProject":
                switch (value as number) {
                    case 1:
                        value = "Yes"
                        break;
                    case 2:
                        value = "No"
                        break;
                    case 3:
                        value = "DontKnow"
                        break;
                    default:
                        break;
                }
                break;
            case "RequestType":
                value = findNameInDropDowns(dropDowns.requestType, value as number);

                break;
            case "Truthful":
                value = value === 1 ? "Yes" : "No"

                break;
            case "CompliesWithPolicy":
                value = value === 1 ? "Yes" : "No"

                break;
            case "PublicOfficial":
                value = value === 1 ? "Yes" : "No"

                break;



            default:
                break;
        }
        return value;

    }


    const tags = () => {
        return Object.keys(userFilter).map(filter => {
            let filterKey = filter as keyof FilterDTO;
            if (userFilter[filterKey] && filterKey) {
                if (filter === "OfferedOn" || filter === "CreatedOn" || !Array.isArray(userFilter[filterKey])) {
                    const items = userFilter[filterKey]
                    return <Tag key={`${filter}`} closable onClose={e => { e.preventDefault(); removeFilter(filterKey, 0) }}>{translate(filter)} : {translate(convertTags(filter, items))}</Tag>
                }


                return (userFilter[filterKey] as any[]).map((items, index: number) => <Tag key={`${filter}:${index}`} closable onClose={e => { e.preventDefault(); removeFilter(filterKey, index) }}>{translate(filter)} : {translate(convertTags(filter, items))}</Tag>)
            }
            return null
        })
    }
    const onSelectChange = (ids: number[]) => {
        setSelectedRowKeys([...ids])
    };

    const rowSelection: TableRowSelection<SearchProps> = {
        selectedRowKeys,
        onChange: (key) => onSelectChange(key.map(x => x as number)),
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_NONE,

        ],
    };

    const searchID = (query: string) => {
        let number = Number.parseInt(query);
        if (number && number > 0) {
            setFilter({ ...userFilter, Id: number })
        }
    }

    return (
        <div>
            <Row style={{ marginBottom: 20 }}>
                <Col span={3}>
                    <Search placeholder={translate("SearchID")} defaultValue={""} onSearch={(searchValue) => searchID(searchValue)} />

                </Col>
                <Col span={10} offset={1}>

                    <div className="ant-col ant-form-item-control ant-select ant-select-multiple" onClick={() => setShowFilterModal(true)}>
                        <div className="ant-select-selector">
                            {Object.keys(userFilter).length == 0
                                ? <span className="ant-select-selection-placeholder">{translate("CurrentFilters")}</span>
                                : <span >
                                    {tags()}
                                </span>}

                        </div>
                    </div>

                </Col>

                <Col span={7} offset={1} style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <Button style={{ display: 'inline-block' }} type="primary" onClick={() => search()}>{translate("Search")}</Button>

                    <Button style={{ display: 'inline-block', marginLeft: 10 }} onClick={() => setShowFilterModal(true)} type="default" >{translate("Filter")}</Button>

                    <Button style={{ display: 'inline-block', marginLeft: 10 }} type="ghost" onClick={() => { clear() }}>{translate("Clear")}</Button>
                    <Dropdown overlay={menu} onVisibleChange={handleVisibleChange} visible={columnVisible}><Button style={{ display: 'inline-block', marginLeft: 10 }} >
                        {translate("TableColumns")} <DownOutlined />
                    </Button></Dropdown>
                    <Dropdown overlay={menuDownloads} onVisibleChange={handleDownloadVisibleChange} visible={downLoadVisible}><Button style={{ display: 'inline-block', marginLeft: 10 }} >
                        {translate("Download")} <DownOutlined />
                    </Button></Dropdown>



                </Col>
            </Row>

            <Table dataSource={gifts}
                columns={columns}
                rowSelection={rowSelection}
                scroll={{ x: true }}
                pagination={{
                    ...pagination,
                    total: total,
                    showTotal: (total) => `${total} ${translate("items")}`,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
                }}

                onChange={(pag, _, sor) => handleTableChange(pag as pagination, sor as sorter)}
                loading={loading}
            />

            <Filters filterVisible={showFilterModal}
                translate={translate}
                onCancel={() => setShowFilterModal(false)}
                setFilter={setFilter}
                initData={userFilter}
                dropDowns={dropDowns}
                showStatus={showStatus}
                coi={coi}
                filterApp={filterApp}
            />
        </div>

    )
}

export default SearchTable;
import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import mime from 'mime-types';
import api from './Api';
import { OrgConflictFilterDTO, pagination, sorter } from '../models/Core';


const OrgCOIService = {
  NAME: "ORGANIZATIONAL CONFLICT OF INTEREST SERVICE",

  getActionsOrgConflict: async function (filter: OrgConflictFilterDTO, pagination: pagination, sorter: sorter, query: string) {
    try {

      const OfferedOn = filter.OfferedOn ? filter.OfferedOn : [];
      const CreatedOn = filter.CreatedOn ? filter.CreatedOn : [];

      const ConflictingProject = filter.ConflictingProject ?? [];
      const RequestType = filter.RequestType ?? [];
      let Status = filter.Status ? filter.Status : [];
      const BusinessGroup = filter.BusinessGroup ?? [];


      const response = await api.get(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/GetActions`, {
        params: {
          OfferedOn: OfferedOn.map(x => x.toDate()), Region: filter.Region,
          Employee: filter.Employee, Status, CreatedOn: CreatedOn.map(x => x.toDate()),
          Truthful: filter.Truthful, CompliesWithPolicy: filter.CompliesWithPolicy,
          ConflictingProject, ConflictStatus: filter.ConflictStatus, RequestType,
          ProjectName: filter.ProjectName, ProjectNumber: filter.ProjectNumber,
          ConflictingProjectName: filter.ConflictingProjectName, ConflictingProjectNumber: filter.ConflictingProjectNumber,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          sortDir: sorter.order, searchQuery: query, BusinessGroup, ProgramOfWorks: filter.ProgramOfWorks,
          ProjectManager: filter.ProjectManager, WSPClient: filter.WSPClient, UltimateClient: filter.UltimateClient,
          Id: filter.Id ?? 0
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },
  getOrgCOI: async function (id: string) {
    try {
      const response = await api.get(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/hub/${id}`);
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  markUnderReviewOrgCOI: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/underreview`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  markInReviewOrgCOI: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/inreview`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  deleteOrgCOI: async function (ID: number) {
    try {
      const response = await api.post(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/Delete/${ID}`);
      return response;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  closeOrgCOI: async function (ID: number, Justification: string, AssessmentResultID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/Close`, qs.stringify({
        ID, Justification, AssessmentResultID
      }));
      return response;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  monitorOrgCOI: async function (ID: number, Justification: string, AssessmentResultID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/Monitor`, qs.stringify({
        ID, Justification, AssessmentResultID
      }));
      return response;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  approveOrgCOI: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/approve`, qs.stringify({
        ID,
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  reopen: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/Reopen`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  rejectOrgCOI: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/reject`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  updateOrganizationOrgCOI: async function (ID: number, OrganizationID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/updateorganization`, qs.stringify({
        ID, OrganizationID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  uploadFilesOrgCOI: async function (fileList: Blob[], ID: string, shareWithUser: string, EmailUser: string, EmailComment: string) {
    try {
      const formData = new FormData();
      formData.append("ConflictOfInterestID", ID);
      formData.append("ShareWithUser", shareWithUser);
      formData.append("EmailUser", EmailUser);
      formData.append("EmailComment", EmailComment);
      fileList.forEach(file => {
        formData.append("files", file)

      });

      const response = await api.post(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/hubUpload`, formData);


      if (response && response.status === 200) {
        return response.data;
      } else {
        return null
      }

    } catch (e) {
      console.log(this.NAME, e);
      return null;
    }
  },

  downloadActionsOrgConflict: async function (filter: OrgConflictFilterDTO, pagination: pagination, sorter: sorter, query: string, selectedRowKeys: number[], selectedColumns: string[]) {
    try {

      const OfferedOn = filter.OfferedOn ? filter.OfferedOn : [];
      const CreatedOn = filter.CreatedOn ? filter.CreatedOn : [];
      let Status = filter.Status ? filter.Status : [];
      const ConflictingProject = filter.ConflictingProject ?? [];
      const RequestType = filter.RequestType ?? [];
      let response = await axios({
        url: `${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/GetActionsExcel`,

        params: {
          OfferedOn: OfferedOn.map(x => x.toDate()), Region: filter.Region,
          Employee: filter.Employee, Status, CreatedOn: CreatedOn.map(x => x.toDate()),
          Truthful: filter.Truthful, CompliesWithPolicy: filter.CompliesWithPolicy,
          ConflictingProject, ConflictStatus: filter.ConflictStatus, RequestType,
          ProjectName: filter.ProjectName, ProjectNumber: filter.ProjectNumber,
          ConflictingProjectName: filter.ConflictingProjectName, ConflictingProjectNumber: filter.ConflictingProjectNumber,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          sortDir: sorter.order, searchQuery: query, SelectedIDs: selectedRowKeys,
          SelectedColumns: selectedColumns,
          Id: filter.Id
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        },

        method: 'GET',
        responseType: 'blob', // important
      })
      if (response) {
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
        return true;
      }
    } catch (e) {
      console.log(this.NAME, e);
      return false;
    }
    return false;

  },

  downloadFileOrgCOI: async function (ID: number) {
    try {
      axios({
        url: `${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/DownloadFile/${ID}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '');
            link.setAttribute('download', filename);

          }
        }
        link.click();
      });
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  AddEmployeeAccess: async function (ID: number, empID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/AddEmployeeAccess`, qs.stringify({
        conflictId: ID, employeeId: empID
      }));

      if (response && response.status === 200) {
        return true;
      }
      return false
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  RemoveEmployeeAccess: async function (ID: number, empID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/RemoveEmployeeAccess`, qs.stringify({
        conflictId: ID, employeeId: empID
      }));

      if (response && response.status === 200) {
        return true;
      }
      return false
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  AssignAdvisor: async function (ID: number, empID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/AssignAdvisor`, qs.stringify({
        conflictId: ID, employeeId: empID
      }));

      if (response && response.status === 200) {
        return true;
      }
      return false
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  RemoveAdvisor: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/OrganizationalConflictsOfInterest/RemoveAdvisor`, qs.stringify({
        conflictId: ID
      }));

      if (response && response.status === 200) {
        return true;
      }
      return false
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
};

export default OrgCOIService;
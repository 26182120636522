import React, { Component, useState, useEffect } from 'react'
import { Table, Spin, Row, Col, Button, Modal, Form, Input, Select, Switch, AutoComplete } from 'antd';
import OrganizationService from '../services/OrganizationService';
import DropdownService from '../services/DropdownService';
import RuleService from '../services/RuleService';
import _ from 'lodash';
import { Employee, GenericDTO, Organization, OrganizationDTO, Rule, RuleDropdowns, TreeDTO } from '../models/Core';
import { ColumnsType } from 'antd/lib/table';

const FormItem = Form.Item;


interface ICreateRuleProps {
    visible: boolean,
    onCancel(): void,
    onCreate(values: any): void,
    currencies: GenericDTO[],
    giftCategories: GenericDTO[],
    ruleTypes: GenericDTO[],
    organizations: OrganizationDTO[],
    publicOfficialRelationshipTypes: GenericDTO[],
    translate(text: string): string
}

const CreateRule = ({ visible, onCancel, onCreate, currencies, giftCategories, ruleTypes, organizations, publicOfficialRelationshipTypes, translate }: ICreateRuleProps) => {
    const [giftType, setGiftType] = useState<number>(0);
    const [isCOI, setIsCOI] = useState(false);
    const [form] = Form.useForm();


    const prefixSelector = (
        <Form.Item name="ParameterCurrency" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                {
                    currencies.map((currency) =>
                        <Select.Option key={currency.ID} value={currency.ID}>{currency.Name}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
    );

    return (
        <Modal
            visible={visible}
            title={translate("CreateANewRule")}
            okText={translate("Create")}
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    }).catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form layout="vertical" form={form} initialValues={{
                Given: false,
                COI: false
            }}>
                <FormItem label={translate("RuleName")} name="Name" rules={[{ required: true, message: translate("RuleNameValidationMessage") }]}>
                    <Input />
                </FormItem>

                {(!isCOI) ?
                    <div>
                        <Form.Item
                            name="RegionIDs"
                            label={translate("AppliesToRegions")}
                            rules={[
                                {
                                    required: true,
                                    message: translate("RegionValidationMessage"),
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select mode="multiple" placeholder={translate("GiftCategoriesPlaceHolder")}>
                                {
                                    organizations.map((org) =>
                                        <Select.Option key={org.ID} value={org.ID}>{org.Name}</Select.Option>
                                    )
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="GiftCategories"
                            label={translate("GiftCategories")}
                            rules={[
                                {
                                    required: true,
                                    message: translate("GiftCategoriesValidationMessage"),
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select mode="multiple" placeholder={translate("GiftCategoriesPlaceHolder")}>
                                {
                                    giftCategories.map((category) =>
                                        <Select.Option key={category.ID} value={category.ID}>{category.Name}</Select.Option>
                                    )
                                }

                            </Select>
                        </Form.Item>
                        <FormItem label={translate("Given/Received")} name="Given" valuePropName="checked">
                            <Switch checkedChildren="Given" unCheckedChildren="Received" />
                        </FormItem>

                        <Form.Item label={translate("RuleType")} name="RuleType" rules={[{ required: true, message: translate("RuleTypeValidationMessage") }]}>
                            <Select onChange={(e) => setGiftType(e as number)}>
                                {ruleTypes.map((rule) => <Select.Option key={rule.ID} value={rule.ID}>{translate(rule.Name)}</Select.Option>)}

                            </Select>
                        </Form.Item>

                        {giftType === 1 &&
                            <Form.Item label={translate("RestrictedCurrency")} name="parameterValue" rules={[{ required: true, message: translate("RestrictedCurrencyValidationMessage") }]}>
                                <Select >
                                    {
                                        currencies.map((currency) =>
                                            <Select.Option key={currency.ID} value={currency.ID}>{currency.Name}</Select.Option>
                                        )
                                    }

                                </Select>
                            </Form.Item>
                        }
                        {giftType === 3 &&
                            <Form.Item label={translate("RestrictedPublicOfficalType")} name="ParameterValue" rules={[{ required: true, message: translate("RestrictedPublicOfficalValidationMessage") }]}>
                                <Select >
                                    {
                                        publicOfficialRelationshipTypes.map((official) =>
                                            <Select.Option key={official.ID} value={String(official.ID)}>{official.Name}</Select.Option>
                                        )
                                    }

                                </Select>
                            </Form.Item>
                        }



                        {giftType >= 4 &&
                            <Form.Item label={translate("CurrencyParameterValue")} name="ParameterValue" rules={[{ required: true, message: translate("CurrencyParameterValueValidationMessage") }]}>
                                <Input
                                    addonBefore={prefixSelector}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        }
                    </div>
                    : <div>

                    </div>
                }



            </Form>
        </Modal>
    );
};

interface IUpdateRuleProps extends ICreateRuleProps {
    id: number
}


const UpdateRule = ({ visible, onCancel, onCreate, currencies, giftCategories, organizations, ruleTypes, id, publicOfficialRelationshipTypes, translate }: IUpdateRuleProps) => {
    const [giftType, setGiftType] = useState(0);
    const [isCOI, setIsCOI] = useState(false);
    const [form] = Form.useForm();
    const [data, setData] = useState({});

    useEffect(() => {
        if (id) {
            RuleService.GetRule(id).then(rule => {
                setData(rule)
                setGiftType(rule.RuleType);
                form.resetFields();
            });
        }

    }, [id])

    const prefixSelector = (
        <Form.Item name="ParameterCurrency" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                {
                    currencies.map((currency) =>
                        <Select.Option key={currency.ID} value={currency.ID}>{currency.Name}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
    );


    return (
        <Modal
            visible={visible}
            title={translate("UpdateARule")}
            okText={translate("Update")}
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    }).catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form layout="vertical" form={form} initialValues={data}>
                <FormItem label={translate("RuleName")} name="Name" rules={[{ required: true, message: translate("RuleNameValidationMessage") }]}>
                    <Input />
                </FormItem>
                <FormItem name="RuleID" hidden></FormItem>

                {(!isCOI) ?
                    <div>
                        <Form.Item
                            name="RegionIDs"
                            label={translate("AppliesToRegions")}
                            rules={[
                                {
                                    required: true,
                                    message: translate("RegionValidationMessage"),
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select mode="multiple" placeholder={translate("RegionMultiSelect")}>
                                {
                                    organizations.map((org) =>
                                        <Select.Option key={org.ID} value={org.ID}>{org.Name}</Select.Option>
                                    )
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="GiftCategories"
                            label={translate("GiftCategories")}
                            rules={[
                                {
                                    required: true,
                                    message: translate("GiftCategoriesValidationMessage"),
                                    type: 'array',
                                },
                            ]}
                        >
                            <Select mode="multiple" placeholder={translate("GiftCategoriesPlaceHolder")}>
                                {
                                    giftCategories.map((category) =>
                                        <Select.Option key={category.ID} value={category.ID}>{category.Name}</Select.Option>
                                    )
                                }

                            </Select>
                        </Form.Item>
                        <FormItem label={translate("Given/Received")} name="Given" valuePropName="checked">
                            <Switch checkedChildren="Given" unCheckedChildren="Received" />
                        </FormItem>

                        <Form.Item label={translate("RuleType")} name="RuleType" rules={[{ required: true, message: translate("RuleTypeValidationMessage") }]}>
                            <Select onChange={(e) => setGiftType(e as number)}>
                                {ruleTypes.map((rule) => <Select.Option key={rule.ID} value={rule.ID}>{translate(rule.Name)}</Select.Option>)}
                            </Select>
                        </Form.Item>


                        {giftType === 1 &&
                            <Form.Item label={translate("RestrictedCurrency")} name="ParameterValue" rules={[{ required: true, message: translate("RestrictedCurrencyValidationMessage") }]}>
                                <Select >
                                    {
                                        currencies.map((currency) =>
                                            <Select.Option key={currency.ID} value={String(currency.ID)}>{currency.Name}</Select.Option>
                                        )
                                    }

                                </Select>
                            </Form.Item>
                        }

                        {giftType === 3 &&
                            <Form.Item label={translate("RestrictedPublicOfficalType")} name="ParameterValue" rules={[{ required: true, message: translate("RestrictedPublicOfficalValidationMessage") }]}>
                                <Select >
                                    {
                                        publicOfficialRelationshipTypes.map((official) =>
                                            <Select.Option key={official.ID} value={String(official.ID)}>{official.Name}</Select.Option>
                                        )
                                    }

                                </Select>
                            </Form.Item>
                        }



                        {giftType >= 4 &&
                            <Form.Item label={translate("parameterValue")} name="ParameterValue" rules={[{ required: true, message: translate("CurrencyParameterValueValidationMessage") }]}>
                                <Input
                                    addonBefore={prefixSelector}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        }
                    </div>
                    : <div>

                    </div>
                }



            </Form>
        </Modal>
    );
};

interface IRulesProps {
    translate(text: string): string
}

interface IRulesState {
    loading: boolean,
    rules: Rule[],
    employees: Employee[],
    employeeSearchQuery: string,
    RuleID: number,
    Employee: null | Employee,
    OrganizationID: number | null,
    Organization: Organization | null,
    showAddModal: boolean,
    showEditModal: boolean,
    currencies: GenericDTO[],
    giftCategories: GenericDTO[],
    organizations: OrganizationDTO[],
    ruleTypes: GenericDTO[],
    publicOfficialRelationshipTypes: GenericDTO[],
}



export default class Rules extends Component<IRulesProps, IRulesState> {
    constructor(props: IRulesProps) {
        super(props)
        this.state = {
            loading: false,
            rules: [],
            employees: [],
            employeeSearchQuery: '',
            RuleID: 0,
            Employee: null,
            OrganizationID: null,
            Organization: null,
            showAddModal: false,
            showEditModal: false,
            currencies: [],
            giftCategories: [],
            organizations: [],
            ruleTypes: [],
            publicOfficialRelationshipTypes: [],

        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true }, async () => {
            const rules = await RuleService.GetAllRule();
            const dropDowns: RuleDropdowns = await DropdownService.getRuleDropdowns();
            const ruleTypes = dropDowns.ruleTypes.filter(x => x.ID != 2).filter(x => x.ID != 0)
            this.setState({
                rules, loading: false,
                currencies: dropDowns.currencies, giftCategories: dropDowns.giftCategories,
                organizations: dropDowns.organizations, ruleTypes,
                publicOfficialRelationshipTypes: dropDowns.publicOfficialRelationshipTypes
            })
        })
    }

    editDetail = (id: number) => {
        this.setState({ RuleID: id, showEditModal: true });

    }

    displayRegions = (regionNames: string[]) => {

        return regionNames.join(", ")
    }
    handleCreate = async (values: Rule) => {
        const newRule = await RuleService.AddNewRule(values);
        if (newRule && newRule.RuleID > 0) {
            const rules = [...this.state.rules, newRule];
            this.setState({ rules, RuleID: 0 });
        }
        this.setState({ showAddModal: false })
    }

    handleUpdate = async (values: Rule) => {
        const updatedRule = await RuleService.UpdateRule(values);
        if (updatedRule && updatedRule.RuleID > 0) {
            let rules = [...this.state.rules];
            const ruleToUpdate = rules.findIndex(x => x.RuleID == updatedRule.RuleID);
            rules[ruleToUpdate] = updatedRule;
            this.setState({ rules, RuleID: 0 });
        }

        this.setState({ showEditModal: false })
    }

    translate = (text: string) => {
        return this.props.translate(text)
    }


    tableColumn = () => {
        return [
            {
                title: this.translate("ID"),
                dataIndex: 'RuleID',
                key: 'RuleID',
                render: (id: number) => {
                    return <a onClick={() => { this.editDetail(id) }} className="primary">{id}</a>
                }
            },
            {
                title: this.translate('Name'),
                dataIndex: 'Name',
                key: 'Name',
            },
            {
                title: this.translate("Regions"),
                dataIndex: 'RegionNames',
                render: (regions: string[]) => {
                    return <span>{this.displayRegions(regions)}</span>
                }
            },
            {
                title: this.translate('RemoveRule'),
                dataIndex: 'RuleID',
                render: (id: number) => {
                    return <a onClick={() => { console.log("Delete", id) }} className="dead-center code">&#x2715;</a>
                }
            },

        ]
    }

    render() {
        const { loading, rules, RuleID, showAddModal, showEditModal, currencies,
            giftCategories, organizations, ruleTypes, publicOfficialRelationshipTypes } = this.state;
        return (
            <div>
                <Row>
                    <Col>
                        <Button className="btn-success" style={{ marginRight: 10 }} onClick={() => { this.setState({ showAddModal: true }) }}>
                            {this.translate("AddNewRules")}
                        </Button>

                    </Col>
                </Row>
                <br />
                {
                    loading ?
                        <Spin />
                        :
                        <div style={{ padding: 20, backgroundColor: '#ffffff', height: '100%', width: '100%' }}>
                            <Row>
                                <Table
                                    dataSource={rules}
                                    columns={this.tableColumn() as ColumnsType<Rule>}
                                    pagination={{ pageSize: 50 }}
                                    style={{ width: '100%' }} />


                            </Row>
                        </div>
                }

                <CreateRule
                    visible={showAddModal}
                    onCancel={() => this.setState({ showAddModal: false })}
                    onCreate={this.handleCreate}
                    currencies={currencies}
                    giftCategories={giftCategories}
                    organizations={organizations}
                    ruleTypes={ruleTypes}
                    publicOfficialRelationshipTypes={publicOfficialRelationshipTypes}
                    translate={this.translate}
                />

                <UpdateRule
                    visible={showEditModal}
                    onCancel={() => this.setState({ showEditModal: false })}
                    onCreate={this.handleUpdate}
                    currencies={currencies}
                    giftCategories={giftCategories}
                    organizations={organizations}
                    id={RuleID}
                    ruleTypes={ruleTypes}
                    publicOfficialRelationshipTypes={publicOfficialRelationshipTypes}
                    translate={this.translate}

                />


            </div>
        )
    }
}

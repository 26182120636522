import React, { Component } from 'react'
import { Button, Modal, Row, Col, Form, Input, Select, message } from 'antd';
import EmployeeService from '../services/EmployeeService';

interface UsersTabProps {
    translate(text: string): string
}

interface UsersTabState {
    loading: boolean,
    showAddModal: boolean,
    bulkUploadFile: null | Blob,
    showAddUserModal: boolean,

    userRole: number | null,
    userName: string,
    userEmail: string
}


class UsersTab extends Component<UsersTabProps, UsersTabState> {
    constructor(props: UsersTabProps) {
        super(props)
        this.state = {
            loading: false,
            showAddModal: false,
            bulkUploadFile: null,
            showAddUserModal: false,

            userRole: null,
            userName: '',
            userEmail: ''
        }
    }

    handleBulkUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.target && e.target.files && e.target.files?.length > 0) {
            this.setState({ bulkUploadFile: e.target.files[0] })

        }
    }

    handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ userName: e.target.value })
    }

    handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ userEmail: e.target.value })
    }

    handleRoleChange = (e: number) => {
        this.setState({ userRole: e })
    }

    handleAddUser = async () => {
        if (!this.state.userEmail) {
            message.error(this.translate("userEmailEmpty"), 10)
            return
        } else if (!this.state.userName) {
            message.error(this.translate("userNameEmpty"), 10)
            return
        } else if (this.state.userRole === 0 || this.state.userRole === null) {
            message.error(this.translate("userRoleNotSelected"), 10)
            return
        }
        const resp = await EmployeeService.addUser(this.state.userName, this.state.userEmail, this.state.userRole);
        if (resp) {
            this.setState({ showAddUserModal: false, userEmail: '', userName: '', userRole: null })
            message.success(this.translate(resp))

        }
        else {
            message.error(this.translate("anErrorOccured"), 10)

        }

    }


    handleBulkUploadValidation = async () => {
        //Add additional valication here

        // form object add additional perams here
        const file = this.state.bulkUploadFile


        if (file) {
            const resp = await EmployeeService.bulkAddUser(file);

        }
    }
    translate = (text: string) => {
        return this.props.translate(text)
    }

    render() {
        const { loading, showAddModal, bulkUploadFile, showAddUserModal, userName, userEmail, userRole } = this.state;

        return (
            <div>
                <Row>
                    <Button className="btn-success" style={{ marginRight: 10 }
                    } onClick={() => { this.setState({ showAddModal: true }) }}>
                        {this.translate("BulkAddUsers")}
                    </Button>

                    < Button className="btn-success" style={{ marginRight: 10 }} onClick={() => { this.setState({ showAddUserModal: true }) }}>
                        {this.translate("AddUsers")}
                    </Button>
                </Row>
                < Modal title={this.translate("BulkAddUsers")} visible={showAddModal} onOk={this.handleBulkUploadValidation}
                    okText="Add"
                    onCancel={() => this.setState({ showAddModal: false })}>
                    <Form>

                        <label htmlFor="BulkUploadFile" > {this.translate("UploadCSV")} </label>
                        < Input type="file" id="BulkUploadFile" name="BulkUploadFile" onChange={this.handleBulkUploadFile} >
                        </Input>
                    </Form>

                </Modal>


                < Modal title={this.translate("AddUser")} visible={showAddUserModal} onOk={this.handleAddUser}
                    okText="Add"
                    onCancel={() => this.setState({ showAddUserModal: false })}>
                    <Form>
                        <Form.Item label={this.translate("UserName")}>
                            <Input type="text" value={userName} onChange={this.handleUserNameChange} />

                        </Form.Item>
                        < Form.Item label={this.translate("Email")} >
                            <Input type="text" value={userEmail} onChange={this.handleEmailChange} />

                        </Form.Item>

                        < Form.Item label={this.translate("Role")} >
                            <Select value={userRole ?? 1} onChange={this.handleRoleChange} >
                                <Select.Option value={1}> {this.translate("Employee")} </Select.Option>
                                < Select.Option value={2} > {this.translate("EthicsOfficer")} </Select.Option>
                                < Select.Option value={3} > {this.translate("Admin")} </Select.Option>
                            </Select>

                        </Form.Item>

                    </Form>

                </Modal>


            </div>)
    }


}

export default UsersTab;

import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import api from './Api';

const CommentService = {
    NAME: "COMMENT SERVICE",
    AddGiftComment: async function (giftId: number, comment: string) {
        try {
            const response = await api.post(`${ApiConfig.baseURL}/api/comments/GiftComment`,
                JSON.stringify({ GiftID: giftId, comment: comment }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    AddCOIComment: async function (coiId: number, comment: string) {
        try {
            const response = await api.post(`${ApiConfig.baseURL}/api/comments/COIComment`,
                JSON.stringify({ GiftID: coiId, comment: comment }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    },
    AddOrgCOIComment: async function (coiId: number, comment: string) {
        try {
            const response = await api.post(`${ApiConfig.baseURL}/api/comments/OrgCOIComment`,
                JSON.stringify({ GiftID: coiId, comment: comment }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (e) {
            console.log(this.NAME, e);
        }
    }
};

export default CommentService;
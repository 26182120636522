import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken, AdalConfig } from 'react-adal';
import ApiConfig from './ApiConfig';

const defaultURL = 'https://graph.microsoft.com'



export const adalConfig: AdalConfig = {
  tenant: 'common',
  clientId: 'be1f1842-0466-4a8a-8587-ed3de9366123',
  redirectUri: `${ApiConfig.baseURL}`,
  endpoints: {
    api: 'https://graph.microsoft.com',
  },
  cacheLocation: 'localStorage',
};

export const AuthContext = new AuthenticationContext(adalConfig);

export const AdalApiFetch = (url: string, options: any = {}) =>
  adalFetch(AuthContext, adalConfig && adalConfig.endpoints ? adalConfig.endpoints.api : defaultURL, fetch, url, options);

export const GetToken = async () => {

  try {
    return await adalGetToken(AuthContext, adalConfig.clientId);
  } catch (error: any) {
    console.log("Token error", error);
    if (
      error &&
      error.msg &&
      (error.msg === 'login_required' ||
        error.msg === 'login required' ||
        error.msg === 'interaction_required' ||
        error.msg === 'Token Renewal Failed')
    ) {
      AuthContext.login();
    }
  }

}

export const WithAdalLoginApi = withAdalLogin(AuthContext, adalConfig && adalConfig.endpoints ? adalConfig.endpoints.api : defaultURL);

import React, { Component } from 'react'
import {
    Table, Spin, Tag, Input, Button, Row, Col, Tabs, Select, Form, Menu, Dropdown,
    Modal, DatePicker
} from 'antd';

import GiftService from '../services/GiftService';
import ConflictOfInterestService from '../services/ConflictOfInterestService';
import OrgCOIService from '../services/OrgCOIService';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import moment from 'moment';
import EmployeeService from '../services/EmployeeService';
import { DownOutlined } from '@ant-design/icons';
import Filters from './Filters';
import SearchTable from './Table';
import BaseService from '../services/BaseService';
import SearchService from '../services/SearchService';
import DropdownService from '../services/DropdownService';
import { App } from '../models/Enums';
import { Link } from 'react-router-dom';
import { EmployeeWithPermissions, FilterDTO, sorter, pagination, GiftFilterDTO, ConflictFilterDTO, OrgConflictFilterDTO, Employee, ActionReturnTypeWithType, ActionsDTO, Contact, ActionReturnType, SearchReturnType } from '../models/Core';
import { History } from 'history';

const { TabPane } = Tabs;
const { Search } = Input;


const nestedRow = (data: ActionReturnTypeWithType, translate: (text: string) => string, moneyFormat: (amount: number, currency: string) => string) => {

    let columnKeys: any;

    switch (data.Type) {
        case "Gifts":
            columnKeys = GiftColumns(translate, moneyFormat, false);
            break;
        case "ConflictsOfInterest":
            columnKeys = CoiColumns(translate, false);
            break;
        case "Org COI":
            columnKeys = OrgCOIColumns(translate, false);
            break;

        default:
            break;
    }

    return <Table key={data.Type} columns={columnKeys} dataSource={data.Data} pagination={false} />;


}


const GiftColumns = (translate: (text: string) => string, moneyFormat: (amount: number, currency: string) => string, serverSort: boolean) => [
    {
        title: translate('ID'),
        dataIndex: 'ID',
        key: 'ID',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.ID - b.ID,
        render: (id: number) => {
            return <Link to={`/actions/${id}`} className="primary">{id}</Link>
        }
    },
    {
        title: translate('Name'),
        dataIndex: 'Name',
        key: 'Name',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Name.localeCompare(b.Name),
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: translate('Description'),
        dataIndex: 'Description',
        key: 'Description',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Description.length - b.Description.length,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: translate('StatusName'),
        dataIndex: 'StatusName',
        key: 'StatusName',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.StatusName.localeCompare(b.StatusName),
        sortDirections: ['ascend', 'descend'],
        render: (text: string) => {
            switch (text) {
                case 'Submitted':
                    return <Tag color="blue">{translate(text)}</Tag>;
                case 'Approved':
                    return <Tag color="green">{translate(text)}</Tag>;
                case 'Rejected':
                    return <Tag color="red">{translate(text)}</Tag>;
                case 'InReview':
                    return <Tag color="orange">{translate(text)}</Tag>;
                case 'UnderReview':
                    return <Tag color="#5260ff">{translate(text)}</Tag>;

                default:
                    return <Tag>{translate(text)}</Tag>;
            }
        }
    },
    {
        title: translate('DateRecieved'),
        dataIndex: 'DateRecieved',
        key: 'DateRecieved',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => new Date(b.DateRecieved).getTime() - new Date(a.DateRecieved).getTime(),
        render: (DateRecieved: Date) => {
            return <span>{moment(DateRecieved).local().format("MMM Do, YYYY")}</span>
        }
    },

    {
        title: translate('CreatedOn'),
        dataIndex: 'CreatedOn',
        key: 'CreatedOn',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime(),
        render: (CreatedOn: Date) => {
            return <span>{moment(CreatedOn).local().format("MMM Do, YYYY")}</span>
        }
    },

    {
        title: translate('Employee'),
        dataIndex: ['Employee'],
        key: 'Employee',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.FullName && b.Employee.FullName && b.Employee.FullName.localeCompare(a.Employee.FullName),
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.FullName} on behalf of ` : ''}{Employee ? Employee.FullName : ''}</span>
        }
    },
    {
        title: translate('EmployeeEmail'),
        dataIndex: ['Employee'],
        key: 'EmployeeEmail',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.EmailAddress && b.Employee.EmailAddress && b.Employee.EmailAddress.localeCompare(a.Employee.EmailAddress),
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.EmailAddress} on behalf of ` : ''}{Employee ? Employee.EmailAddress : ''}</span>
        }
    },
    {
        title: translate('Region'),
        dataIndex: 'Region',
        key: 'Region',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Region && b.Region && a.Region.localeCompare(b.Region),
    },
    {
        title: translate('Given/Received'),
        dataIndex: 'Given',
        key: 'Given/Received',
        render: (Given: boolean) => {
            return <span>{Given ? "Given" : "Received"}</span>
        }
    },
    {
        title: translate('PublicOfficial'),
        dataIndex: 'PublicOfficials',
        key: 'PublicOfficial',
        render: (PublicOfficials: Contact[]) => {
            return <span>{PublicOfficials && PublicOfficials.length > 0 ? PublicOfficials[0].FullName : ""}</span>
        }
    },
    {
        title: translate('IsPublicOffical'),
        dataIndex: 'IsPublicOffical',
        key: 'IsPublicOffical',
        render: (PublicOfficials: boolean) => {
            return <span>{translate(PublicOfficials ? "Yes": "No")}</span>
        }
    },
    {
        title: translate('Amount'),
        dataIndex: 'OriginalValue',
        key: 'Amount',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.OriginalValue - b.OriginalValue,
        render: (OriginalValue: number, row: ActionsDTO) => {
            return <span>{moneyFormat(OriginalValue, row.Currency)}</span>
        }
    },
    {
        title: translate('ConvertedValue'),
        dataIndex: 'ConvertedValue',
        key: 'ConvertedValue',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.ConvertedValue - b.ConvertedValue,
        render: (ConvertedValue: number) => {
            return <span>{moneyFormat(ConvertedValue, "CAD")}</span>
        }
    }
];
const CoiColumns = (translate: (text: string) => string, serverSort: boolean) => [
    {
        title: translate('ID'),
        dataIndex: 'ID',
        key: 'ID',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.ID - b.ID,
        sortDirections: ['ascend', 'descend'],
        render: (id: number) => {
            return <Link to={`/COIActions/${id}`} className="primary">{id}</Link>
        }
    },

    {
        title: translate('Description'),
        dataIndex: 'Description',
        key: 'Description',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Description.length - b.Description.length,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: translate('StatusName'),
        dataIndex: 'StatusName',
        key: 'StatusName',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.StatusName.localeCompare(b.StatusName),
        sortDirections: ['ascend', 'descend'],
        render: (text: string) => {
            switch (text) {
                case 'Submitted':
                    return <Tag color="blue">{translate(text)}</Tag>;
                case 'Approved':
                    return <Tag color="green">{translate(text)}</Tag>;
                case 'Rejected':
                    return <Tag color="red">{translate(text)}</Tag>;
                case 'InReview':
                    return <Tag color="orange">{translate(text)}</Tag>;
                case 'UnderReview':
                    return <Tag color="#5260ff">{translate(text)}</Tag>;
                default:
                    return <Tag>{translate(text)}</Tag>;
            }
        }
    },
    {
        title: translate('CreatedOn'),
        dataIndex: 'DateRecieved',
        key: 'DateRecieved',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => new Date(b.DateRecieved).getTime() - new Date(a.DateRecieved).getTime(),
        sortDirections: ['ascend', 'descend'],
        render: (DateRecieved: Date) => {
            return <span>{moment(DateRecieved).local().format("MMM Do, YYYY")}</span>
        }
    },

    {
        title: translate('Employee'),
        dataIndex: ['Employee'],
        key: 'Employee',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.FullName && b.Employee.FullName && b.Employee.FullName.localeCompare(a.Employee.FullName),
        sortDirections: ['ascend', 'descend'],
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.FullName} on behalf of ` : ''}{Employee ? Employee.FullName : ''}</span>
        }
    },
    {
        title: translate('EmployeeEmail'),
        dataIndex: ['Employee'],
        key: 'EmployeeEmail',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.EmailAddress && b.Employee.EmailAddress && b.Employee.EmailAddress.localeCompare(a.Employee.EmailAddress),
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.EmailAddress} on behalf of ` : ''}{Employee ? Employee.EmailAddress : ''}</span>
        }
    },
    {
        title: translate('Region'),
        dataIndex: 'Region',
        key: 'Region',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Region && b.Region && a.Region.localeCompare(b.Region),
        sortDirections: ['ascend', 'descend'],
    },
   


];
const OrgCOIColumns = (translate: (text: string) => string, serverSort: boolean) => [
    {
        title: translate('ID'),
        dataIndex: 'ID',
        key: 'ID',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.ID - b.ID,
        sortDirections: ['ascend', 'descend'],
        render: (id: number) => {
            return <Link to={`/Organisational/${id}`} className="primary">{id}</Link>
        }
    },
    {
        title: translate('Project'),
        dataIndex: 'ProjectName',
        key: 'ProjectName',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.ProjectName.localeCompare(b.ProjectName),
        sortDirections: ['ascend', 'descend']
    },
    {
        title: translate('Description'),
        dataIndex: 'Description',
        key: 'Description',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Description.length - b.Description.length,
        sortDirections: ['ascend', 'descend'],
    },
    {
        title: translate('StatusName'),
        dataIndex: 'StatusName',
        key: 'StatusName',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.StatusName.localeCompare(b.StatusName),
        sortDirections: ['ascend', 'descend'],
        render: (text: string) => {
            switch (text) {
                case 'Submitted':
                    return <Tag color="blue">{translate(text)}</Tag>;
                case 'Approved':
                    return <Tag color="green">{translate(text)}</Tag>;
                case 'Rejected':
                    return <Tag color="red">{translate(text)}</Tag>;
                case 'InReview':
                    return <Tag color="orange">{translate(`org_${text}`)}</Tag>;
                case 'UnderReview':
                    return <Tag color="#5260ff">{translate(`org_${text}`)}</Tag>;
                case 'Monitor':
                    return <Tag color="#ff9c60">{translate(text)}</Tag>;
                default:
                    return <Tag>{translate(text)}</Tag>;
            }
        }
    }, {
        title: translate('CreatedOn'),
        dataIndex: 'DateRecieved',
        key: 'DateRecieved',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => new Date(b.DateRecieved).getTime() - new Date(a.DateRecieved).getTime(),
        sortDirections: ['ascend', 'descend'],
        render: (DateRecieved: Date) => {
            return <span>{moment(DateRecieved).local().format("MMM Do, YYYY")}</span>
        }
    },

    {
        title: translate('Employee'),
        dataIndex: ['Employee'],
        key: 'Employee',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.FullName && b.Employee.FullName && b.Employee.FullName.localeCompare(a.Employee.FullName),
        sortDirections: ['ascend', 'descend'],
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.FullName} on behalf of ` : ''}{Employee ? Employee.FullName : ''}</span>
        }
    },
    {
        title: translate('EmployeeEmail'),
        dataIndex: ['Employee'],
        key: 'EmployeeEmail',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Employee && b.Employee && a.Employee.EmailAddress && b.Employee.EmailAddress && b.Employee.EmailAddress.localeCompare(a.Employee.EmailAddress),
        render: (Employee: Employee, row: ActionsDTO) => {

            return <span> {row.OnBehalfOf ? `${row.OnBehalfOf.EmailAddress} on behalf of ` : ''}{Employee ? Employee.EmailAddress : ''}</span>
        }
    },

    {
        title: translate('Region'),
        dataIndex: 'Region',
        key: 'Region',
        sorter: serverSort ? true : (a: ActionsDTO, b: ActionsDTO) => a.Region && b.Region && a.Region.localeCompare(b.Region),
        sortDirections: ['ascend', 'descend'],
    },


];




interface IActionsProps {
    languageCode: string,
    history: History,
    user: EmployeeWithPermissions,
    currentTab: string,
    textSearchQuery: string,
    sorter: sorter,
    pagination: pagination,
    userFilter: FilterDTO,
    CanViewOrgCOI: boolean,
    updateSearchFilter(filter: FilterDTO): void,
    setTextSearchQuery(text: string): void,

    updateCurrentTab(tab: string): void,
    updatePagination(pag: pagination): void,
    updateSorter(sort: sorter): void
}

interface IActionsState {
    query: string,
    queryCOI: string,
    filteredGifts: [],
    inReview: [],
    conflictOfInterest: [],
    gifts: [],
    loading: boolean,
    selectedGift: null,
    languageCode: string,
    langData: any,
    regionFilter: [],
    statusFilter: [],
    nameFilter: [],
    filterVisible: boolean,
    initFilterData: {},

    searchLoading: boolean,
    dropDowns: {},
    dropDownsConflict: {},
    dropDownsOrgConflict: {},
    visibleGiftTableColumn: string[],
    visibleCOITableColumn: string[],

    visibleOrgCOITableColumn: string[],
    textSearchTable: SearchReturnType,
}

export default class Actions extends Component<IActionsProps, IActionsState> {
    constructor(props: IActionsProps) {
        super(props)
        this.state = {
            query: '',
            queryCOI: '',
            filteredGifts: [],
            inReview: [],
            conflictOfInterest: [],
            gifts: [],
            loading: false,
            selectedGift: null,
            languageCode: props.languageCode,
            langData: {},
            regionFilter: [],
            statusFilter: [],
            nameFilter: [],
            filterVisible: false,
            initFilterData: {},

            searchLoading: false,
            dropDowns: {},
            dropDownsConflict: {},
            dropDownsOrgConflict: {},
            visibleGiftTableColumn: ['ID', 'Name', 'Description', 'StatusName', 'DateRecieved', 'Employee','EmployeeEmail',
                'Region', 'Given/Received', 'Amount', 'ConvertedValue','IsPublicOffical'],
            visibleCOITableColumn: ['ID', 'Description', 'StatusName', 'DateRecieved', 'Employee', 'EmployeeEmail',
                'Region'],

            visibleOrgCOITableColumn: ['ID', 'ProjectName', 'Description', 'StatusName', 'DateRecieved', 'Employee', 'EmployeeEmail',
                'Region'
            ],
            textSearchTable: {
                Data: [],
                Count: 0
            },

        }
    }

    componentDidMount() {
        this.getLanguageData();

        if (this.props.textSearchQuery.length > 0 && this.props.currentTab === "6") {
            this.searchQuery(this.props.textSearchQuery);
        }
    }
    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label] as string
        }
        return label;
    }



    goToGiftDetail = (id: number) => { this.props.history.push(`/actions/${id}`) }
    goToCOIDetail = (id: number) => { this.props.history.push(`/COIActions/${id}`) }
    goToOrgCOIDetail = (id: number) => { this.props.history.push(`/Organisational/${id}`) }



    moneyFormat = (amount: number, currency: string) => {
        return `${currency} : ${currencyFormatter.format(amount, { code: currency })}`;
    }

    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getActionResources();
            this.setState({ langData });
            this.getDropDowns();

        })

    }

    getDropDowns = async () => {
        this.setState({ loading: true }, async () => {
            const dropDowns = await DropdownService.getRequestDropdowns();
            const dropDownsConflict = await DropdownService.getConflictDropdowns();
            const dropDownsOrgConflict = await DropdownService.getOrgConflictDropdowns();
            this.setState({ dropDowns, dropDownsConflict, dropDownsOrgConflict, loading: false });

        })
    }



    getSearchTableColumns = () => {
        let table = [{
            title: this.translate('Type'),
            dataIndex: "Type",
            key: "Type",
            render: (x: string) => <span>{this.translate(x)}</span>
        }, {
            title: this.translate('Count'),
            dataIndex: "Count",
            key: "Count"
        }];

        return table
    }

    getOrgCoiColumns = () => {

        let table = OrgCOIColumns(this.translate, true);

        table = table.filter(x => this.state.visibleOrgCOITableColumn.includes(x.key))
        return table;
    }


    setFilter = (data: FilterDTO) => {
        this.props.updateSearchFilter(data);
        this.setState({ filterVisible: false });

    };



    resetFilters = (key: string) => {
        this.props.updateCurrentTab(key);
        this.props.updatePagination({
            current: 1,
            pageSize: 10,
            total: 0
        });
        this.props.updateSorter({
            field: '',
            order: ''
        });

    }

    // handleTableChange = (pagination: pagination, _, sorter: sorter) => {
    //     this.props.updatePagination(pagination);
    //     this.props.updateSorter(sorter);

    // };


    allGiftTableColumns = () => {
        return ['ID', 'Name', 'Description', 'StatusName', 'DateRecieved', 'Employee',
            'Region', 'Given/Received', 'Amount', 'ConvertedValue', 'PublicOfficial', 'CreatedOn','IsPublicOffical'];
    }

    setVisibleGiftColumns = (newVisibleColumns: string[]) => {
        this.setState({ visibleGiftTableColumn: newVisibleColumns });
    }

    allCOITableColumns = () => {
        return ['ID', 'Description', 'StatusName', 'CreatedOn', 'Employee',
            'Region'];
    }

    allOrgCOITableColumns = () => {
        return ['ID', 'ProjectName', 'Description', 'StatusName', 'CreatedOn', 'Employee',
            'Region'
        ];
    }

    setVisibleCOIColumns = (newVisibleColumns: string[]) => {
        this.setState({ visibleCOITableColumn: newVisibleColumns });
    }
    searchQuery = async (query: string) => {
        if (query) {
            this.props.setTextSearchQuery(query)
            this.setState({ searchLoading: true })
            this.resetFilters("6");

            const data: SearchReturnType = await SearchService.getActionsSearch(query);
            this.setState({ textSearchTable: data, searchLoading: false })
        }

    }

    setVisibleOrgCOIColumns = (newVisibleColumns: string[]) => {
        this.setState({ visibleOrgCOITableColumn: newVisibleColumns });
    }

    getCoiColumns = () => {

        let table = CoiColumns(this.translate, true);

        table = table.filter(x => this.state.visibleCOITableColumn.includes(x.key))
        return table;
    }


    getTableColumns = () => {
        let table = GiftColumns(this.translate, this.moneyFormat, true);

        // let sorter = this.props.sorter;

        // let field = table.find(x => sorter.field === x.dataIndex);
        // if (field) {
        //     field["defaultSortOrder"] = sorter.order;
        // }

        table = table.filter(x => this.state.visibleGiftTableColumn.includes(x.key))


        return table;
    }

    render() {
        const { loading, filteredGifts, query, queryCOI, inReview, visibleOrgCOITableColumn,
            filterVisible, searchLoading, dropDownsOrgConflict, dropDowns, dropDownsConflict, visibleGiftTableColumn, visibleCOITableColumn, textSearchTable } = this.state;


        if (loading) {
            return (<Spin />)
        }

        return (
            <div>
                <h2>{this.translate("GEH")}</h2>
                <Row justify="end">
                    <Col>
                        <Search placeholder={this.translate("TextSearch")} defaultValue={this.props.textSearchQuery} onSearch={(searchValue) => this.searchQuery(searchValue)} />

                    </Col>
                </Row>

                <div><Tabs defaultActiveKey="1" activeKey={this.props.currentTab} onTabClick={(key, _) => this.resetFilters(key)}>
                    {
                        (this.props.user.IsAdmin || this.props.user.IsEthicOfficer) && <>
                            <TabPane tab={this.translate("AllGifts")} key="1">
                                <SearchTable
                                    columns={this.getTableColumns()}
                                    translate={this.translate}
                                    pagination={this.props.pagination}
                                    userFilter={this.props.userFilter}
                                    dropDowns={dropDowns}
                                    updateSearchFilter={this.props.updateSearchFilter}
                                    sorter={this.props.sorter}
                                    getActions={(filter: GiftFilterDTO, pagination, sorter, query) => GiftService.getActions(filter, pagination, sorter, query, false)}
                                    updatePagination={this.props.updatePagination}
                                    updateSorter={this.props.updateSorter}
                                    showStatus={true}
                                    tableColumns={this.allGiftTableColumns()}
                                    visibleColumns={visibleGiftTableColumn}
                                    setNewVisibleColumns={(array) => this.setVisibleGiftColumns(array)}
                                    excelFileService={(filter: GiftFilterDTO, pagination, sorter, query, selectedRowKeys) => GiftService.downloadActionsGift(filter, pagination, sorter, query, false, selectedRowKeys, visibleGiftTableColumn)}
                                    filterApp={App.Gifts}
                                    coi={false}
                                />

                            </TabPane>
                            <TabPane tab={this.translate("ConflictsOfInterest")} key="2">


                                <SearchTable
                                    columns={this.getCoiColumns()}
                                    translate={this.translate}
                                    pagination={this.props.pagination}
                                    userFilter={this.props.userFilter}
                                    dropDowns={dropDownsConflict}
                                    updateSearchFilter={this.props.updateSearchFilter}
                                    sorter={this.props.sorter}
                                    getActions={(filter: ConflictFilterDTO, pagination, sorter, query) => ConflictOfInterestService.getActions(filter, pagination, sorter, query)}
                                    updatePagination={this.props.updatePagination}
                                    updateSorter={this.props.updateSorter}
                                    showStatus={true}
                                    coi={true}
                                    tableColumns={this.allCOITableColumns()}
                                    visibleColumns={visibleCOITableColumn}
                                    setNewVisibleColumns={(array) => this.setVisibleCOIColumns(array)}
                                    excelFileService={(filter: ConflictFilterDTO, pagination, sorter, query, selectedRowKeys) => ConflictOfInterestService.downloadActions(filter, pagination, sorter, query, selectedRowKeys, visibleCOITableColumn)}
                                    filterApp={App.ConflictOfInterest}

                                />
                            </TabPane>
                            <TabPane tab={this.translate("InReview")} key="3">
                                <SearchTable
                                    columns={this.getTableColumns()}
                                    translate={this.translate}
                                    pagination={this.props.pagination}
                                    userFilter={this.props.userFilter}
                                    dropDowns={dropDowns}
                                    updateSearchFilter={this.props.updateSearchFilter}
                                    sorter={this.props.sorter}
                                    getActions={(filter: GiftFilterDTO, pagination, sorter, query) => GiftService.getActions(filter, pagination, sorter, query, true)}
                                    updatePagination={this.props.updatePagination}
                                    updateSorter={this.props.updateSorter}
                                    showStatus={false}
                                    tableColumns={this.allGiftTableColumns()}
                                    visibleColumns={visibleGiftTableColumn}
                                    setNewVisibleColumns={(array) => this.setVisibleGiftColumns(array)}
                                    excelFileService={(filter: GiftFilterDTO, pagination, sorter, query, selectedRowKeys) => GiftService.downloadActionsGift(filter, pagination, sorter, query, true, selectedRowKeys, visibleGiftTableColumn)}
                                    filterApp={App.Gifts}
                                    coi={false}

                                />
                            </TabPane>


                        </>

                    }


                    {
                        this.props.CanViewOrgCOI &&
                        <TabPane tab={this.translate("Org COI")} key="4">
                            <SearchTable
                                columns={this.getOrgCoiColumns()}
                                translate={this.translate}
                                pagination={this.props.pagination}
                                userFilter={this.props.userFilter}
                                dropDowns={dropDownsOrgConflict}
                                updateSearchFilter={this.props.updateSearchFilter}
                                sorter={this.props.sorter}
                                getActions={(filter: OrgConflictFilterDTO, pagination, sorter, query) => OrgCOIService.getActionsOrgConflict(filter, pagination, sorter, query)}
                                updatePagination={this.props.updatePagination}
                                updateSorter={this.props.updateSorter}
                                showStatus={false}
                                tableColumns={this.allOrgCOITableColumns()}
                                visibleColumns={visibleOrgCOITableColumn}
                                setNewVisibleColumns={(array) => this.setVisibleOrgCOIColumns(array)}
                                excelFileService={(filter: OrgConflictFilterDTO, pagination, sorter, query, selectedRowKeys) => OrgCOIService.downloadActionsOrgConflict(filter, pagination, sorter, query, selectedRowKeys, visibleOrgCOITableColumn)}
                                filterApp={App.OrgConflictOfInterest}
                                coi={false}

                            />
                        </TabPane>
                    }

                    <TabPane tab={this.translate("Text Search")} key="6">

                        <Table
                            columns={this.getSearchTableColumns()}
                            expandable={{ expandedRowRender: (data) => nestedRow(data, this.translate, this.moneyFormat), defaultExpandAllRows: false }}
                            dataSource={textSearchTable.Data}
                            loading={searchLoading}
                        />
                    </TabPane>


                </Tabs>

                </div>
            </div>
        )
    }
}
import { EventDataNode } from "antd/lib/tree";
import { App } from "./Enums";

import { FormInstance } from 'antd';
import moment, { Moment } from "moment";
import { SearchProps } from "antd/lib/input";

export interface GenericDTO {
    ID: number
    Name: string
    Description: string
}


export interface OrganizationDTO {
    ID: number
    Name: string
}



export const EmptyOrganization: OrganizationDTO = {
    ID: 0,
    Name: ""
}
export interface BaseDropDowns {
    status: GenericDTO[]
    organizations: GenericDTO[]
}

export interface GiftDropDowns extends BaseDropDowns {
    currencies: string[],
    criticalPeriods: GenericDTO[],
    acceptanceStatus: GenericDTO[],
    giftCategoryItems: GenericDTO[],
    preapprovalStatus: GenericDTO[],
    relationshipTypes: GenericDTO[],
    giftCategories: GenericDTO[],
}

export interface ConflictDropDowns extends BaseDropDowns {
    conflictTypes: GenericDTO[],
    personalObjectOfConflicts: GenericDTO[],
    organizationalObjectOfConflicts: GenericDTO[],
    publicOfficialRelationshipTypes: GenericDTO[],
    conflictStatus: GenericDTO[],
}

export interface OrgConflictDropDowns extends BaseDropDowns {
    conflictStatus: GenericDTO[],
    requestType: GenericDTO[],
    opportunityBuisnessGroup: GenericDTO[]

}


export interface TreeDTO extends EventDataNode {
    key: string,
    title: string,
    isLeaf: boolean,
    children: TreeDTO[],
    id: number,
    isActive: boolean,
    orgId: number
}

export interface MessageDetails {
    FullName: string
}

export interface Message {
    IsError: boolean,
    Data: MessageDetails,
    Message: string
}


//Comments Types
export interface CommentsBase {
    UserName: string,
    UpdatedOn: string,
    Comment: string,
    Roles: string[],
    UserPhoto: string
}

export type FormattedComment = {
    author: string,
    content: JSX.Element,
    datetime: string
}
export type CommentListType = {
    comments: FormattedComment[],
    translate(text: string): string
}

export type CommentType = {
    comments: CommentsBase[],
    translate(text: string): string
    submitFunction(text: string): Promise<void>
}

export type EditorType = {
    translate(text: string): string,
    submitting: boolean,
    value: string,
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>,
    onSubmit: React.MouseEventHandler<HTMLElement>,

}


export interface Contact {
    ID: number
    FullName: string
    EmailAddress: string,
    Business: Business,
    Title: string
}

//ProjectCard
export interface Business {
    ID: number,
    Name: string,
    Country: string
}

export const EmptyBusiness: Business = {
    ID: 0,
    Name: "",
    Country: ""
}

export interface BasicEmployee {
    ID: number,
    FullName: string,
}


export interface Employee extends BasicEmployee {

    EmailAddress: string,
    Roles: string[],
    PhoneNumber: string,
    PreferredLanguage: string,
    UsersAzureID: string
}

export const emptyEmployee: Employee = {
    ID: 0,
    FullName: "",
    EmailAddress: "",
    Roles: [],
    PhoneNumber: "",
    PreferredLanguage: "en",
    UsersAzureID: ""
}

export interface EmployeeWithPermissions extends Employee {
    IsAdmin: boolean,
    IsLegalOfficer: boolean,
    IsEthicOfficer: boolean,
    CanViewOrgCOI: boolean,
    RoleID: number
}

export interface Project {
    ID: number,
    ProjectName: string,
    ProjectNumber: string,
    ProjectScope: string,
    UltimateClient: Business | null,
    WSPClient: Business | null,
    KeyAccountManager: Employee | null,
    ProjectManager: Employee | null,
    ProjectStage: string,
    BuisnessGroup: string,
    Location: string
}

export interface ProjectCardType {
    Project: Project,
    translate(text: string): string
}


//filters



export interface filterType {
    filterVisible: any,
    translate: any,
    onCancel: any,
    initData: any,
    setFilter: any,
    dropDowns: any,
    showStatus: any,
    coi: any,
    filterApp: App
}


export interface filterFormType {
    form: FormInstance<any>
    showStatus: boolean,
    translate(text: string): string
    initData: any
    filterApp: App
    dropDown: BaseDropDowns
}

export interface giftFilterFormType extends filterFormType {
    dropDown: GiftDropDowns
}

export interface ConflictFilterFormType extends filterFormType {
    dropDown: ConflictDropDowns
}


export interface OrgConflictFilterFormType extends filterFormType {
    dropDown: OrgConflictDropDowns
}



// filter dtos
export interface pagination {
    current: number,
    pageSize: number,
    total: number
}

export interface sorter {
    field: string,
    order: string
}


export interface FilterDTO extends Object {
    Status: number[] | null,
    OfferedOn: [Moment, Moment] | null,
    CreatedOn: [Moment, Moment] | null,
    Employee: string | null,
    BuisnessName: string | null,
    BuisnessCountry: string | null,
    PublicOfficialName: string | null,
    SelectedIDs: number[] | null,
    SelectedColumns: string[] | null,
    Id: number
}

export const EmptyFilter: FilterDTO = {
    Status: null,
    OfferedOn: null,
    CreatedOn: null,
    Employee: null,
    BuisnessName: null,
    BuisnessCountry: null,
    PublicOfficialName: null,
    SelectedIDs: null,
    SelectedColumns: null,
    Id: 0
}
export interface ActionReturnType {
    Data: ActionsDTO[],
    Count: number
}

export interface ActionReturnTypeWithType extends ActionReturnType {
    Type: string,
    key: string
}

export interface SearchReturnType {
    Data: ActionReturnTypeWithType[],
    Count: number,
}
export interface ActionsDTO extends SearchProps {
    ID: number,
    key: number,
    Name: string,
    Description: string,
    StatusName: string,
    ContactName: string,
    OriginalValue: number,
    ConvertedValue: number,
    Currency: string,
    DateRecieved: Date,
    CreatedOn: Date,
    Employee: Employee,
    OnBehalfOf: Employee,
    Region: string,
    BusinessName: string,
    ProjectName: string,
    ActionType: number,
    Given: boolean,
    IsPublicOffical: boolean,
    PublicOfficials: Contact[]
}


export interface GiftFilterDTO extends FilterDTO {
    CriticalPeriod: number[]
    AcceptanceStatus: number[]
    GiftCategoryItems: number[]
    PreapprovalStatus: number[]
    Priorities: number[]
    RelationshipTypes: number[]
    Currencies: string[]
    GivenRecieved: number[]
    PublicOfficial: number[]
    Region: number[]
    ExpenseReportNumber: string,
    Type: number[]
}

export interface ConflictFilterDTO extends FilterDTO {
    Type: number[],
    PersonalObjectOfConflicts: number[],
    OrganizationalObjectOfConflicts: number[],
    PublicOfficialName: string,
    PublicOfficialRelationshipTypes: number[],
    ConflictStatus: number[],
    Region: number[],
    Truthful: number[],
    CompliesWithPolicy: number[]
}

export interface OrgConflictFilterDTO extends FilterDTO {
    ConflictStatus: number[],
    RequestType: number[],
    Region: number[],
    Truthful: number[],
    CompliesWithPolicy: number[],
    ConflictingProject: number[],
    ProjectName: string,
    ProjectNumber: string,
    ConflictingProjectName: string,
    ConflictingProjectNumber: string,
    ProgramOfWorks: string,
    ProjectManager: string,
    WSPClient: string,
    UltimateClient: string,
    BusinessGroup: number[]
}


// rule

export interface Rule {
    RuleID: number,
    key: number,
    Name: string,
    Given: boolean,
    RuleType: number,
    GiftCategories: number[],
    ParameterCurrency: number,
    ParameterValue: string,
    RulePriority: number,
    RegionIDs: number[],
    RegionNames: string[]
}

export interface RuleDropdowns {
    currencies: GenericDTO[],
    publicOfficals: GenericDTO[],
    giftCategories: GenericDTO[],
    ruleTypes: GenericDTO[],
    publicOfficialRelationshipTypes: GenericDTO[],
    organizations: OrganizationDTO[]
}


export interface IChartType {
    name: string,
    y: number,
    count?: number
}


export interface IChartWrapper {
    type?: string,
    data: IChartType[]
}
//Gifts


export interface Organization {
    Name: string
}


export interface Event {
    ID: number,
    EventType: string,
    AttendeesCount: number | null,
    Description: string,
    EventDate: Date,
    EventDateString: string,
    CreatedOn: Date,
    UpdatedOn: Date,
    CreatedBy: Employee,
    UpdatedBy: Employee
}



export interface FileDTO {
    ID: number,
    Name: string,
    CreatedOn: string,
    UpdatedOn: string,
}

export interface GiftFileDTO extends FileDTO {
    Private: boolean,
}

export interface ConflictOfInterestFileDTO extends FileDTO {
    Private: boolean,
}

export interface OrgConflictOfInterestFileDTO extends FileDTO {
    Private: boolean,
}

export interface RuleHistoryDTO {
    ID: number,
    key: number,
    GiftID: number,
    RuleID: number,
    Status: string,
    StatusID: number,
    Name: string,
    Comment: string,
    CreatedOn: Date,
    UpdatedOn: Date,
}
export interface AuditDetailsDTO {
    ID: number,
    ColumnChanged: string,
    OriginalValueDisplay: string,
    NewValueDisplay: string,
    ChangeTypeDisplay: string,

    ChangeAmount: null | number
}
export interface AuditDTO {
    ID: number,
    CreatedData: Date,
    User: Employee,
    GiftID: number,
    AuditDetails: AuditDetailsDTO[]
}
export interface LineItemDTO {
    ID: number,
    Contact: Contact,
    Currency: string,
    Amount: number
}

export interface Gift {
    ID: number
    Name: string
    Description: string
    Status: number,
    StatusName: string,
    OriginalValue: number,
    ApprovedValue: number,
    BusinessID: null | number,
    Business: Business | null,
    BusinessName: string,
    ContactID: null | number,
    Contact: Contact | null,
    Currency: string,
    OriginalAmount: string,
    ApprovedAmount: string,
    CreatedOn: Date | null,
    UpdatedOn: Date | null,
    CreatedBy: Employee | null,
    UpdatedBy: Employee | null,
    IsGiven: boolean,
    OrganizationID: null | number,
    Organization: Organization | null,
    LocationID: number,
    EmployeeID: null | number
    Employee: Employee | null,
    ExpenseReportNumber: string,
    BaseCurrencyValue: null | number
    GiftCategoryID: null | number,
    GiftCategoryName: string,
    IsAssociatedWithEvent: boolean | null,
    EventID: null | number,
    Event: Event | null,
    AdditionalComments: string
    ReviewComments: string
    GiftCategoryItemID: null | number,
    GiftCategoryItem: GenericDTO | null,
    RelationshipTypeID: null | number,
    RelationshipTypeName: string,
    CriticalPeriodID: null | number,
    CriticalPeriodName: string,
    ArePublicOfficials: boolean | null,
    IsDuringCriticalPeriod: boolean | null,
    AcceptanceStatusID: null | number,
    AcceptanceStatusName: string,
    ReasonForDecline: string,
    OtherEmployeesInvited: boolean | null,
    PreapprovalStatusID: null | number,
    PreapprovalStatusName: string,
    CompliesWithPolicy: boolean | null,
    OfferedOn: Date | null,
    Contacts: Contact[]
    GiftTypes: GenericDTO[]
    Priorities: GenericDTO[]
    OtherGiftCategory: string,
    OtherRelationshipType: string,
    OtherCriticalPeriod: string,
    OtherEmployeesInvitedNames: string,
    BusinessPurpose: string,
    Files: GiftFileDTO[],
    Comments: CommentsBase[],
    RuleHistory: RuleHistoryDTO[],
    LineItems: LineItemDTO[],
    AuditHistory: AuditDTO[],
    IsOnBehalfOf: boolean,
    ConvertedValue: number,
    CanDelete: boolean,
    ActionType: number,
}


export const GiftEmpty: Gift = {
    ID: 0,
    Name: "",
    Description: "",
    Status: 0,
    StatusName: "",
    OriginalValue: 0,
    ApprovedValue: 0,
    BusinessID: 0,
    Business: null,
    BusinessName: "",
    ContactID: 0,
    Contact: null,
    Currency: "",
    OriginalAmount: "",
    ApprovedAmount: "",
    CreatedOn: new Date(),
    UpdatedOn: new Date(),
    CreatedBy: null,
    UpdatedBy: null,
    IsGiven: false,
    OrganizationID: 0,
    Organization: null,
    LocationID: 0,
    EmployeeID: 0,
    Employee: null,
    ExpenseReportNumber: "",
    BaseCurrencyValue: 0,
    GiftCategoryID: 0,
    GiftCategoryName: "",
    IsAssociatedWithEvent: false,
    EventID: 0,
    Event: null,
    AdditionalComments: "",
    ReviewComments: "",
    GiftCategoryItemID: 0,
    GiftCategoryItem: null,
    RelationshipTypeID: 0,
    RelationshipTypeName: "",
    CriticalPeriodID: 0,
    CriticalPeriodName: '',
    ArePublicOfficials: false,
    IsDuringCriticalPeriod: false,
    AcceptanceStatusID: 0,
    AcceptanceStatusName: "",
    ReasonForDecline: "",
    OtherEmployeesInvited: false,
    PreapprovalStatusID: 0,
    PreapprovalStatusName: "",
    CompliesWithPolicy: false,
    OfferedOn: new Date(),
    Contacts: [],
    GiftTypes: [],
    Priorities: [],
    OtherGiftCategory: "",
    OtherRelationshipType: "",
    OtherCriticalPeriod: "",
    OtherEmployeesInvitedNames: "",
    BusinessPurpose: "",
    Files: [],
    Comments: [],
    RuleHistory: [],
    LineItems: [],
    AuditHistory: [],
    IsOnBehalfOf: false,
    ConvertedValue: 0,
    CanDelete: false,
    ActionType: 0,
}

export interface IConflictOfInterest {
    ID: number,
    ConflictTypeID: number,
    ConflictTypeName: string,
    PersonalObjectOfConflictID: number | null,
    PersonalObjectOfConflictName: string,
    OrganizationalObjectOfConflictID: number | null,
    OrganizationalObjectOfConflictName: string,
    PublicOfficialName: string,
    PublicOfficialBusinessID: number | null,
    PublicOfficialBusinessName: string,
    PublicOfficialRelationshipTypeID: number | null,
    PublicOfficialRelationshipTypeName: string,
    PublicOfficialPosition: string,
    Description: string,
    ConflictStatusID: number | null,
    ConflictStatusName: string,
    MitigationDescription: string,
    AdditionalComments: string,
    IsTruthful: boolean,
    PublicOfficialLeftOfficeDate: Date | null,
    EmployeeID: number | null,
    Employee: Employee,
    CreatedByID: number | null,
    CreatedBy: Employee,
    UpdatedByID: number | null,
    UpdatedBy: Employee,
    CreatedOn: Date | null,
    UpdatedOn: Date | null,
    RelationshipDetailWithPublicOfficial: string,
    OtherConflictType: string,
    OtherPersonalObjectOfConflict: string,
    OtherOrganizationalObjectOfConflict: string,
    CompliesWithPolicy: boolean | null,
    Status: number | null,
    StatusName: string,
    Files: ConflictOfInterestFileDTO[]
    Comments: CommentsBase[]
    IsOnBehalfOf: boolean,
    OrganizationID: number,
    Organization: OrganizationDTO,
    ReviewComments: string,
    ImplicatedBuisnessID: number | null,
    ImplicatedBuisness: Business
    NotifiedManager: boolean,
    CanDelete: boolean,
    ActionType: number,
    AuditHistory: AuditDTO[]

}

export const EmptyConflictOfInterest: IConflictOfInterest = {
    ID: 0,
    ConflictTypeID: 0,
    ConflictTypeName: "",
    PersonalObjectOfConflictID: null,
    PersonalObjectOfConflictName: "",
    OrganizationalObjectOfConflictID: null,
    OrganizationalObjectOfConflictName: "",
    PublicOfficialName: "",
    PublicOfficialBusinessID: null,
    PublicOfficialBusinessName: "",
    PublicOfficialRelationshipTypeID: null,
    PublicOfficialRelationshipTypeName: "",
    PublicOfficialPosition: "",
    Description: "",
    ConflictStatusID: null,
    ConflictStatusName: "",
    MitigationDescription: "",
    AdditionalComments: "",
    IsTruthful: false,
    PublicOfficialLeftOfficeDate: null,
    EmployeeID: null,
    Employee: emptyEmployee,
    CreatedByID: null,
    CreatedBy: emptyEmployee,
    UpdatedByID: null,
    UpdatedBy: emptyEmployee,
    CreatedOn: null,
    UpdatedOn: null,
    RelationshipDetailWithPublicOfficial: "",
    OtherConflictType: "",
    OtherPersonalObjectOfConflict: "",
    OtherOrganizationalObjectOfConflict: "",
    CompliesWithPolicy: null,
    Status: null,
    StatusName: "",
    Files: [],
    Comments: [],
    IsOnBehalfOf: false,
    OrganizationID: 0,
    Organization: EmptyOrganization,
    ReviewComments: "",
    ImplicatedBuisnessID: null,
    ImplicatedBuisness: EmptyBusiness,
    NotifiedManager: false,
    CanDelete: false,
    ActionType: 0,
    AuditHistory: []
}

export interface State {
    ID: number,
    Name: string,
}

export const EmptyState: State = {
    ID: 0,
    Name: ""
}

export interface IOrgConflictOfInterst {
    ID: number,
    OrganizationID: number | null,
    StateID: number | null,
    Status: number,
    StatusName: string,
    ProgramOfWorksID: number | null,
    WSPRoleType: number | null,
    WSPRoleTypeName: string,
    ConflictingProject: number | null,
    Description: string,
    MitigationProcess: string,
    AdditionalComments: string,
    CreatedOn: Date | null,
    UpdatedOn: Date | null,
    CreatedByID: number | null,
    UpdatedByID: number | null,
    DescribeRequestID: number | null,
    DescribeRequest: string,
    IsTruthful: boolean,
    AssignedAdvisorID: number | null,
    AssessmentResultID: number | null,
    AssessmentResult: string,
    Justification: string,
    ProjectID: number | null,
    EmployeeID: number | null,
    ProgramOfWork: Business,
    CreatedBy: Employee,
    UpdatedBy: Employee,
    AssignedTo: Employee,
    Employee: Employee,
    Organization: OrganizationDTO,
    StateProvince: State,
    ConflictingProjects: Project[],
    Projects: Project[]
    Comments: CommentsBase[],
    AuditHistory: AuditDTO[],
    Files: OrgConflictOfInterestFileDTO[],
    CanDelete: boolean,
    ActionType: number,
    ConflictStatus: number | null,
    ConflictStatusName: string,
    WSPRoleTypeOther: string,
    DescribeRequestOther: string,

}

export const EmptyOrgConflictOfInterest: IOrgConflictOfInterst = {
    ID: 0,
    OrganizationID: null,
    StateID: null,
    Status: 0,
    StatusName: "",
    ProgramOfWorksID: null,
    WSPRoleType: null,
    WSPRoleTypeName: "",
    ConflictingProject: null,
    Description: "",
    MitigationProcess: "",
    AdditionalComments: "",
    CreatedOn: null,
    UpdatedOn: null,
    CreatedByID: null,
    UpdatedByID: null,
    DescribeRequestID: null,
    DescribeRequest: "",
    IsTruthful: false,
    AssignedAdvisorID: null,
    AssessmentResultID: null,
    AssessmentResult: "",
    Justification: "",
    ProjectID: null,
    EmployeeID: null,
    ProgramOfWork: EmptyBusiness,
    CreatedBy: emptyEmployee,
    UpdatedBy: emptyEmployee,
    AssignedTo: emptyEmployee,
    Employee: emptyEmployee,
    Organization: EmptyOrganization,
    StateProvince: EmptyState,
    ConflictingProjects: [],
    Projects: [],
    Comments: [],
    AuditHistory: [],
    Files: [],
    CanDelete: false,
    ActionType: 0,
    ConflictStatus: null,
    ConflictStatusName: "",
    WSPRoleTypeOther: "",
    DescribeRequestOther: ""

}
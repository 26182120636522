import { Form, Button, List, Input, Comment, Avatar } from 'antd';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { CommentListType, EditorType, CommentType, FormattedComment } from "../models/Core";
import AuthService from '../services/AuthService';
import { UserOutlined } from '@ant-design/icons';

const { TextArea } = Input;


const CommentList = ({ comments, translate }: CommentListType) => (
    <List
        dataSource={comments}
        header={`${comments.length} ${comments.length > 1 ? translate('replies') : translate('reply')}`}
        itemLayout="horizontal"
        renderItem={props => <Comment {...props} />}
    />
);

const Editor = ({ onChange, onSubmit, submitting, value, translate }: EditorType) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} showCount maxLength={500} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                {translate("AddComment")}
            </Button>
        </Form.Item>
    </>
);

interface IAvatarPhoto {
    photoID: string
}
const AvatarPhoto = ({ photoID }: IAvatarPhoto) => {
    const [photo, setPhoto] = useState("");

    useEffect(() => {
        const getPhoto = async () => {
            const userPhoto = await AuthService.userPhotoByID(photoID);
            if (userPhoto) {
                setPhoto(userPhoto);
            }
        }
        if (photoID && photoID.length > 0) {
            getPhoto()
        }
    }, [])
    return (
        <>
            {photo && photo.length > 0 ?
                <Avatar size="default" src={photo} />
                :
                <Avatar size="default" icon={<UserOutlined />} />

            }
        </>
    )
}


const Comments = ({ comments, submitFunction, translate }: CommentType) => {

    const formattedComments: FormattedComment[] = comments.sort((a, b) => {
        return new Date(b.UpdatedOn).getTime() - new Date(a.UpdatedOn).getTime()
    })
        .map(x => {

            return {
                author: `${x.UserName} - ${x.Roles.map(y => translate(y)).join(', ')}`,
                content: <p>{x.Comment}</p>,
                datetime: moment(x.UpdatedOn).fromNow(),
                avatar: <AvatarPhoto photoID={x.UserPhoto} />
            }
        })


    const [submitting, setSubmitting] = useState(false);


    const [commentText, setCommentText] = useState('');

    const submitComment = () => {
        if (!commentText) {
            return;
        }

        setSubmitting(true);
        handleSubmit();

    }
    const handleSubmit = async () => {
        await submitFunction(commentText);
        setSubmitting(false);
        setCommentText('');
    }

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCommentText(e.target.value)
    }

    return (
        <div>
            <Comment
                content={<Editor
                    onChange={handleCommentChange}
                    onSubmit={submitComment}
                    submitting={submitting}
                    value={commentText}
                    translate={translate}
                />}
            />
            {formattedComments.length > 0 && <CommentList comments={formattedComments} translate={translate} />}
        </div>
    )
}



export default Comments;
import React, { useState, useEffect } from 'react';
import {
    Input, Row, Col, Select, Button,
    Modal, Spin, Form, Tree, message, Typography
} from 'antd';

import { App } from '../models/Enums'
import { TreeDTO } from '../models/Core';

import OrganizationService from '../services/OrganizationService';

const { Text } = Typography;

interface statesProvincesType {
    translate(text: string): string
}


let defaultStates: TreeDTO[] = []

const States = ({ translate }: statesProvincesType) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [states, setStates] = useState(defaultStates);
    const [currentOrg, setCurrentOrg] = useState<TreeDTO | null>(null);
    const [currentState, setCurrentState] = useState<TreeDTO | null>(null);

    const [name, setName] = useState("");

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        setLoading(true);
        const statesData: TreeDTO[] = await OrganizationService.getOrganizationsTreeStates(null);
        setStates(statesData);
        setLoading(false);
    }

    const selectOrg = (node: TreeDTO) => {

        if (node.isLeaf) {
            setCurrentState(node);
            setCurrentOrg(null);

        }
        else {
            setCurrentOrg(node);
            setCurrentState(null);
        }

    }


    const changeStateStatus = async () => {
        if (currentState === null || !currentState.key) {
            message.error(translate("errorOccured"), 10);
            return;
        }

        const res = await OrganizationService.changeStateStatus(currentState.id);
        if (res) {
            if (res.IsActive) {
                message.success(`${translate("Enabled")}: ${res.Name}`, 10)
            } else {
                message.success(`${translate("Disabled")}: ${res.Name}`, 10)

            }
            setCurrentOrg(null);
            setCurrentState(null);
            getData();
        }


    }

    const addState = async () => {
        setSubmitting(true);
        if (name.length === 0) {
            message.error(translate("nameMustNotBeBlank"), 10)
            return;
        }
        if (currentOrg === null || !currentOrg.key) {
            message.error(translate("errorOccured"), 10);
            return;
        }

        const res = await OrganizationService.addState(name, currentOrg.key);

        if (res) {
            message.success(`${translate("Added")}: ${name}`, 10)
            setName("");
            setCurrentOrg(null);
            setCurrentState(null);
            setShowModal(false);
            getData();
        }

        setSubmitting(false);

    }


    return (
        <>
            <Row>
                <Col>
                    <Button className="btn-success" style={{ marginRight: 10 }} disabled={currentOrg ? false : true} onClick={() => setShowModal(true)}>
                        {translate("AddState")}
                    </Button>
                    {
                        currentState && <Button className={currentState.isActive ? "btn-danger" : "btn-success"} onClick={changeStateStatus}>
                            {translate(currentState.isActive ? "DisableState" : "EnableState")}
                        </Button>
                    }

                </Col>
            </Row>
            <br />
            {
                loading ?
                    <Spin />
                    :
                    <div style={{ padding: 20, backgroundColor: '#ffffff', height: '100%', width: '100%' }}>
                        <Row>
                            <Col>
                                <Tree treeData={states}
                                    titleRender={(node) => {
                                        const data = node as TreeDTO;
                                        return <span>{data.title}  {!data.isActive ? <Text type="danger">({translate("Disabled")})</Text> : null}</span>
                                    }}
                                    onSelect={(key, e) => selectOrg(e.node as TreeDTO)} />
                            </Col>
                        </Row>
                    </div>
            }
            <Modal title={`${translate("AddState")} - ${currentOrg !== null ? currentOrg.title : ""}`} visible={showModal} onOk={addState}
                okText={translate("Add")}
                okButtonProps={{ disabled: name.length === 0 || submitting, loading: submitting }}
                cancelText={translate("Cancel")}
                onCancel={() => setShowModal(false)}>

                <Form>
                    <Form.Item label={translate("State/ProvinceName")}>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default States
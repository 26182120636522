import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { createBrowserHistory } from "history";
import Auth from './views/Auth';
import Home from './views/Home';
import CacheBuster from './components/CacheBuster';

const history = createBrowserHistory();

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <CacheBuster>

        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Switch>
              <Route path="/auth" component={Auth} />
              <Route path="/" component={Home} />
            </Switch>
          )
        }}

      </CacheBuster>

    );
  }
}

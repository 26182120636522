import React, { Component } from 'react'
import GiftService from '../services/GiftService';
import {
    Layout, Spin, Descriptions, PageHeader, Button, Row, Col,
    Tag, Typography, List, Modal, Select, Form, Input, Alert, Tooltip, Tabs, Badge, Table, Upload, Checkbox, message,
    Timeline
} from 'antd'
import { EditTwoTone, CheckCircleTwoTone, ExclamationCircleTwoTone, InboxOutlined } from '@ant-design/icons';
import OrganizationService from '../services/OrganizationService';
import DropdownService from '../services/DropdownService';
import CommentService from '../services/CommentService';
import RuleService from '../services/RuleService';
import BaseService from '../services/BaseService';
import moment from 'moment';
import Comments from './Comments';
import currencyFormatter from 'currency-formatter';
import TextArea from 'antd/lib/input/TextArea';
import { Gift, CommentsBase, RuleHistoryDTO, GiftFileDTO, AuditDTO, GiftEmpty, GenericDTO, AuditDetailsDTO, EmployeeWithPermissions } from '../models/Core';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';


const { Header, Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;




type IGiftDetailsParams = {
    id: string; // parameters will always be a string (even if they are numerical)
};

interface IGiftDetailsProps extends RouteComponentProps<IGiftDetailsParams> {
    languageCode: string
    history: History,
    user: EmployeeWithPermissions,


}

interface IGiftDetailsState {
    loading: boolean,
    gift: Gift,
    organizations: GenericDTO[],
    currencies: [],
    OrganizationName: string,
    ApprovedValue: number,
    ReviewComments: string,
    showEditModal: boolean,
    showStatusModal: boolean,
    statusModalTitle: string,
    editModalConfirmLoading: boolean,
    statusModalConfirmLoading: boolean,
    comments: CommentsBase[],
    showRuleVerifyButton: boolean,
    OrganizationID: number,
    ruleHistory: RuleHistoryDTO[],
    hasFailedRules: RuleHistoryDTO[],
    loadingRuleHistory: boolean,
    languageCode: string,
    langData: any,
    showFileModal: boolean,
    ShareWithUser: boolean,
    EmailUser: boolean,
    EmailUserComment: string,
    fileList: RcFile[],
    giftFiles: GiftFileDTO[],
    auditHistory: AuditDTO[],
    CanDelete: boolean
}



export default class GiftDetail extends Component<IGiftDetailsProps, IGiftDetailsState> {
    constructor(props: IGiftDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            gift: GiftEmpty,
            organizations: [],
            currencies: [],
            OrganizationName: '',
            ApprovedValue: 0,
            ReviewComments: '',
            showEditModal: false,
            showStatusModal: false,
            statusModalTitle: '',
            editModalConfirmLoading: false,
            statusModalConfirmLoading: false,
            comments: [],
            showRuleVerifyButton: false,
            OrganizationID: 0,
            ruleHistory: [],
            hasFailedRules: [],
            loadingRuleHistory: false,
            languageCode: props.languageCode,
            langData: {},
            showFileModal: false,
            ShareWithUser: false,
            EmailUser: false,
            EmailUserComment: '',
            fileList: [],
            giftFiles: [],
            auditHistory: [],
            CanDelete: false

        }
    }

    componentDidMount() {
        this.getLanguageData();
    }

    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label]
        }
        return label;
    }
    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getGiftDetailResources();
            this.setState({ langData });
            this.getData();

        })

    }

    getData = () => {
        const { id } = this.props.match.params;

        if (id) {
            this.setState({ loading: true }, async () => {
                const gift: Gift = await GiftService.getGift(id);
                const organizations = await OrganizationService.getOrganizations();
                this.setState({
                    loading: false,
                    gift, organizations,
                    OrganizationName: gift.Organization != null ? gift.Organization.Name : '',
                    ApprovedValue: gift.ApprovedValue ? gift.ApprovedValue : gift.OriginalValue,
                    ReviewComments: gift.ReviewComments ? gift.ReviewComments : '',
                    comments: gift.Comments ? gift.Comments : [],
                    ruleHistory: gift.RuleHistory ? gift.RuleHistory : [],
                    hasFailedRules: gift.RuleHistory ? gift.RuleHistory.filter(x => x.StatusID === 2) : [],
                    showRuleVerifyButton: gift.StatusName === "Submitted" || gift.StatusName === "InReview",
                    giftFiles: gift.Files ? gift.Files : [],
                    auditHistory: gift.AuditHistory ? gift.AuditHistory : [],
                    CanDelete: gift.CanDelete
                })
            })
        }
        else {
            this.props.history.push('/actions');
        }
    }

    changeGiftStatus = () => {
        const { statusModalTitle, ApprovedValue, ReviewComments, gift } = this.state;

        this.setState({ loading: true, showStatusModal: false }, async () => {
            if (statusModalTitle === 'Approve') {
                await GiftService.approveGift(gift.ID, ApprovedValue, ReviewComments);
                this.getData();
            }
            else if (statusModalTitle === 'InReview') {
                await GiftService.markInReview(this.state.gift.ID, ReviewComments);
                this.getData();
            }
            else if (statusModalTitle === 'UnderReview') {
                await GiftService.markUnderReview(this.state.gift.ID);
                this.getData();
            }
            else if (statusModalTitle === 'Reopen') {
                await GiftService.reopen(this.state.gift.ID);
                this.getData();
            }
            else if (statusModalTitle === 'MoveItemBackToSubmitted') {
                await GiftService.moveItem(this.state.gift.ID);
                this.getData();
            }

            
            else if (statusModalTitle === 'Delete') {
                const data = await GiftService.deleteGift(this.state.gift.ID);
                if (data) {
                    message.success(this.translate("deleteSuccessful"), 10)

                    this.props.history.push(`/actions`)
                } else {
                    message.error(this.translate("deleteError"), 10)

                    this.getData();

                }
            }
            else {
                await GiftService.rejectGift(gift.ID, ApprovedValue, ReviewComments);
                this.getData();
            }
        })
    }

    // markInReview = async () => {
    //     await GiftService.markInReview(this.state.gift.ID);
    //     this.getData();
    // }

    downloadFile = async (fileID: number) => {
        await GiftService.downloadFile(fileID);
    }

    lineItemAmount = () => {
        if (this.state.gift && this.state.gift.LineItems) {
            return this.state.gift.LineItems.reduce((x, y) => x + y.Amount, 0)
        }
        return 0
    }

    updateRuleHistory = () => {
        this.setState({ loadingRuleHistory: true }, async () => {
            var result = await RuleService.ReRunRule(this.state.gift.ID);
            if (result) {
                var ruleHistory = this.state.ruleHistory.map(x => {
                    x.Status = this.translate("Waiting");
                    x.Comment = "";
                    x.UpdatedOn = new Date()
                    return x
                });
                this.setState({ ruleHistory })
            } else {
                // say something went wrong...
                message.error(this.translate("reRunRuleError"), 10)

            }


            this.setState({ loadingRuleHistory: false })
        })


    }

    getStatus = () => {
        const text = this.state.gift.StatusName;
        switch (text) {
            case 'Submitted':
                return <Tag color="blue">{this.translate(text)}</Tag>;
            case 'Approved':
                return <Tag color="green">{this.translate(text)}</Tag>;
            case 'Rejected':
                return <Tag color="red">{this.translate(text)}</Tag>;
            case 'InReview':
                return <Tag color="orange">{this.translate(text)}</Tag>;
            case 'UnderReview':
                return <Tag color="#5260ff">{this.translate(text)}</Tag>;
            default:
                return <Tag>{this.translate(text)}</Tag>;
        }
    }

    getCompliesWithPolicy = () => {
        const complies = this.state.gift.CompliesWithPolicy;
        switch (complies) {
            case true:
                return <Tooltip title="Complies"><CheckCircleTwoTone twoToneColor='#52c41a'></CheckCircleTwoTone></Tooltip>;
            case false:
                return <Tooltip title="DoesNotComply"><ExclamationCircleTwoTone twoToneColor='#eb2f96'></ExclamationCircleTwoTone></Tooltip>;
            default:
                return null;
        }
    }

    getSubTitles = () => {
        return <div>{this.getStatus()} {this.getCompliesWithPolicy()}</div>
    }

    postComment = async (commentText: string) => {
        var res = await CommentService.AddGiftComment(this.state.gift.ID, commentText);

        if (res) {
            const comments = [res, ...this.state.comments];
            this.setState({ comments });
        }

    }

    moneyFormat = (Currency: string, Amount: number) => {
        return `${Currency} : ${currencyFormatter.format(Amount, { code: Currency })}`
    }

    getButtons = () => {
        let btns = [<Button key="2" className="btn-success" onClick={() => (
            this.setState({ statusModalTitle: 'Approve', showStatusModal: true })
        )}>{this.translate("Approve")}</Button>,
        <Button key="3" className="btn-danger" onClick={() => (
            this.setState({ statusModalTitle: 'Reject', showStatusModal: true })
        )}>{this.translate("Reject")}</Button>]


        if (this.state.gift.StatusName === 'Submitted') {

            btns.push(
                <Button key="1" onClick={() => (this.setState({ statusModalTitle: 'InReview', showStatusModal: true }))}>{this.translate("InReview")}</Button>,
            )
            btns.push(<Button key="5" className="btn-review" onClick={() => (
                this.setState({ statusModalTitle: 'UnderReview', showStatusModal: true })
            )}>{this.translate("UnderReview")}</Button>)

        }
        if (this.state.gift.StatusName === 'UnderReview') {
            btns.push(
                <Button key="1" onClick={() => (this.setState({ statusModalTitle: 'InReview', showStatusModal: true }))}>{this.translate("InReview")}</Button>,
            )

        }
        else if (this.state.gift.StatusName === 'InReview') {
            btns = [<Button key="2" disabled className="btn-success" onClick={() => (
                this.setState({ statusModalTitle: 'Approve', showStatusModal: true })
            )}>{this.translate("Approve")}</Button>,
            <Button key="3" disabled className="btn-danger" onClick={() => (
                this.setState({ statusModalTitle: 'Reject', showStatusModal: true })
                )}>{this.translate("Reject")}</Button>,
                <Button key="12" onClick={() => (this.setState({ statusModalTitle: 'MoveItemBackToSubmitted', showStatusModal: true }))}>{this.translate("MoveItemBackToSubmitted")}</Button>,

            ]
        }
        if (this.state.gift.StatusName === 'Approved' || this.state.gift.StatusName === 'Rejected') {
            btns = [];
            if (this.props.user.IsAdmin) {
                btns.push(
                    <Button key="10" onClick={() => (this.setState({ statusModalTitle: 'Reopen', showStatusModal: true }))}>{this.translate("Reopen")}</Button>,
                )
            }

        }


        if (this.state.gift.CanDelete) {
            btns.push(
                <Button key="8" className="btn-delete" onClick={() => (
                    this.setState({ statusModalTitle: 'Delete', showStatusModal: true })
                )}>{this.translate("Delete")}</Button>
            )
        }

        return btns;
    }

    ruleColumn = () => {
        return [
            {
                title: this.translate("RuleID"),
                dataIndex: 'Name',
                key: 'Name',

            },
            {
                title: this.translate("Status"),
                dataIndex: 'Status',
                key: 'Status',

            },
            {
                title: this.translate("Comment"),
                dataIndex: 'Comment',

            },
            {
                title: this.translate("UpdatedOn"),
                dataIndex: 'UpdatedOn',
                render: (updatedOn: Date) => {
                    return <span>{moment(updatedOn).local().format("MMM Do, YYYY")}</span>
                }
            },

        ]
    }

    auditColumn = () => {
        return [
            {
                title: this.translate("ChangeType"),
                dataIndex: 'ChangeTypeDisplay',

            },
            {
                title: this.translate("ColumnChanged"),
                dataIndex: 'ColumnChanged',
                render: (ColumnChanged: string) => {
                    return <span>{this.translate(ColumnChanged)}</span>
                }

            },
            {
                title: this.translate("OriginalValue"),
                dataIndex: 'OriginalValueDisplay',
                render: (data: any, row: AuditDetailsDTO) => {
                    const gift = this.state.gift;
                    if (row.ColumnChanged === "OriginalValue" && gift && gift.Currency) {
                        return this.moneyFormat(gift.Currency, data)
                    }

                    return <span>{this.translate(data)}</span>
                }

            },
            {
                title: this.translate("NewValue"),
                dataIndex: 'NewValueDisplay',
                render: (data: any, row: AuditDetailsDTO) => {
                    const gift = this.state.gift;

                    if (row.ColumnChanged === "OriginalValue" && gift && gift.Currency) {
                        return this.moneyFormat(gift.Currency, data)
                    }

                    return <span>{this.translate(data)}</span>
                }

            },

        ]
    }

    updateOrganization = () => {
        this.setState({ loading: true, showEditModal: false }, async () => {
            const { gift, OrganizationID } = this.state;
            await GiftService.updateOrganization(gift.ID, OrganizationID);
            this.getData();
        })
    }

    title = () => {
        const { gift } = this.state;
        return `ID - ${gift.ID}      ${gift.Name} ${gift.GiftCategoryItem ? ` /  ${gift.GiftCategoryItem.Name}` : ''} / ${gift.IsGiven ? 'Given' : 'Received'} `
    }


    CriticalPeriodLabel = (type: string) => {
        switch (type) {
            case "Gifts":
                return this.translate("GiftCriticalPeriod");
                break;

            default:
                return this.translate("CriticalPeriod")
                break;
        }
    }


    PublicOfficialLabel = (given: boolean) => {
        if (given) {
            return this.translate("PublicOfficialGiven")
        } else {
            return this.translate("PublicOfficalReceived")
        }
    }

    AcceptanceStatusLabel = (given: boolean, type: string) => {
        switch (type) {
            case "Gifts":
                return given ? this.translate("AcceptanceStatusGiftGiven") : this.translate("AcceptanceStatusGiftReceived");
                break;

            default:
                return this.translate("AcceptanceStatusName")
                break;
        }
    }


    uploadFiles = async () => {
        const { EmailUserComment, EmailUser, ShareWithUser, fileList, gift } = this.state;

        const emailComment = EmailUser ? EmailUserComment : ""
        const result = await GiftService.uploadFiles(fileList, gift.ID.toString(), String(ShareWithUser), String(EmailUser), emailComment);

        if (result) {
            this.setState(state => ({
                giftFiles: [...state.giftFiles, ...result],
            }));
            message.success(this.translate("FileUploaded"))
        } else {
            message.error(this.translate("fileUploadError"), 10)
        }
        this.setState({ showFileModal: false, EmailUserComment: '', EmailUser: false, ShareWithUser: false, fileList: [] })

    }

    render() {
        const { loading, gift, showEditModal, showStatusModal, statusModalTitle, ApprovedValue, ReviewComments, CanDelete,
            editModalConfirmLoading, statusModalConfirmLoading, organizations, OrganizationName, comments, loadingRuleHistory,
            ruleHistory, hasFailedRules, showRuleVerifyButton, showFileModal, EmailUserComment, EmailUser, ShareWithUser, giftFiles, auditHistory } = this.state;


        return (
            <div>
                {
                    loading ?
                        <Spin />
                        :
                        <div>
                            <Content>
                                <PageHeader
                                    ghost={false}
                                    onBack={() => window.history.length > 1 ? window.history.back() : this.props.history.push(`/actions`)}
                                    title={this.title()}
                                    subTitle={this.getSubTitles()}
                                    extra={this.getButtons()}
                                >
                                    {
                                        gift.Business ?
                                            <div style={{ alignItems: 'center' }}>
                                                <h2 style={{ display: 'inline', marginRight: 10 }}>
                                                    <span className="txt-label">{this.translate("EndBeneficiary")}</span>
                                                    {gift.Business.Name}
                                                </h2>
                                                <h2 style={{ float: 'right' }}><code>{gift.Name}</code></h2>
                                            </div>
                                            :
                                            null
                                    }
                                    <br />
                                    <Row>
                                        <Col span={12}>
                                            <h3><span className="txt-label">{this.translate("OriginalAmount")}</span> <code>{this.moneyFormat(gift.Currency, gift.OriginalValue)}</code></h3>
                                        </Col>
                                        {
                                            gift.Status && gift.ApprovedValue ?
                                                <Col span={12}>
                                                    <h3><span className="txt-label" style={gift.Status === 3 ? { color: 'red' } : { color: 'green' }}>
                                                        {this.translate("ApprovedAmount")}
                                                    </span> <code>{this.moneyFormat(gift.Currency, gift.ApprovedValue)}</code></h3>
                                                </Col>
                                                :
                                                null
                                        }
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <h3><span className="txt-label">{this.translate("ConvertedAmount")}</span> <code>{this.moneyFormat("CAD", gift.ConvertedValue)}</code></h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                gift.Organization ?
                                                    <h3><span className="txt-label">{this.translate("Region")}</span> {gift.Organization.Name}
                                                        <EditTwoTone style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.setState({ showEditModal: true })} /> </h3>
                                                    :
                                                    null
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            {
                                                gift.CreatedBy ?
                                                    <h3><span className="txt-label">{this.translate("CreatedBy")}</span> {gift.CreatedBy.FullName} - {moment(gift.CreatedOn).local().format("MMM Do, YYYY")} </h3>
                                                    :
                                                    null
                                            }
                                        </Col>
                                    </Row>
                                    {gift.UpdatedBy ? <Row>
                                        <Col>
                                            <h3><span className="txt-label">{this.translate("UpdatedBy")}</span> {gift.UpdatedBy.FullName} - {moment(gift.UpdatedOn).local().format("MMM Do, YYYY")} </h3>

                                        </Col>
                                    </Row> : null}
                                    <br />
                                    <Row>
                                        <Col span={hasFailedRules.length > 0 ? 8 : 0}>
                                            <Descriptions title={this.translate("FailedRules")}>
                                                {hasFailedRules.map(rule =>
                                                    <Descriptions.Item key={rule.RuleID} span={24}>{rule.Name}</Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </Col>
                                        <Col span={hasFailedRules.length > 0 ? 16 : 24}>
                                            <Descriptions title={this.translate("Description")}>
                                                <Descriptions.Item>{gift.Description}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                    <br />
                                    {
                                        gift.ReviewComments ?
                                            <Alert message={this.translate("ReviewComments")} style={{ marginBottom: 20 }}
                                                description={gift.ReviewComments} type="error" closable={false} />
                                            :
                                            null
                                    }
                                    {
                                        gift.AdditionalComments ?
                                            <Alert message={this.translate("AdditionalComments")} style={{ marginBottom: 20 }}
                                                description={gift.AdditionalComments} type="info" closable={false} />
                                            :
                                            null
                                    }
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab={this.translate("Details")} key="1">
                                            <Descriptions
                                                title={this.translate("Details")}
                                                bordered
                                            >

                                                {gift.OfferedOn ? <Descriptions.Item span={24} label={gift.IsGiven ? this.translate("OfferedOn") : this.translate("RecievedOn")}>{moment(gift.OfferedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                {gift.CreatedOn ? <Descriptions.Item span={24} label={this.translate("CreatedOn")}>{moment(gift.CreatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                {gift.UpdatedOn ? <Descriptions.Item span={24} label={this.translate("UpdatedOn")}>{moment(gift.UpdatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                {gift.BusinessPurpose ? <Descriptions.Item span={24} label={this.translate("BusinessPurpose")}>{gift.BusinessPurpose}</Descriptions.Item> : null}
                                                {gift.RelationshipTypeName ? <Descriptions.Item span={24} label={this.translate("RelationshipTypeName")}>{this.translate(gift.RelationshipTypeName)}</Descriptions.Item> : null}
                                                {gift.OtherRelationshipType ? <Descriptions.Item span={24} label={this.translate("OtherRelationshipType")}>{gift.OtherRelationshipType}</Descriptions.Item> : null}

                                                <Descriptions.Item span={24} label={this.PublicOfficialLabel(gift.IsGiven)}>{gift.ArePublicOfficials ? this.translate("Yes") : this.translate("No")}</Descriptions.Item>
                                                <Descriptions.Item span={24} label={this.CriticalPeriodLabel(gift.Name)}>{gift.IsDuringCriticalPeriod ? this.translate(gift.CriticalPeriodName) : this.translate("No")}</Descriptions.Item>
                                                {gift.OtherCriticalPeriod ? <Descriptions.Item span={24} label={this.translate("OtherCriticalPeriod")}>{gift.OtherCriticalPeriod}</Descriptions.Item> : null}

                                                {gift.PreapprovalStatusName ? <Descriptions.Item span={24} label={this.translate("PreapprovalStatusName")}>{this.translate(gift.PreapprovalStatusName)}</Descriptions.Item> : null}

                                                {gift.AcceptanceStatusName ? <Descriptions.Item span={24} label={this.AcceptanceStatusLabel(gift.IsGiven, gift.Name)}>{this.translate(gift.AcceptanceStatusName)}</Descriptions.Item> : null}


                                                {gift.ReasonForDecline ? <Descriptions.Item span={24} label={this.translate("ReasonForDecline")}>{gift.ReasonForDecline}</Descriptions.Item> : null}
                                                {gift.ExpenseReportNumber ? <Descriptions.Item span={24} label={this.translate("ExpenseReportNumber")}>{gift.ExpenseReportNumber}</Descriptions.Item> : null}

                                                {gift.Priorities && gift.Priorities.length > 0 ? <Descriptions.Item span={24} label={this.translate("Priorities")}>{gift.Priorities.map(x => this.translate(x.Name)).join(", ")}</Descriptions.Item> : null}

                                                {/* <Descriptions.Item span={24} label={this.translate("BiddingProcess")}>{gift.IsBiddingProcess ? this.translate("Yes") : this.translate("No")}</Descriptions.Item> */}
                                                <Descriptions.Item span={24} label={this.translate("Given")}>{gift.IsGiven ? this.translate("Yes") : this.translate("No")}</Descriptions.Item>
                                                <Descriptions.Item span={24} label={this.translate("IsAssociatedWithEvent")}>{gift.IsAssociatedWithEvent ? this.translate("Yes") : this.translate("No")}</Descriptions.Item>
                                                <Descriptions.Item span={24} label={this.translate("OtherEmployeesInvited")}>{gift.OtherEmployeesInvited ? this.translate("Yes") : this.translate("No")}</Descriptions.Item>
                                                {gift.OtherEmployeesInvitedNames ? <Descriptions.Item span={24} label={this.translate("OtherEmployeesInvitedNames")}>{gift.OtherEmployeesInvitedNames}</Descriptions.Item> : null}

                                                {gift.OtherGiftCategory ? <Descriptions.Item span={24} label={this.translate("OtherGiftCategory")}>{gift.OtherGiftCategory}</Descriptions.Item> : null}




                                            </Descriptions>

                                            <br />




                                            {
                                                gift.Contacts && gift.Contacts.length > 0 ?
                                                    <Descriptions
                                                        title={this.translate("Providers")}
                                                        bordered
                                                        column={24}
                                                    >


                                                        {gift.Contacts.map(contact => {

                                                            const rowSpan = contact.Business && contact.Business.Country ? 6 : 8;
                                                            let row = [<Descriptions.Item span={rowSpan} label={this.translate("Name")}>{contact.FullName}</Descriptions.Item>,
                                                            <Descriptions.Item span={rowSpan} label={this.translate("Company")}>{contact.Business != null ? contact.Business.Name : gift.Business != null ? gift.Business.Name : ''}</Descriptions.Item>]

                                                            if (contact.Business && contact.Business.Country) {
                                                                row.push(<Descriptions.Item span={rowSpan} label={this.translate("Country")}>{contact.Business.Country ? contact.Business.Country : "N/A"}</Descriptions.Item>)
                                                            }
                                                            row.push(<Descriptions.Item span={rowSpan} label={this.translate("Role")}>{contact.Title}</Descriptions.Item>)

                                                            return (row)
                                                        })}
                                                    </Descriptions>
                                                    :
                                                    null
                                            }
                                            <br />


                                            {

                                                gift.LineItems && gift.LineItems.length > 0 ?
                                                    <Descriptions
                                                        title={this.translate("LineItems")}
                                                        bordered
                                                        column={24}
                                                    >


                                                        {gift.LineItems.map(lineItems => {
                                                            return ([<Descriptions.Item span={6} label={this.translate("Name")}>{lineItems.Contact.FullName}</Descriptions.Item>,
                                                            <Descriptions.Item span={6} label={this.translate("Company")}>{lineItems.Contact.Business.Name}</Descriptions.Item>,
                                                            <Descriptions.Item span={6} label={this.translate("Role")}>{lineItems.Contact.Title}</Descriptions.Item>,
                                                            <Descriptions.Item span={6} label={this.translate("Amount")}>{this.moneyFormat(gift.Currency, lineItems.Amount)}</Descriptions.Item>
                                                            ])
                                                        })}
                                                        <Descriptions.Item span={6} label={this.translate("Total Amount")}>{this.moneyFormat(gift.Currency, this.lineItemAmount())}</Descriptions.Item>
                                                    </Descriptions>
                                                    :
                                                    null
                                            }
                                            <br />
                                            {
                                                gift.Employee ?
                                                    <Descriptions
                                                        title={this.translate("WSPContact")}
                                                        bordered
                                                    >
                                                        <Descriptions.Item span={24} label={this.translate("Name")}>{gift.Employee.FullName}</Descriptions.Item>
                                                        <Descriptions.Item span={24} label={this.translate("EmailAddress")}>{gift.Employee.EmailAddress}</Descriptions.Item>
                                                        <Descriptions.Item span={24} label={this.translate("PhoneNumber")}>{gift.Employee.PhoneNumber}</Descriptions.Item>
                                                    </Descriptions>
                                                    :
                                                    null
                                            }
                                            <br />
                                            {
                                                gift.Event ?
                                                    <Descriptions
                                                        title={this.translate("AssociatedEvent")}
                                                        bordered
                                                    >
                                                        <Descriptions.Item span={24} label={this.translate("EventType")}>{gift.Event.EventType}</Descriptions.Item>
                                                        <Descriptions.Item span={12} label={this.translate("Attendees")}>{gift.Event.AttendeesCount}</Descriptions.Item>
                                                        <Descriptions.Item span={12} label={this.translate("EventDate")}>{gift.Event.EventDateString}</Descriptions.Item>
                                                        <Descriptions.Item span={24} label={this.translate("EventDescription")}>{gift.Event.Description}</Descriptions.Item>
                                                    </Descriptions>
                                                    :
                                                    null
                                            }

                                        </TabPane>
                                        <TabPane tab={<span>{this.translate("Comments")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={comments.length} size="small" showZero></Badge></span>} key="2">
                                            {/* <Button onClick={() => this.postComment()}>Test</Button> */}


                                            <Comments comments={comments} submitFunction={this.postComment} translate={this.translate} />
                                        </TabPane>
                                        <TabPane tab={<span>{this.translate("Files")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={giftFiles ? giftFiles.length : 0} size="small" showZero></Badge></span>} key="3">

                                            <div>
                                                <Modal title={this.translate('addNewFile')} visible={showFileModal} onOk={this.uploadFiles}
                                                    confirmLoading={statusModalConfirmLoading}
                                                    okText={this.translate("Upload")}
                                                    okButtonProps={this.state.fileList.length > 0 ? { disabled: false } : { disabled: true }}
                                                    onCancel={() => this.setState({ showFileModal: false })}>
                                                    <Dragger
                                                        name={'files'}
                                                        multiple={true}
                                                        beforeUpload={file => {
                                                            this.setState(state => ({
                                                                fileList: [...state.fileList, file],
                                                            }));
                                                            return false;
                                                        }}
                                                        fileList={this.state.fileList}
                                                        onRemove={file => {
                                                            this.setState(state => {
                                                                const index = state.fileList.findIndex(x => x.uid === file.uid);
                                                                const newFileList = state.fileList.slice();
                                                                newFileList.splice(index, 1);
                                                                return {
                                                                    fileList: newFileList,
                                                                };
                                                            });

                                                        }}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">{this.translate("uploadText")}</p>
                                                        <p className="ant-upload-hint">
                                                            {this.translate("uploadHint")}
                                                        </p>
                                                    </Dragger>
                                                    <br />

                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                                        <Checkbox value={ShareWithUser} onChange={(e) => this.setState({ ShareWithUser: e.target.checked })}>{this.translate("ShareWithUser")}</Checkbox>
                                                        <Checkbox value={EmailUser} onChange={(e) => this.setState({ EmailUser: e.target.checked })}>{this.translate("EmailUser")}</Checkbox>

                                                    </div>
                                                    <br />

                                                    {EmailUser && <Form.Item label={this.translate("EmailFileComment")}>
                                                        <TextArea value={EmailUserComment} rows={4} onChange={(e) => { this.setState({ EmailUserComment: e.target.value }) }}></TextArea>
                                                    </Form.Item>}
                                                </Modal>


                                                <List header={<h3><span className="txt-label">{this.translate("Files")}</span><Button size={"small"} type={'primary'} onClick={() => this.setState({ showFileModal: true })}>{this.translate("addNewFile")}</Button></h3>}
                                                    bordered
                                                    dataSource={giftFiles}
                                                    locale={
                                                        { emptyText: this.translate("NoFiles") }
                                                    }
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <Typography.Link onClick={() => this.downloadFile(item.ID)}
                                                            >{item.Name}
                                                            </Typography.Link>
                                                            {item.Private && <Tag color="red">{this.translate("Private")}</Tag>}
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>

                                        </TabPane>

                                        <TabPane tab={<span>{this.translate("Rules")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={ruleHistory ? ruleHistory.length : 0} size="small" showZero></Badge></span>} key="4">
                                            <Row>
                                                <Table
                                                    title={() => <Row><Col ><h3>{this.translate("Rules")}</h3></Col> <Col offset={2}> {showRuleVerifyButton && <Button onClick={() => this.updateRuleHistory()}> {this.translate("VerifyRules")}</Button>} </Col></Row>}
                                                    dataSource={ruleHistory} columns={this.ruleColumn()} style={{ width: '100%' }}
                                                    loading={loadingRuleHistory}
                                                />

                                            </Row>

                                        </TabPane>

                                        <TabPane tab={<span>{this.translate("AuditHistory")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={auditHistory ? auditHistory.length : 0} size="small" showZero></Badge></span>} key="5">
                                            <Row>
                                                <Timeline style={{ width: '100%' }}>
                                                    {auditHistory.map(audit => (
                                                        <Timeline.Item key={audit.ID}>
                                                            <Table
                                                                tableLayout="fixed"
                                                                title={() => <Row><Col ><h4>{`${audit.User.FullName}${audit.User.Roles && audit.User.Roles.length > 0 && ` - ${audit.User.Roles.map(y => this.translate(y)).join(", ")}`} - ${moment(audit.CreatedData).local().format("MMM Do, YYYY h:mm:ss a")}`}</h4></Col> </Row>}
                                                                dataSource={audit.AuditDetails} columns={this.auditColumn()} style={{ width: '100%' }}
                                                                size="small" pagination={false}
                                                            />


                                                        </Timeline.Item>
                                                    ))}

                                                </Timeline>

                                            </Row>

                                        </TabPane>


                                    </Tabs>


                                </PageHeader>
                            </Content >
                        </div >
                }
                <Modal title={this.translate("UpdateRegion")} visible={showEditModal} onOk={this.updateOrganization}
                    confirmLoading={editModalConfirmLoading}
                    okText={this.translate("Update")}
                    onCancel={() => this.setState({ showEditModal: false })}>
                    <Form>
                        <Form.Item label={this.translate("Region")}>
                            <Select placeholder={this.translate("PleaseSelectTheRegion")}
                                defaultValue={gift.OrganizationID ?? 60}
                                onChange={(OrganizationID: number) => this.setState({ OrganizationID })}>
                                {
                                    organizations.map(org => <Option key={`org${org.ID}`} value={org.ID}>{org.Name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title={this.translate(statusModalTitle)} visible={showStatusModal} onOk={this.changeGiftStatus}
                    confirmLoading={statusModalConfirmLoading}
                    okText={statusModalTitle != "Delete" ? this.translate("Update") : this.translate("Delete")}
                    onCancel={() => this.setState({ showStatusModal: false })}>
                    <Form>

                        {statusModalTitle != "InReview" && statusModalTitle != "UnderReview"
                            && statusModalTitle != "Delete" && statusModalTitle != "Reopen" && statusModalTitle != "MoveItemBackToSubmitted"
                            && <div>{this.translate("RequestedAmount")}: <code>{gift.OriginalAmount}</code>
                                <br /><br />
                                <Form.Item label={this.translate("ApprovedAmount")}>
                                    <Input placeholder={this.translate("EnterApprovedAmount")} value={ApprovedValue} addonBefore={gift.Currency}
                                        type="number"
                                        onChange={(e) => { this.setState({ ApprovedValue: +e.target.value }) }} />
                                </Form.Item></div>}


                        {statusModalTitle != "UnderReview" && statusModalTitle != "Reopen" && statusModalTitle != "MoveItemBackToSubmitted" && statusModalTitle != "Delete" ? <Form.Item label={this.translate("ReviewComments")}>
                            <Input.TextArea showCount maxLength={1000} rows={4} value={ReviewComments} onChange={(e) => { this.setState({ ReviewComments: e.target.value }) }} />
                        </Form.Item> :

                            statusModalTitle == "Delete" ?
                                <span>{this.translate("DeleteEntry")}</span>
                                : statusModalTitle == "Reopen" ?
                                    <span>{this.translate("SetReviewStateReopen")}</span>

                                    :
                                    statusModalTitle == "MoveItemBackToSubmitted" ? <span>{this.translate("MoveItemBackToSubmitteds")} </span>
                                        :
                                    <span>{this.translate("SetReviewState")} </span>

                        }

                    </Form>
                </Modal>
            </div >
        )
    }
}

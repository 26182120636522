import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { IChartWrapper } from '../../models/Core';

interface IPieChartProps {
    series: IChartWrapper[]
}

const PieChart = ({ series }: IPieChartProps) => {
    const mapOptions = {
        chart: {
            plotBackgroundColor: 'transparent',
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 300
        },
        title: {
            text: ""
        },
        subtitle: {
            text: ""
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}% (Count: {point.count})</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: series ? series : []
    }


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={mapOptions}
        />
    )
}

export default PieChart;
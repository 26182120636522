import Api from './Api';
import { AuthContext, AdalApiFetch } from '../config/AdalConfig';

let photos = new Map<string, string>();

const AuthService = {
    loginUser: async function () {

    },
    getLoggedInUser: async function () {
        try {
            const response = await Api.get("/api/Employees/Current", {
                headers: {

                    'Accept': 'application/json'
                }
            });
            return response.data;
        }
        catch (e) {
            console.error(e);
        }
    },
    userPhoto: async function () {
        try {
            const response = await AdalApiFetch("https://graph.microsoft.com/v1.0/me/photo/$value", {})
            if (response != null && response.ok) {
                const blob = await response.blob();
                if (blob) {
                    return URL.createObjectURL(blob);
                }
            }

        }
        catch (e) {
            console.error(e);
        }
        return null;

    }

    ,
    userPhotoByID: async function (id: string) {
        if (photos.has(id)) {
            return photos.get(id);
        }
        try {
            const response = await AdalApiFetch(`https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, {})
            if (response != null && response.ok) {
                const blob = await response.blob();
                if (blob) {
                    let photo = URL.createObjectURL(blob)
                    photos.set(id, photo)
                    return photo;
                }

            } else {
                return null;

            }
        }
        catch (e) {
            console.error(e);
        }

        return null;
    }


};

export default AuthService;
import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import mime from 'mime-types';
import api from './Api';
import { ConflictFilterDTO, pagination, sorter } from '../models/Core';


const ConflictOfInterestService = {
  NAME: 'CONFLICT OF INTEREST SERVICE',

  getActions: async function (filter: ConflictFilterDTO, pagination: pagination, sorter: sorter, query: string) {
    try {

      const OfferedOn = filter.OfferedOn ?? [];
      const CreatedOn = filter.CreatedOn ?? [];

      let Status = filter.Status ?? [];

      const response = await api.get(`${ApiConfig.baseURL}/api/ConflictOfInterests/GetActions`, {
        params: {
          BuisnessName: filter.BuisnessName,
          CreatedOn: CreatedOn.map(x => x.toDate()),
          OfferedOn: OfferedOn.map(x => x.toDate()),
          Employee: filter.Employee, Type: filter.Type, PersonalObjectOfConflicts: filter.PersonalObjectOfConflicts,
          OrganizationalObjectOfConflicts: filter.OrganizationalObjectOfConflicts,
          PublicOfficialRelationshipTypes: filter.PublicOfficialRelationshipTypes, ConflictStatus: filter.ConflictStatus,
          Truthful: filter.Truthful, CompliesWithPolicy: filter.CompliesWithPolicy, Status,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          sortDir: sorter.order, searchQuery: query, Region: filter.Region,
          BuisnessCountry: filter.BuisnessCountry, PublicOfficialName: filter.PublicOfficialName,
          Id: filter.Id ?? 0
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  get: async function (id: string) {
    try {
      const response = await api.get(`${ApiConfig.baseURL}/api/ConflictOfInterests/hub/${id}`);
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  markUnderReview: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/underreview`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  markInReview: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/inreview`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  reopen: async function (ID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/Reopen`, qs.stringify({
        ID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  delete: async function (ID: number) {
    try {
      const response = await api.post(`${ApiConfig.baseURL}/api/ConflictOfInterests/DeleteConflict/${ID}`);
      return response;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },
  approve: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/approve`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  reject: async function (ID: number, ReviewComments: string) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/reject`, qs.stringify({
        ID, ReviewComments
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  updateOrganization: async function (ID: number, OrganizationID: number) {
    try {
      const response = await api.put(`${ApiConfig.baseURL}/api/ConflictOfInterests/updateorganization`, qs.stringify({
        ID, OrganizationID
      }));
      return response.data;
    } catch (e) {
      console.log(this.NAME, e);
    }
  },

  uploadFiles: async function (fileList: Blob[], ID: string, shareWithUser: string, EmailUser: string, EmailComment: string) {
    try {
      const formData = new FormData();
      formData.append("GiftID", ID);
      formData.append("ShareWithUser", shareWithUser);
      formData.append("EmailUser", EmailUser);
      formData.append("EmailComment", EmailComment);
      fileList.forEach(file => {
        formData.append("files", file)

      });

      const response = await api.post(`${ApiConfig.baseURL}/api/ConflictOfInterests/hubUpload`, formData);


      if (response && response.status === 200) {
        return response.data;
      } else {
        return null
      }

    } catch (e) {
      console.log(this.NAME, e);
      return null;
    }
  },

  downloadActions: async function (filter: ConflictFilterDTO, pagination: pagination, sorter: sorter, query: string, selectedRowKeys: number[], selectedColumns: string[]) {
    try {

      const OfferedOn = filter.OfferedOn ?? [];
      const CreatedOn = filter.CreatedOn ?? [];

      let Status = filter.Status ?? [];

      let response = await axios({
        url: `${ApiConfig.baseURL}/api/ConflictOfInterests/GetActionsExcel/`,

        params: {
          BuisnessName: filter.BuisnessName,
          OfferedOn: OfferedOn.map(x => x.toDate()),
          CreatedOn: CreatedOn.map(x => x.toDate()),

          Employee: filter.Employee, Type: filter.Type, PersonalObjectOfConflicts: filter.PersonalObjectOfConflicts,
          OrganizationalObjectOfConflicts: filter.OrganizationalObjectOfConflicts,
          PublicOfficialRelationshipTypes: filter.PublicOfficialRelationshipTypes, ConflictStatus: filter.ConflictStatus,
          Truthful: filter.Truthful, CompliesWithPolicy: filter.CompliesWithPolicy, Status,
          page: pagination.current, limit: pagination.pageSize, sortField: sorter.field,
          sortDir: sorter.order, searchQuery: query, Region: filter.Region,
          BuisnessCountry: filter.BuisnessCountry, PublicOfficialName: filter.PublicOfficialName, SelectedIDs: selectedRowKeys,
          SelectedColumns: selectedColumns,
          Id: filter.Id
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        },

        method: 'GET',
        responseType: 'blob', // important
      })
      if (response) {
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
        return true;
      }
    } catch (e) {
      console.log(this.NAME, e);
      return false;
    }
    return false;

  },

  downloadFile: async function (ID: number) {
    try {
      axios({
        url: `${ApiConfig.baseURL}/api/ConflictOfInterests/DownloadFile/${ID}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileExtension = mime.extension(contentType);
        link.setAttribute('download', `file.${fileExtension}`);
        document.body.appendChild(link);
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '');
            link.setAttribute('download', filename);

          }
        }
        link.click();
      });
    } catch (e) {
      console.log(this.NAME, e);
    }
    return false;

  }
};

export default ConflictOfInterestService;
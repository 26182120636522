import React, { Component } from 'react';
import { Button, Spin, Alert } from 'antd';
import { AuthContext, adalConfig } from '../config/AdalConfig';

export default class Auth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errorMessage: ''
        }
    }

    login = () => {
        this.setState({ loading: true }, () => {
            if (!AuthContext.getCachedToken(adalConfig.clientId) || !AuthContext.getCachedUser()) {
                AuthContext.login()
            } else {
                AuthContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
                    if (token) {
                        this.props.history.push({ pathname: '/' })
                    } else {
                        this.setState({ errorMessage: message, loading: false });
                    }
                })
            }
        })
    }

    render() {
        const { loading, errorMessage } = this.state;

        return (
            <div id="splash" className="auth">
                <div className="dead-center">
                    <img src="/img/wsp_logo.png" style={{ height: '50px' }} />
                    <h1>Gifts, Entertainment & Hospitality (GEH) System</h1>
                    <br />
                    {
                        loading ?
                            <Spin />
                            :
                            <div>
                                <Button type="primary" onClick={this.login}>Login</Button>
                                {
                                    errorMessage ?
                                        <Alert style={{ marginTop: 20 }} message={errorMessage} type="error" />
                                        :
                                        null
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }
}

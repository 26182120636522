import React, { useState, useEffect } from 'react';
import {
    Input, Row, Col, Select, Form,
    Modal, DatePicker, FormInstance
} from 'antd';

import { App } from '../models/Enums'
import { filterFormType, GenericDTO, giftFilterFormType, ConflictFilterFormType, OrgConflictFilterFormType, filterType } from '../models/Core';
const { RangePicker } = DatePicker;


const BaseFilters = ({ showStatus, translate, filterApp, dropDown }: filterFormType) => {

    const statusDropDowns: GenericDTO[] = dropDown.status ?? [];
    const organizations: GenericDTO[] = dropDown.organizations ?? [];


    return (
        <>
            {showStatus && <Col span={6}>
                <Form.Item name="Status" label={translate("Status")}>
                    <Select mode="multiple">
                        {statusDropDowns.filter(x => x.ID != 4).map(x => (
                            <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                        ))}

                    </Select>
                </Form.Item>
            </Col>}


            <Col span={6}>
                <Form.Item name="Region" label={translate("Region")}>
                    <Select mode="multiple">
                        {organizations.map(x => (
                            <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                        ))}

                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="CreatedOn" label={translate("CreatedOn")}>
                    <RangePicker />
                </Form.Item>
            </Col>

            <Col span={6}>
                <Form.Item name="Employee" label={translate("Employee")}>
                    <Input placeholder={translate("SearchPlaceHolderGift")} />
                </Form.Item>
            </Col>

        </>
    )
}

const GiftFilters = ({ form, showStatus, translate,
    initData,
    filterApp, dropDown
}: giftFilterFormType) => {

    const giftCategories = dropDown.giftCategories ?? [];
    const currencies = dropDown.currencies ?? [];
    const criticalPeriods = dropDown.criticalPeriods ?? [];
    const acceptanceStatus = dropDown.acceptanceStatus ?? [];
    const giftCategoryItems = dropDown.giftCategoryItems ?? [];
    const preapprovalStatus = dropDown.preapprovalStatus ?? [];
    const relationshipTypes = dropDown.relationshipTypes ?? [];


    return (
        <Form form={form} initialValues={initData}>
            <Row gutter={[16, 16]}>
                <BaseFilters
                    showStatus={showStatus}
                    translate={translate}
                    form={form}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDown}
                />

                <Col span={6}>
                    <Form.Item name="OfferedOn" label={translate("OfferedOn")}>
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="Currencies" label={translate("Currency")}>
                        <Select mode="multiple">
                            {currencies.map(x => (
                                <Select.Option key={x} value={x}>{x}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="GivenRecieved" label={translate("GivenRecieved")}>
                        <Select mode="multiple">
                            <Select.Option key={1} value={1}>{translate("Given")}</Select.Option>
                            <Select.Option key={2} value={2}>{translate("Recieved")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="ExpenseReportNumber" label={translate("ExpenseReportNumber")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>



                <Col span={6}>
                    <Form.Item name="PublicOfficial" label={translate("Public Official")}>
                        <Select mode="multiple">

                            <Select.Option key={1} value={1}>{translate("Yes")}</Select.Option>
                            <Select.Option key={2} value={2}>{translate("No")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="CriticalPeriod" label={translate("CriticalPeriod")}>
                        <Select mode="multiple">
                            {criticalPeriods.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>


                <Col span={6}>
                    <Form.Item name="AcceptanceStatus" label={translate("AcceptanceStatus")}>
                        <Select mode="multiple">
                            {acceptanceStatus.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="GiftCategoryItems" label={translate("giftCategoryItems")}>
                        <Select mode="multiple">
                            {giftCategoryItems.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="PreapprovalStatus" label={translate("preapprovalStatus")}>
                        <Select mode="multiple">
                            {preapprovalStatus.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>


                <Col span={6}>
                    <Form.Item name="RelationshipTypes" label={translate("relationshipTypes")}>
                        <Select mode="multiple">
                            {relationshipTypes.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="Type" label={translate("Type")}>
                        <Select

                            mode="multiple">
                            {giftCategories.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}



                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="BuisnessName" label={translate("BuisnessName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="BuisnessCountry" label={translate("BuisnessCountry")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="PublicOfficialName" label={translate("PublicOfficialName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    )
}


const ConflictFilters = ({ form, showStatus, translate,
    initData,
    filterApp, dropDown
}: ConflictFilterFormType) => {


    const conflictTypes = dropDown.conflictTypes ?? [];
    const personalObjectOfConflicts = dropDown.personalObjectOfConflicts ?? [];
    const organizationalObjectOfConflicts = dropDown.organizationalObjectOfConflicts ?? [];
    const publicOfficialRelationshipTypes = dropDown.publicOfficialRelationshipTypes ?? [];
    const conflictStatus = dropDown.conflictStatus ?? [];


    return (
        <Form form={form} initialValues={initData}>
            <Row gutter={[16, 16]}>
                <BaseFilters
                    showStatus={showStatus}
                    translate={translate}
                    form={form}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDown}
                />

                <Col span={6}>
                    <Form.Item name="Type" label={translate("Type")}>
                        <Select

                            mode="multiple">
                            {conflictTypes.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}



                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="PersonalObjectOfConflicts" label={translate("PersonalObjectOfConflicts")}>
                        <Select mode="multiple">
                            {personalObjectOfConflicts.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="OrganizationalObjectOfConflicts" label={translate("OrganizationalObjectOfConflicts")}>
                        <Select mode="multiple">
                            {organizationalObjectOfConflicts.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>


                <Col span={6}>
                    <Form.Item name="PublicOfficialRelationshipTypes" label={translate("PublicOfficialRelationshipTypes")}>
                        <Select mode="multiple">
                            {publicOfficialRelationshipTypes.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ConflictStatus" label={translate("ConflictStatus")}>
                        <Select mode="multiple">
                            {conflictStatus.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="Truthful" label={translate("Truthful")}>
                        <Select mode="multiple">
                            <Select.Option key={1} value={1}>{translate("Yes")}</Select.Option>
                            <Select.Option key={2} value={2}>{translate("No")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="CompliesWithPolicy" label={translate("CompliesWithPolicy")}>
                        <Select mode="multiple">
                            <Select.Option key={1} value={1}>{translate("Yes")}</Select.Option>
                            <Select.Option key={2} value={2}>{translate("No")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="BuisnessName" label={translate("BuisnessName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="BuisnessCountry" label={translate("BuisnessCountry")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="PublicOfficialName" label={translate("PublicOfficialName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>


            </Row>
        </Form>

    )
}



const OrgConflictFilters = ({ form, showStatus, translate,
    initData,
    filterApp, dropDown
}: OrgConflictFilterFormType) => {
    const conflictStatus: GenericDTO[] = dropDown.conflictStatus ?? [];
    const describeRequest: GenericDTO[] = dropDown.requestType ?? [];
    const statusDropDowns: GenericDTO[] = dropDown.status ?? [];
    const opportunityBuisnessGroupDropDowns: GenericDTO[] = dropDown.opportunityBuisnessGroup ?? [];

    return (
        <Form form={form} initialValues={initData}>
            <Row gutter={[16, 16]}>
                <BaseFilters
                    showStatus={showStatus}
                    translate={translate}
                    form={form}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDown}
                />
                <Col span={6}>
                    <Form.Item name="ConflictingProject" label={translate("ConflictingProject")}>
                        <Select mode="multiple">
                            <Select.Option key={1} value={1}>{translate("Yes")}</Select.Option>
                            <Select.Option key={2} value={2}>{translate("No")}</Select.Option>
                            <Select.Option key={3} value={3}>{translate("DontKnow")}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>



                <Col span={6}>
                    <Form.Item name="ProjectName" label={translate("ProjectName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ProjectNumber" label={translate("ProjectNumber")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ConflictingProjectName" label={translate("ConflictingProjectName")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ConflictingProjectNumber" label={translate("ConflictingProjectNumber")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="ConflictStatus" label={translate("ConflictStatus")}>
                        <Select mode="multiple">
                            {conflictStatus.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="RequestType" label={translate("RequestType")}>
                        <Select mode="multiple">
                            {describeRequest.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ProgramOfWorks" label={translate("ProgramOfWorks")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="Status" label={translate("WorkflowStatus")}>
                        <Select mode="multiple">
                            {statusDropDowns.map(x => {
                                if (x.Name === "InReview" || x.Name === "UnderReview") {
                                    return (
                                        <Select.Option key={x.ID} value={x.ID}>{translate(`org_${x.Name}`)}</Select.Option>
                                    )
                                }
                                return (
                                    <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                                )
                            })}

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="ProjectManager" label={translate("ProjectManager")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="WSPClient" label={translate("WSPClient")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="UltimateClient" label={translate("UltimateClient")}>
                        <Input placeholder={translate("SearchPlaceHolderGift")} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="BusinessGroup" label={translate("BusinessGroup")}>
                        <Select mode="multiple">
                            {opportunityBuisnessGroupDropDowns.map(x => (
                                <Select.Option key={x.ID} value={x.ID}>{translate(x.Name)}</Select.Option>
                            ))}

                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    )
}




const Filters = ({ filterVisible, translate, onCancel, initData, setFilter, dropDowns, showStatus, filterApp }: filterType) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (filterVisible) {
            form.resetFields();
        }
    }, [initData, filterVisible])

    const changeFilters = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                setFilter(values);
            }).catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const renderForm = () => {
        switch (filterApp) {
            case App.Gifts:
                return (<GiftFilters
                    form={form}
                    showStatus={showStatus}
                    translate={translate}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDowns}
                />)
                break;
            case App.ConflictOfInterest:
                return (<ConflictFilters
                    form={form}
                    showStatus={showStatus}
                    translate={translate}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDowns}
                />)
                break;
            case App.OrgConflictOfInterest:
                return (<OrgConflictFilters
                    form={form}
                    showStatus={showStatus}
                    translate={translate}
                    initData={initData}
                    filterApp={filterApp}
                    dropDown={dropDowns}
                />)
                break;

            default:
                return (null);
                break;
        }
    }

    return (
        <Modal width={"90vw"} title={translate('Filters')} visible={filterVisible} onOk={changeFilters}
            okText={translate("Update")}
            onCancel={onCancel}
            getContainer={false}
        >
            {renderForm()}

        </Modal>
    )
}

export default Filters;
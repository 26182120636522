import axios from './Api';
import ApiConfig from '../config/ApiConfig';
import qs from 'qs';
import mime from 'mime-types';
import api from './Api';


const SearchService = {


  getActionsSearch: async function (query: string) {
    try {

      const response = await api.get(`${ApiConfig.baseURL}/api/Search`, {
        params: {
          searchQuery: query,
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      });
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  },

};

export default SearchService;
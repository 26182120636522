import React, { Component } from 'react'
import { Tabs, Select, Form, Spin } from 'antd';
import EthicsOfficers from './EthicsOfficers';
import States from './States';
import Region from './Region';
import UsersTab from './Users';
import Rules from './Rules';
import DropdownService from '../services/DropdownService';
import BaseService from '../services/BaseService';

import OrganizationService from '../services/OrganizationService';
import { History } from 'history';
import { EmployeeWithPermissions, GenericDTO } from '../models/Core';
import { RouteComponentProps } from 'react-router';

const { Option } = Select;
const { TabPane } = Tabs;


interface IRoutes {
    id: string,
    route: string
}

type AdminParams = {
    id: string; // parameters will always be a string (even if they are numerical)
};

interface IAdminProps extends RouteComponentProps<AdminParams> {
    languageCode: string | null,
    history: History
    user: EmployeeWithPermissions,

    setLanguage(text: string): void


}
interface IAdminState {
    loading: Boolean,
    supportedLanguages: GenericDTO[],
    languageCode: string,
    langData: any,
    key: string,
    routes: IRoutes[]
}



export default class Admin extends Component<IAdminProps, IAdminState> {
    constructor(props: IAdminProps) {
        super(props);
        this.state = {
            loading: false,
            supportedLanguages: [],
            languageCode: props.languageCode ? props.languageCode : 'en',
            langData: {},
            key: "1",
            routes: [{ id: "1", route: "Settings" }
                , { id: "4", route: "EthicsOfficers" }
                , { id: "5", route: "LegalOfficers" }
                , { id: "6", route: "Users" }
                , { id: "7", route: "Rules" }
                , { id: "2", route: "StatesProvinces" }
                , { id: "3", route: "Regions" }]
        }
    }

    componentDidMount() {
        this.getData()
    }

    callback(key: string) {
        const route = this.state.routes.find(x => x.id === key);
        if (route) {
            this.props.history.push(`${route.route}`)
        }
    }

    componentDidUpdate(prevProps: IAdminProps, prevState: IAdminState) {
        if (prevProps.languageCode !== this.props.languageCode) {
            this.setState({ languageCode: this.props.languageCode ?? 'en' }, async () => {
                await this.getLanguageData();
            })
        }
    }


    setLanguage = (lang: string) => {
        this.setState({ languageCode: lang }, async() => {
            this.props.setLanguage(lang);
        });
    }

    getData = () => {
        this.setState({ loading: true }, async () => {
            await this.getLanguageData();
            const route = this.state.routes.find(x => x.route === this.props.match.params.id);
            let key = "1"
            if (route) {
                key = route.id
            }
            this.setState({ loading: false, key });
        })
    };

    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getHubAdminResources();
            const supportedLanguages = await BaseService.getLanguages();

            this.setState({ langData, supportedLanguages, loading: false });
        })

    }

    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label]
        }
        return label;
    }

    render() {
        const { loading, supportedLanguages, languageCode, langData, key } = this.state;
        return (
            <div>
                <h2>{this.translate("Admin")}</h2>
                {
                    loading ?
                        <Spin />
                        :
                        <Tabs defaultActiveKey={key.toString()} onChange={(x) => this.callback(x)}>
                            <TabPane tab={this.translate("Settings")} key="1">
                                <Form>
                                    <Form.Item label={this.translate("Language")} name="Language">
                                        <Select defaultValue={languageCode} onChange={(e) => this.setLanguage(e)}>
                                            {
                                                supportedLanguages.map(c => (
                                                    <Option key={c.ID} value={c.Name}>{c.Description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </TabPane>
                            <TabPane tab={this.translate("States/Provinces")} key="2">
                                <States translate={this.translate} />
                            </TabPane>

                            {this.props.user.IsAdmin &&
                                <>
                                    <TabPane tab={this.translate("Regions")} key="3">
                                        <Region translate={this.translate} />
                                    </TabPane>
                                    <TabPane tab={this.translate("Ethics Officers")} key="4">
                                        <EthicsOfficers translate={this.translate}
                                            removeOfficer={OrganizationService.removeEthicsOfficer}
                                            addOfficer={OrganizationService.addEthicsOfficer}
                                            getOrg={OrganizationService.getOrganizationTree}
                                            addText={"Add Ethics Officer"}
                                            removeText={"Remove Ethics Officer"}
                                            searchText={"Search for an employee and add them as Ethics Officer"}
                                            searchType={1}

                                        />
                                    </TabPane>
                                    <TabPane tab={this.translate("LegalOfficers")} key="5">
                                        <EthicsOfficers translate={this.translate}
                                            removeOfficer={OrganizationService.removeLegalOfficer}
                                            addOfficer={OrganizationService.addLegalOfficer}
                                            getOrg={OrganizationService.getOrganizationsTreeLegal}
                                            addText={"AddLegalOfficer"}
                                            removeText={"RemoveLegalOfficer"}
                                            searchText={"SearchforanemployeeandaddthemasLegalOfficer"}
                                            searchType={2}

                                        />
                                    </TabPane>
                                    <TabPane tab={this.translate("Users")} key="6">
                                        <UsersTab translate={this.translate} />

                                    </TabPane>
                                    <TabPane tab={this.translate("Rules")} key="7">
                                        <Rules translate={this.translate} />
                                    </TabPane>
                                </>
                            }

                        </Tabs>
                }
            </div>
        )
    }
}

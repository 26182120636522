import React, { Component, useState } from 'react'
import OrgCOIService from '../services/OrgCOIService';
import {
    Layout, Spin, Descriptions, PageHeader, Button, Row, Col,
    Tag, Typography, List, Modal, Select, Form, Input, Alert, Tooltip, Tabs, Badge, Upload, Checkbox, message,
    Timeline, Table
} from 'antd'
import { EditTwoTone, CheckCircleTwoTone, ExclamationCircleTwoTone, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import OrganizationService from '../services/OrganizationService';
import DropdownService from '../services/DropdownService';
import moment from 'moment';
import CommentService from '../services/CommentService';
import BaseService from '../services/BaseService';
import Comments from './Comments';
import TextArea from 'antd/lib/input/TextArea';
import ProjectCard from './ProjectCard';
import EmployeeService from '../services/EmployeeService';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';
import { RcFile } from 'antd/lib/upload';
import { AuditDTO, BasicEmployee, CommentsBase, ConflictOfInterestFileDTO, Employee, EmployeeWithPermissions, EmptyConflictOfInterest, EmptyOrgConflictOfInterest, GenericDTO, IConflictOfInterest, IOrgConflictOfInterst, OrgConflictOfInterestFileDTO, pagination, sorter } from '../models/Core';


const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Search } = Input;

const loadingIcon = <LoadingOutlined spin />;

type IAddOfficerBtn = {
    coiID: number,
    empID: number,
    addEmployeeToList: Function
}


const AddOfficerBtn = ({ coiID, empID, addEmployeeToList }: IAddOfficerBtn) => {
    const [loading, setLoading] = useState(false);
    const [added, setAdded] = useState(false);

    let addEmployee = async () => {
        setLoading(true);
        let data = await OrgCOIService.AddEmployeeAccess(coiID, empID);
        if (data) {
            addEmployeeToList();
            setAdded(true);

        }
        setLoading(false);
    }

    if (added) {
        return <span>Added</span>
    }
    return <Button onClick={addEmployee} disabled={loading}> {loading ? <Spin indicator={loadingIcon} /> : "Add"}</Button>
}

const AddAdvisorBtn = ({ coiID, empID, addEmployeeToList }: IAddOfficerBtn) => {
    const [loading, setLoading] = useState(false);
    const [added, setAdded] = useState(false);

    let addEmployee = async () => {
        setLoading(true);
        let data = await OrgCOIService.AssignAdvisor(coiID, empID);
        if (data) {
            addEmployeeToList();
            setAdded(true);

        }
        setLoading(false);
    }

    if (added) {
        return <span>Assigned</span>
    }
    return <Button onClick={addEmployee} disabled={loading}> {loading ? <Spin indicator={loadingIcon} /> : "Assign"}</Button>
}


type IOrgCOIDetailParams = {
    id: string; // parameters will always be a string (even if they are numerical)
};


interface IOrgCOIDetailProps extends RouteComponentProps<IOrgCOIDetailParams> {
    languageCode: string,
    history: History,
    user: EmployeeWithPermissions,

}

interface IOrgCOIDetailState {
    loading: boolean,
    coi: IOrgConflictOfInterst,
    organizations: GenericDTO[],
    OrganizationName: null,
    ReviewComments: string,
    showEditModal: boolean,
    showStatusModal: boolean,
    showAssignAdvisorModal: boolean,
    statusModalTitle: string,
    editModalConfirmLoading: boolean,
    statusModalConfirmLoading: boolean,
    comments: CommentsBase[],
    languageCode: string,
    langData: any,
    showFileModal: boolean,
    ShareWithUser: boolean,
    EmailUser: boolean,
    EmailUserComment: string,
    fileList: RcFile[],
    coiFiles: OrgConflictOfInterestFileDTO[],
    auditHistory: AuditDTO[],
    coiAccessList: BasicEmployee[],
    CanDelete: boolean,
    orgCOIAssessmentResult: GenericDTO[],
    AssessmentResult: number,
    officerQuery: string,
    officerPag: pagination,
    officerSorter: sorter,
    officerSearchList: Employee[],
    officerModal: boolean,
    officerTotal: number,
    OrganizationID: number
}


export default class OrgCOIDetail extends Component<IOrgCOIDetailProps, IOrgCOIDetailState> {
    constructor(props: IOrgCOIDetailProps) {
        super(props);
        this.state = {
            loading: false,
            coi: EmptyOrgConflictOfInterest,
            organizations: [],
            OrganizationName: null,
            ReviewComments: '',
            showEditModal: false,
            showStatusModal: false,
            statusModalTitle: '',
            editModalConfirmLoading: false,
            statusModalConfirmLoading: false,
            comments: [],
            languageCode: props.languageCode,
            langData: {},
            showFileModal: false,
            ShareWithUser: false,
            EmailUser: false,
            EmailUserComment: '',
            fileList: [],
            coiFiles: [],
            auditHistory: [],
            coiAccessList: [],
            CanDelete: false,
            orgCOIAssessmentResult: [],
            AssessmentResult: 0,
            officerQuery: "",
            officerPag: {
                current: 1,
                pageSize: 10,
                total: 0

            },
            officerSorter: {
                field: '',
                order: '',
            },
            officerSearchList: [],
            officerModal: false,
            officerTotal: 0,
            OrganizationID: 60,
            showAssignAdvisorModal: false
        }
    }

    componentDidMount() {
        this.getLanguageData();
    }

    translate = (label: string) => {
        if (this.state.langData[label]) {
            return this.state.langData[label]
        }
        return label;
    }
    getLanguageData = async () => {
        this.setState({ loading: true }, async () => {
            const langData = await BaseService.getOrgCoiDetailsResources();
            this.setState({ langData });
            this.getData();

        })

    }

    checkIfJustLegalOfficer = () => {
        return this.props.user.IsLegalOfficer && (this.props.user.IsEthicOfficer === false && this.props.user.IsAdmin === false)
    }

    getData = () => {
        const { id } = this.props.match.params;

        if (id) {
            this.setState({ loading: true }, async () => {

                let organizations = [];
                let dropDowns = [];
                let coiAccessList: BasicEmployee[] = [];
                const coi = await OrgCOIService.getOrgCOI(id);
                if (!this.checkIfJustLegalOfficer()) {
                    organizations = await OrganizationService.getOrganizations();
                    dropDowns = await DropdownService.getCOIAssessmentResult();
                }

                if (coi.OrgConflictAccess) {
                    coiAccessList = [...coi.OrgConflictAccess];
                }


                this.setState({
                    loading: false, coi, organizations,
                    OrganizationName: coi.Organization != null ? coi.Organization.Name : '',
                    ReviewComments: coi.ReviewComments ? coi.ReviewComments : '',
                    comments: coi.Comments ? coi.Comments : [],
                    coiFiles: coi.Files ? coi.Files : [],
                    auditHistory: coi.AuditHistory ? coi.AuditHistory : [],
                    coiAccessList,
                    CanDelete: coi.CanDelete,
                    orgCOIAssessmentResult: dropDowns.orgCOIAssessmentResult ? dropDowns.orgCOIAssessmentResult : []
                })
            })
        }
        else {
            this.props.history.push('/actions');
        }
    }

    postComment = async (commentText: string) => {
        var res = await CommentService.AddOrgCOIComment(this.state.coi.ID, commentText);

        if (res) {
            const comments = [res, ...this.state.comments];
            this.setState({ comments });
        }

    }

    changeGiftStatus = () => {
        const { statusModalTitle, ReviewComments, coi, AssessmentResult } = this.state;

        this.setState({ loading: true, showStatusModal: false }, async () => {
            switch (statusModalTitle) {
                case 'Approve':
                    await OrgCOIService.approveOrgCOI(coi.ID);
                    this.getData();
                    break;


                case 'UnderReview':
                    await OrgCOIService.markUnderReviewOrgCOI(coi.ID);
                    this.getData();
                    break;

                case 'Reopen':
                    await OrgCOIService.reopen(coi.ID);
                    this.getData();
                    break;

                case 'InReview':
                    await OrgCOIService.markInReviewOrgCOI(coi.ID, ReviewComments);
                    this.getData();
                    break;
                case 'Closed':
                    await OrgCOIService.closeOrgCOI(coi.ID, ReviewComments, AssessmentResult);
                    this.getData();
                    break;
                case 'Monitor':
                    await OrgCOIService.monitorOrgCOI(coi.ID, ReviewComments, AssessmentResult);
                    this.getData();
                    break;

                case 'Delete':
                    const data = await OrgCOIService.deleteOrgCOI(coi.ID);
                    if (data && data.status === 200) {
                        this.props.history.push(`/actions`)
                    } else {
                        this.getData();
                    }
                    break;

                default:
                    await OrgCOIService.rejectOrgCOI(coi.ID);
                    this.getData();
                    break;
            }

        })
    }

    markInReview = async () => {
        await OrgCOIService.markUnderReviewOrgCOI(this.state.coi.ID);
        this.getData();
    }

    markReject = async () => {
        await OrgCOIService.rejectOrgCOI(this.state.coi.ID);
        this.getData();
    }

    markApprove = async () => {
        await OrgCOIService.approveOrgCOI(this.state.coi.ID);
        this.getData();
    }

    getStatus = () => {
        const text = this.state.coi.StatusName;
        switch (text) {
            case 'Submitted':
                return <Tag color="blue">{this.translate(text)}</Tag>;
            case 'Approved':
                return <Tag color="green">{this.translate(text)}</Tag>;
            case 'Rejected':
                return <Tag color="red">{this.translate(text)}</Tag>;
            case 'InReview':
                return <Tag color="orange">{this.translate(`org_${text}`)}</Tag>;
            case 'UnderReview':
                return <Tag color="#5260ff">{this.translate(`org_${text}`)}</Tag>;
            case 'Monitor':
                return <Tag color="#ff9c60">{this.translate(text)}</Tag>;
            default:
                return <Tag>{this.translate(text)}</Tag>;
        }
    }



    getSubTitles = () => {
        return <div>{this.getStatus()}</div>
    }


    removeUser = async (index: number, ID: number) => {
        let { coiAccessList, coi } = this.state;
        let data = await OrgCOIService.RemoveEmployeeAccess(coi.ID, ID);

        if (data && coiAccessList.length >= index) {
            coiAccessList = coiAccessList.filter(x => x.ID !== ID);
            this.setState({ coiAccessList })

        }
    }

    addEmployeeToList = (empID: number, empName: string) => {
        let accessList = this.state.coiAccessList;
        if (accessList === null) {
            accessList = [];
        }
        accessList.push({
            FullName: empName,
            ID: empID
        })
        this.setState({ coiAccessList: accessList })
    }
    getButtons = () => {

        let btns = [{
            key: "2", className: "btn-success", onClick: () => (
                this.setState({ statusModalTitle: 'Closed', showStatusModal: true })
            ), content: this.translate("Closed")
        }];

        // let btns = [<Button key="2" className="btn-success" onClick={() => (
        //     this.setState({ statusModalTitle: 'Closed', showStatusModal: true })
        // )}>{this.translate("Closed")}</Button>];

        if (this.state.coi.StatusName === 'Submitted' || this.state.coi.StatusName === 'InReview' || this.state.coi.StatusName === 'UnderReview' || this.state.coi.StatusName === 'Monitor') {

            if (this.state.coi.StatusName !== 'InReview') {
                btns.push(
                    {
                        key: "1", className: "", onClick: () => (
                            this.setState({ statusModalTitle: 'InReview', showStatusModal: true })
                        ), content: this.translate("org_InReview")
                    }
                )
            }

            if (this.state.coi.StatusName !== 'UnderReview') {
                btns.push(
                    {
                        key: "5", className: "btn-review", onClick: () => (
                            this.setState({ statusModalTitle: 'UnderReview', showStatusModal: true })
                        ), content: this.translate("org_UnderReview")
                    }
                )

            }
            if (this.state.coi.StatusName !== 'Monitor') {
                btns.push(
                    {
                        key: "5", className: "btn-monitor", onClick: () => (
                            this.setState({ statusModalTitle: 'Monitor', showStatusModal: true })
                        ), content: this.translate("Monitor")
                    }
                )

            }
        }

        else {
            btns = [];
            if (this.props.user.IsAdmin) {
                btns.push(
                    {
                        key: "5", className: "", onClick: () => (
                            this.setState({ statusModalTitle: 'Reopen', showStatusModal: true })
                        ), content: this.translate("Reopen")
                    }
                )

            }
        }


        if (this.state.coi.CanDelete) {
            btns.push(
                {
                    key: "8", className: "btn-delete", onClick: () => (
                        this.setState({ statusModalTitle: 'Delete', showStatusModal: true })
                    ), content: this.translate("Delete")
                }
            )
        }




        return btns.map(x => <Button key={x.key} className={x.className} onClick={x.onClick} disabled={this.checkIfJustLegalOfficer()}>{x.content}</Button>);
    }

    downloadFile = async (fileID: number) => {
        await OrgCOIService.downloadFileOrgCOI(fileID);
    }

    yesOrNo = (key: number) => {
        switch (key) {
            case 0:
                return 'Yes'
                break;
            case 1:
                return 'No'
                break;
            case 2:
                return 'DontKnow'
                break;
            default:
                return ''
                break;
        }
    }

    updateOrganization = () => {
        this.setState({ loading: true, showEditModal: false }, async () => {
            const { coi, OrganizationID } = this.state;
            await OrgCOIService.updateOrganizationOrgCOI(coi.ID, OrganizationID);
            this.getData();
        })
    }
    uploadFiles = async () => {
        const { EmailUserComment, EmailUser, ShareWithUser, fileList, coi } = this.state;

        const emailComment = EmailUser ? EmailUserComment : ""
        const result = await OrgCOIService.uploadFilesOrgCOI(fileList, coi.ID.toString(), String(ShareWithUser), String(EmailUser), emailComment);

        if (result) {
            this.setState(state => ({
                coiFiles: [...state.coiFiles, ...result],
            }));
            message.success(this.translate("FileUploaded"))
        } else {
            message.error(this.translate("fileUploadError"), 10)
        }
        this.setState({ showFileModal: false, EmailUserComment: '', EmailUser: false, ShareWithUser: false, fileList: [] })

    }


    getOfficerTable = async () => {
        const { officerPag, officerSorter, officerQuery } = this.state;
        const data = await EmployeeService.getOfficers(officerPag, officerSorter, officerQuery);
        this.setState({ officerSearchList: data.Search, officerTotal: data.Count });
    }

    handleTableChange = async (pagination: pagination, sorter: sorter) => {
        this.setState({ officerPag: pagination, officerSorter: sorter }, this.getOfficerTable);

    };

    searchQuery = (searchTerm: string) => {
        const officerPag = {
            current: 1,
            pageSize: 10,
            total: 0
        }
        this.setState({ officerQuery: searchTerm, officerPag }, this.getOfficerTable)
    }

    showOfficerModal = () => {
        this.getOfficerTable();
        this.setState({ officerModal: true });
    }

    showAdvisorModal = () => {
        this.getOfficerTable();
        this.setState({ showAssignAdvisorModal: true });
    }

    officerColumn = () => {
        const coiID = this.state.coi.ID;
        const accessList = this.state.coiAccessList;
        return [
            {
                title: this.translate("Name"),
                dataIndex: 'FullName',

            },
            {
                title: this.translate("Add"),
                render: (x: Employee) => {

                    console.log(accessList.some(y => y.ID === x.ID), x.ID, accessList)

                    if (accessList.some(y => y.ID === x.ID)) {
                        return <span>{this.translate("Added")}</span>
                    }

                    return <AddOfficerBtn coiID={coiID} empID={x.ID} addEmployeeToList={() => this.addEmployeeToList(x.ID, x.FullName)} />
                }

            },


        ]
    }


    advisorColumn = () => {
        const coiID = this.state.coi.ID;
        const advisor = this.state.coi.AssignedAdvisorID;
        return [
            {
                title: this.translate("Name"),
                dataIndex: 'FullName',

            },
            {
                title: this.translate("Set Advisor"),
                render: (x: Employee) => {


                    if (x.ID === advisor) {
                        return <span>{this.translate("CurrentAdvisor")}</span>
                    }

                    return <AddAdvisorBtn coiID={coiID} empID={x.ID} addEmployeeToList={() => { this.setState({ showAssignAdvisorModal: false }); this.getData() }} />
                }

            },


        ]
    }


    auditColumn = () => {
        return [
            {
                title: this.translate("ChangeType"),
                dataIndex: 'ChangeTypeDisplay',

            },
            {
                title: this.translate("ColumnChanged"),
                dataIndex: 'ColumnChanged',
                render: (ColumnChanged: string) => {


                    return <span>{this.translate(ColumnChanged)}</span>
                }

            },
            {
                title: this.translate("OriginalValue"),
                dataIndex: 'OriginalValueDisplay',
                render: (data: string) => {


                    return <span>{this.translate(data)}</span>
                }

            },
            {
                title: this.translate("NewValue"),
                dataIndex: 'NewValueDisplay',
                render: (data: string) => {
                    if (data === "UnderReview" || data === "InReview") {
                        return <span>{this.translate(`org_${data}`)}</span>

                    }

                    return <span>{this.translate(data)}</span>
                }

            },

        ]
    }

    render() {
        const { loading, coi, showEditModal, showStatusModal, statusModalTitle, ReviewComments,
            editModalConfirmLoading, statusModalConfirmLoading, auditHistory, OrganizationName, comments, ShareWithUser, coiFiles, showFileModal,
            EmailUserComment, EmailUser, organizations,
            coiAccessList,
            orgCOIAssessmentResult, officerSearchList, officerPag, officerTotal, OrganizationID } = this.state;

        return (
            <div>
                {
                    loading ?
                        <Spin />
                        :
                        <div>
                            <Content>
                                <PageHeader
                                    ghost={false}
                                    onBack={() => window.history.length > 1 ? window.history.back() : this.props.history.push(`/actions`)}
                                    title={`${this.translate("OrgCOIID")} - ${coi.ID}`}
                                    subTitle={this.getSubTitles()}
                                    extra={this.getButtons()}
                                >


                                    <div>
                                        {
                                            coi.AdditionalComments ?

                                                <Alert message={this.translate("AdditionalComments")} style={{ marginBottom: 20 }}
                                                    description={coi.AdditionalComments} type="info" closable={false} />

                                                :

                                                null
                                        }
                                        {
                                            coi.Justification ?

                                                <Alert message={this.translate("Justification")} style={{ marginBottom: 20 }}
                                                    description={coi.Justification} type="info" closable={false} />

                                                :

                                                null
                                        }
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab={this.translate("Details")} key="1">
                                                <Row>
                                                    <Col>
                                                        {
                                                            coi.CreatedBy ?
                                                                <h3><span className="txt-label">{this.translate("CreatedBy")}</span> {coi.CreatedBy.FullName} - {moment(coi.CreatedOn).local().format("MMM Do, YYYY")} </h3>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>
                                                {coi.UpdatedBy ? <Row>
                                                    <Col>
                                                        <h3><span className="txt-label">{this.translate("UpdatedBy")}</span> {coi.UpdatedBy.FullName} - {moment(coi.UpdatedOn).local().format("MMM Do, YYYY")} </h3>

                                                    </Col>
                                                </Row> : null}

                                                <Row>
                                                    <Col>
                                                        {
                                                            coi.Organization ?
                                                                <h3><span className="txt-label">{this.translate("Region")}</span> {coi.Organization.Name}
                                                                    {(this.props.user.IsAdmin || this.props.user.IsEthicOfficer) &&
                                                                        <EditTwoTone style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.setState({ showEditModal: true })} />
                                                                    }
                                                                </h3>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {
                                                            coi.StateProvince ?
                                                                <h3><span className="txt-label">{this.translate("State")}</span> {coi.StateProvince.Name}
                                                                </h3>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>

                                                        <h3><span className="txt-label">{this.translate("AssignedAdvisor")}</span>{
                                                            coi.AssignedAdvisorID ?
                                                                <span>{coi.AssignedTo.FullName}</span>
                                                                : <span>{this.translate("NoAdvisor")}</span>}
                                                            {(this.props.user.IsAdmin || this.props.user.IsEthicOfficer) &&
                                                                <EditTwoTone style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.showAdvisorModal()} />
                                                            }
                                                        </h3>





                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Descriptions title={this.translate("Description")}>
                                                            <Descriptions.Item>{coi.Description}</Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={24}>
                                                        <Descriptions title={this.translate("MitigationProcess")}>
                                                            <Descriptions.Item>{coi.MitigationProcess}</Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Descriptions
                                                    title={this.translate("Details")}
                                                    bordered
                                                >

                                                    {coi.ConflictStatusName ? <Descriptions.Item span={24} label={this.translate("ConflictStatusName")}>{this.translate(coi.ConflictStatusName)}</Descriptions.Item> : null}
                                                    {coi.ProgramOfWork ? <Descriptions.Item span={24} label={this.translate("ProgramOfWork")}>{this.translate(coi.ProgramOfWork.Name)}</Descriptions.Item> : null}
                                                    {coi.WSPRoleType ? <Descriptions.Item span={24} label={this.translate("WSPRoleType")}>{this.translate(coi.WSPRoleTypeName)}</Descriptions.Item> : null}
                                                    {coi.ConflictingProject != null ? <Descriptions.Item span={24} label={this.translate("ConflictingProject")}>{this.translate(this.yesOrNo(coi.ConflictingProject))}</Descriptions.Item> : null}
                                                    {coi.IsTruthful != null ? <Descriptions.Item span={24} label={this.translate("IsTruthful")}>{coi.IsTruthful ? this.translate("Yes") : this.translate("No")}</Descriptions.Item> : null}
                                                    {coi.CreatedOn ? <Descriptions.Item span={24} label={this.translate("CreatedOn")}>{moment(coi.CreatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                    {coi.UpdatedOn ? <Descriptions.Item span={24} label={this.translate("UpdatedOn")}>{moment(coi.UpdatedOn).local().format("MMM Do, YYYY")}</Descriptions.Item> : null}
                                                    {coi.DescribeRequest != null ? <Descriptions.Item span={24} label={this.translate("DescribeRequest")}>{this.translate(coi.DescribeRequest)}</Descriptions.Item> : null}
                                                    {coi.AssessmentResult != null ? <Descriptions.Item span={24} label={this.translate("AssessmentResult")}>{this.translate(coi.AssessmentResult)}</Descriptions.Item> : null}




                                                </Descriptions>

                                                <br />
                                                {
                                                    coi.Employee ?
                                                        <Descriptions
                                                            title={this.translate("WSPContact")}
                                                            bordered
                                                        >
                                                            <Descriptions.Item span={24} label={this.translate("Name")}>{coi.Employee.FullName}</Descriptions.Item>
                                                            <Descriptions.Item span={24} label={this.translate("EmailAddress")}>{coi.Employee.EmailAddress}</Descriptions.Item>
                                                            <Descriptions.Item span={24} label={this.translate("PhoneNumber")}>{coi.Employee.PhoneNumber}</Descriptions.Item>
                                                        </Descriptions>
                                                        :
                                                        null
                                                }
                                                <br />


                                            </TabPane>
                                            <TabPane tab={<span>{this.translate("Project")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={coi.Projects ? coi.Projects.length : 0} size="small" showZero></Badge></span>} key="5">
                                                {coi.Projects && coi.Projects.length > 0 && coi.Projects.map(x => <ProjectCard key={x.ID} Project={x} translate={this.translate} />)}



                                            </TabPane>
                                            <TabPane tab={<span>{this.translate("ConflictingProjects")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={coi.ConflictingProjects ? coi.ConflictingProjects.length : 0} size="small" showZero></Badge></span>} key="6">

                                                {coi.ConflictingProjects && coi.ConflictingProjects.length > 0 && coi.ConflictingProjects.map(x => <ProjectCard key={x.ID} Project={x} translate={this.translate} />)}

                                            </TabPane>
                                            <TabPane tab={<span>{this.translate("Comments")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={comments ? comments.length : 0} size="small" showZero></Badge></span>} key="2">


                                                <Comments comments={comments} submitFunction={this.postComment} translate={this.translate} />

                                            </TabPane>

                                            <TabPane tab={<span>{this.translate("Files")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={coi.Files ? coi.Files.length : 0} size="small" showZero></Badge></span>} key="3">
                                                <div>
                                                    <Modal title={this.translate('addNewFile')} visible={showFileModal} onOk={this.uploadFiles}
                                                        confirmLoading={statusModalConfirmLoading}
                                                        okText={this.translate("Upload")}
                                                        okButtonProps={this.state.fileList.length > 0 ? { disabled: false } : { disabled: true }}
                                                        onCancel={() => this.setState({ showFileModal: false })}>
                                                        <Dragger
                                                            name={'files'}
                                                            multiple={true}
                                                            beforeUpload={file => {
                                                                this.setState(state => ({
                                                                    fileList: [...state.fileList, file],
                                                                }));
                                                                return false;
                                                            }}
                                                            fileList={this.state.fileList}
                                                            onRemove={file => {
                                                                this.setState(state => {
                                                                    const index = state.fileList.findIndex(x => x.uid === file.uid);
                                                                    const newFileList = state.fileList.slice();
                                                                    newFileList.splice(index, 1);
                                                                    return {
                                                                        fileList: newFileList,
                                                                    };
                                                                });
                                                            }}
                                                        >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{this.translate("uploadText")}</p>
                                                            <p className="ant-upload-hint">
                                                                {this.translate("uploadHint")}
                                                            </p>
                                                        </Dragger>
                                                        <br />

                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                                            <Checkbox value={ShareWithUser} onChange={(e) => this.setState({ ShareWithUser: e.target.checked })}>{this.translate("ShareWithUser")}</Checkbox>
                                                            <Checkbox value={EmailUser} onChange={(e) => this.setState({ EmailUser: e.target.checked })}>{this.translate("EmailUser")}</Checkbox>

                                                        </div>
                                                        <br />

                                                        {EmailUser && <Form.Item label={this.translate("EmailFileComment")}>
                                                            <TextArea value={EmailUserComment} rows={4} onChange={(e) => { this.setState({ EmailUserComment: e.target.value }) }}></TextArea>
                                                        </Form.Item>}
                                                    </Modal>


                                                    <List header={<h3><span className="txt-label">{this.translate("Files")}</span>
                                                        {!this.checkIfJustLegalOfficer() &&
                                                            <Button size={"small"} type={'primary'} onClick={() => this.setState({ showFileModal: true })}>
                                                                {this.translate("addNewFile")}

                                                            </Button>
                                                        }

                                                    </h3>}
                                                        bordered
                                                        dataSource={coiFiles}
                                                        locale={
                                                            { emptyText: this.translate("NoFiles") }
                                                        }
                                                        renderItem={item => (
                                                            <List.Item>
                                                                <Typography.Link onClick={() => this.downloadFile(item.ID)}
                                                                >{item.Name}
                                                                </Typography.Link>
                                                                {item.Private && <Tag color="red">{this.translate("Private")}</Tag>}
                                                            </List.Item>
                                                        )}
                                                    />
                                                </div>
                                            </TabPane>
                                            <TabPane tab={<span>{this.translate("AuditHistory")} <Badge className="site-badge-count-4" style={{ backgroundColor: '#108ee9' }} count={auditHistory ? auditHistory.length : 0} size="small" showZero></Badge></span>} key="4">
                                                <Row>
                                                    <Timeline style={{ width: '100%' }}>
                                                        {auditHistory.map(audit => (
                                                            <Timeline.Item key={audit.ID}>
                                                                <Table
                                                                    tableLayout="fixed"
                                                                    title={() => <Row><Col ><h4>{`${audit.User.FullName} ${audit.User.Roles && audit.User.Roles.length > 0 ? `- ${audit.User.Roles.map(y => this.translate(y)).join(", ")}` : ''} - ${moment(audit.CreatedData).local().format("MMM Do, YYYY h:mm:ss a")}`}</h4></Col> </Row>}
                                                                    dataSource={audit.AuditDetails} columns={this.auditColumn()} style={{ width: '100%' }}
                                                                    size="small" pagination={false}
                                                                />


                                                            </Timeline.Item>
                                                        ))}

                                                    </Timeline>

                                                </Row>

                                            </TabPane>

                                            <TabPane tab={<span>{this.translate("Employee Access")}</span>} key="9">
                                                <Row>
                                                    <Button onClick={() => this.showOfficerModal()}>Add Access</Button>
                                                </Row>
                                                <Row>
                                                    <List
                                                        dataSource={coiAccessList}
                                                        renderItem={(item, index) => (
                                                            <List.Item key={index}>
                                                                {item.FullName} <Button onClick={() => this.removeUser(index, item.ID)}>Remove Access</Button>
                                                            </List.Item>
                                                        )
                                                        }
                                                    />

                                                </Row>

                                            </TabPane>

                                        </Tabs>
                                    </div>

                                </PageHeader>

                            </Content>
                        </div>
                }
                <Modal title={this.translate("UpdateRegion")} visible={showEditModal} onOk={this.updateOrganization}
                    confirmLoading={editModalConfirmLoading}
                    okText={this.translate("Update")}
                    onCancel={() => this.setState({ showEditModal: false })}>
                    <Form>
                        <Form.Item label={this.translate("Region")}>
                            <Select placeholder={this.translate("PleaseSelectTheRegion")}
                                defaultValue={OrganizationID}
                                onChange={(OrganizationID) => this.setState({ OrganizationID })}>
                                {
                                    organizations.map(org => <Option key={`org${org.ID}`} value={org.ID}>{org.Name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={this.translate("AddEmployee")}
                    visible={this.state.officerModal}
                    onOk={() => this.setState({ officerModal: false })}
                    onCancel={() => this.setState({ officerModal: false })}>

                    <Search placeholder="Search" onSearch={(searchValue) => this.searchQuery(searchValue)} />
                    <Table dataSource={officerSearchList}
                        columns={this.officerColumn()}
                        scroll={{ x: true }}
                        pagination={{
                            ...officerPag,
                            total: officerTotal,
                            showTotal: (total) => `${total} ${this.translate("items")}`,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
                        }}

                        onChange={() => this.handleTableChange}
                        loading={loading}
                    />

                </Modal>

                <Modal
                    title={this.translate("AssignAdvisor")}
                    visible={this.state.showAssignAdvisorModal}
                    onOk={() => this.setState({ showAssignAdvisorModal: false })}
                    onCancel={() => this.setState({ showAssignAdvisorModal: false })}>

                    <Search placeholder="Search" onSearch={(searchValue) => this.searchQuery(searchValue)} />
                    <Table dataSource={officerSearchList}
                        columns={this.advisorColumn()}
                        scroll={{ x: true }}
                        pagination={{
                            ...officerPag,
                            total: officerTotal,
                            showTotal: (total) => `${total} ${this.translate("items")}`,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
                        }}

                        onChange={() => this.handleTableChange}
                        loading={loading}
                    />

                </Modal>
                <Modal title={this.translate(statusModalTitle)} visible={showStatusModal} onOk={this.changeGiftStatus}
                    confirmLoading={statusModalConfirmLoading}
                    okText={this.translate("Update")}
                    onCancel={() => this.setState({ showStatusModal: false })}>
                    <Form>

                        {statusModalTitle === "Closed" ? <Form.Item label={this.translate("SummaryJustification")}>
                            <Input.TextArea showCount maxLength={1000} rows={4} value={ReviewComments} onChange={(e) => { this.setState({ ReviewComments: e.target.value }) }} />



                        </Form.Item> :

                            statusModalTitle !== "Delete" ?
                                <span>{this.translate("SetReviewState")} </span>
                                : <span>{this.translate("DeleteEntry")}</span>

                        }
                        {
                            statusModalTitle === "Closed" &&

                            <Form.Item label={this.translate("COIAssessmentResult")}>
                                <Select placeholder={this.translate("PleaseCOIAssessmentResult")}
                                    onChange={(AssessmentResult: number) => this.setState({ AssessmentResult })}>
                                    {
                                        orgCOIAssessmentResult.map(org => <Option key={`org${org.ID}`} value={org.ID}>{this.translate(org.Name)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }


                    </Form>
                </Modal>
            </div >
        )
    }
}
